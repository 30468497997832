import {
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import { Schema } from "jsonschema";
import { JsonSchemaFormErrorMessage } from "../JsonSchemaFormErrorMessage"

@Component({
  selector: "app-json-form-editor",
  templateUrl: "./json-form-editor.component.html",
  styleUrls: ["./json-form-editor.component.css"]
})
export class JsonFormEditorComponent {
  @Input()
  schema: Schema;

  @Input()
  layout: any;

  @Output()
  isValid: EventEmitter<boolean> = new EventEmitter();

  @Output()
  dataChange = new EventEmitter<any>();

  @Output()
  validationErrors: EventEmitter<JsonSchemaFormErrorMessage[]> = new EventEmitter();

  @Input()
  data: any;

  constructor() {
  }

}
