import { CommonColumn } from "@rezonence/log-column";
import { TimestampColumn } from "./TimestampColumn";
import { EdgeLocationColumn } from "./EdgeLocationColumn";

// eslint-disable-next-line local-rules/multi-exports
export type LogTableColumn = TimestampColumn | CommonColumn | EdgeLocationColumn;
// eslint-disable-next-line @typescript-eslint/naming-convention
export const LogTableColumn = {
  ...TimestampColumn,
  ...CommonColumn,
  ...EdgeLocationColumn
};
