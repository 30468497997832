import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import {JsonSchemaFormService} from "@ajsf/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "material-number-widget",
  templateUrl: "./custom.number.component.html",
  styleUrls: [
    "./custom.number.component.css"
  ],
})
export class CustomNumberComponent implements OnInit {
  formControl: FormControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  allowNegative = true;
  allowDecimal = true;
  allowExponents = false;
  lastValidNumber = "";
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    private jsf: JsonSchemaFormService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.jsf.initializeControl(this);
    if (this.layoutNode.dataType === "integer") { this.allowDecimal = false; }
    if (!this.options.notitle && !this.options.description && this.options.placeholder) {
      this.options.description = this.options.placeholder;
    }
  }

  updateValue(event) {
    this.jsf.updateValue(this, event.target.value);
  }
}
