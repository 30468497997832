import {Injectable} from "@angular/core";
import { CognitoIdentityCredentials } from "aws-sdk/global";
import DynamoDB from "aws-sdk/clients/dynamodb";
import {environment} from "../../environments/environment";
import {environment as awsEnv} from "@rezonence/core/aws/environment";
import {CognitoIdentityServiceFactory} from "@jabrythehutt/aws-factory/src/cognito.identity.service.factory";
import {config} from "aws-sdk/global";
import {AuthenticatedProfileRequest} from "@jabrythehutt/aws-factory/src/authenticated.profile.request";
config.region = awsEnv.REGION;

@Injectable()
export class AWSService extends CognitoIdentityServiceFactory {

  private db: Promise<DynamoDB.DocumentClient>;

  constructor() {
    super();
  }

  async identityId(): Promise<string> {
    await this.authenticate();
    return this.credentials.identityId;
  }

  async createDB(): Promise<DynamoDB.DocumentClient> {
    return new DynamoDB.DocumentClient({
      region: awsEnv.REGION,
      credentials: this.credentials,
      convertEmptyValues: true
    });
  }

  async switchToAuthenticatedProfile(request: AuthenticatedProfileRequest): Promise<void> {
    this.credentials = new CognitoIdentityCredentials({ IdentityPoolId: environment.identityPoolId });
    await super.switchToAuthenticatedProfile(request);
  }

  async dynamodb(): Promise<DynamoDB.DocumentClient> {
    await this.authenticate();
    this.db = this.db || this.createDB();
    return this.db;
  }

  signOut(): void {
    if (this.credentials) {
      super.signOut();
    }
  }
}
