import {Injectable} from "@angular/core";

import {FileService} from "@djabry/fs-s3";
import {AWSService} from "./aws.service";
import S3 from "aws-sdk/clients/s3";

@Injectable()
export class CampaignFileService extends FileService {

  constructor(private awsService: AWSService) {

    super(awsService.getService(S3));

  }

}
