import {BackgroundRefreshCache} from "../../cache/background.refresh.cache";
import {Injectable, NgZone} from "@angular/core";

/**
 * Caches report data in the browser
 */
@Injectable()
export class ReportDataCache extends BackgroundRefreshCache {

  constructor(zone: NgZone) {
    super({
      version: 2,
      objectStoreName: "reportData",
      dbName: "reportDataCache"
    }, zone);
  }

}
