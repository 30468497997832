import {Component, Inject } from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: "app-deployment-confirmation-dialog",
  templateUrl: "./deployment-confirmation-dialog.component.html"
})
export class DeploymentConfirmationDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { configIds: string[] },
              private dialogRef: MatDialogRef<DeploymentConfirmationDialogComponent>) {
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
