/**
 * Created by djabry on 22/05/2017.
 */

export interface AuthorisationAction {
    key: string;
    title: string;
    description: string;
    resourceName?: string;
}

export const AUTHORISATION_ACTION = {
    DeployWithoutUnlockTrackers: {
        key: "DeployWithoutUnlockTrackers",
        title: "Deploy without unlock trackers",
        description: "Deploy a FreeWall without any unlock trackers specified",
        resourceName: "ad id"
    } as AuthorisationAction,
    Test: {
        key: "Test",
        title: "Test",
        description: "Test the authorisation framework"
    } as AuthorisationAction
};
