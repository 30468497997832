import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AUTHORISATION_ACTION,
  AUTHORISATION_STATE,
  AuthorisationKey,
  DeployValidationMessage,
  AuthorisationStatus
} from '@rezonence/core';
import { Optional } from "@rezonence/sdk";
import { AWSService } from '../../core/aws.service';
import { CampaignAuthorisationService } from '../../core/campaign.authorisation.service';
import { RequestDeployComponent } from '../request-deploy/request-deploy.component';
import { FreeWallProgressComponent } from '../freewall-progress/freewall-progress.component';
import { FreewallService } from './FreewallService';


@Injectable({
  providedIn: 'root'
})
export class FreeWallDeployer {
  constructor(
    private awsService: AWSService,
    private dialog: MatDialog,
    private freeWallService: FreewallService,
    private campaignAuthService: CampaignAuthorisationService,
    private snackbar: MatSnackBar
  ) {

  }

  async hasUnlockTrackers(adId: string): Promise<boolean> {
    try {
      await this.freeWallService.testFreeWall(adId);
      return true;
    } catch (err) {
      if (err.message === DeployValidationMessage.NoUnlockTrackersSpecified) {
        return false;
      }
      throw err;
    }
  }

  async toAuthKey(adId: string): Promise<AuthorisationKey> {
    const identityId = await this.awsService.identityId();
    const requestCode =
      this.campaignAuthService.toRequestCode(AUTHORISATION_ACTION.DeployWithoutUnlockTrackers.key, adId);
    return {
      identityId,
      requestCode
    };
  }

  async toAuthorisationStatus(authKey: AuthorisationKey): Promise<Optional<AuthorisationStatus>> {
    const status = await this.campaignAuthService.getStatusItem(authKey);
    return Optional.of(status);
  }

  async canRequestDeployment(adId: string): Promise<boolean> {
    const authKey = await this.toAuthKey(adId);
    const authStatus = await this.toAuthorisationStatus(authKey);
    return authStatus.map(status => status.state === AUTHORISATION_STATE.rejected, () => true).item;
  }

  async isAuthorised(adId: string): Promise<boolean> {
    const containsUnlockTrackers = await this.hasUnlockTrackers(adId);
    if (!containsUnlockTrackers) {
      const canRedeploy = await this.canRequestDeployment(adId);
      if (!canRedeploy) {
        throw new Error(DeployValidationMessage.NoUnlockTrackersSpecified);
      }
      return false;
    }
    return true;
  }

  async validateAndDeploy(adId: string): Promise<void> {
    const progressDialog = this.dialog.open(FreeWallProgressComponent, { disableClose: true });
    try {
      progressDialog.componentInstance.message = "Validating FreeWall";
      const authorised = await this.isAuthorised(adId);
      if (authorised) {
        progressDialog.componentInstance.message = "Writing files to the CDNs";
        await this.freeWallService.writeFreeWallFilesToCDN(adId);
        this.snackbar.open("Completed deploying FreeWall", "OK");
      } else {
        this.openRedeployDialog(adId);
      }
    } catch (err) {
      this.notifyOfFailure(err.message);
    } finally {
      progressDialog.close();
    }
  }

  notifyOfFailure(message: string): void {
    this.snackbar.open(`Failed to deploy: ${message}`, "OK");
  }


  private openRedeployDialog(adId: string): void {
    const requestDialog = this.dialog.open(RequestDeployComponent);
    requestDialog.componentInstance.adId = adId;
  }
}
