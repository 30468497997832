import { type DynamoDBDocumentClient, paginateScan } from "@aws-sdk/lib-dynamodb";
import { FieldName } from "./FieldName";

export class S3IndexClient {
  constructor(private dbClient: DynamoDBDocumentClient, private tableName: string) {
  }

  async* list(): AsyncIterable<Record<FieldName, string>> {
    const responses = paginateScan({
      client: this.dbClient
    }, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      TableName: this.tableName
    });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    for await (const { Items } of responses) {
      for (const item of Items) {
        yield item as Record<FieldName, string>;
      }
    }
  }
}
