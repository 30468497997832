import {Optional} from "@rezonence/sdk";

export abstract class StringParser<T> {
    parse(input: string): Optional<T> {
        return this.isValid(input) ? Optional.of(this.parseString(input)) : Optional.empty();
    }
    abstract encode(input: T): string;
    abstract isValid(input: string): boolean;

    protected abstract parseString(input: string): T;
}
