import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import {AWSService} from "./aws.service";
import { TitleService } from "./title.service";
import {ProgressService} from "./progress.service";
import {SpinnerComponent} from "./load";
import {DatePipe, DecimalPipe, PercentPipe} from "@angular/common";
import {CampaignFileService} from "./campaign.file.service";
import {FreeWallDBService} from "./FreeWallDBService";
import { CampaignUtils } from "./campaign.utils";
import { CampaignAuthorisationService } from "./campaign.authorisation.service";
import { TagGenerationService } from "./tag.generation.service";
import { FakeNexusDataService } from "./nexus.data.service";
import {HttpClientModule} from "@angular/common/http";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  declarations: [
    SpinnerComponent
  ],
  providers: [
    FakeNexusDataService,
    CampaignAuthorisationService,
    CampaignUtils,
    FreeWallDBService,
    CampaignFileService,
    AWSService,
    TitleService,
    ProgressService,
    PercentPipe,
    DatePipe,
    DecimalPipe,
    TagGenerationService
  ],
  exports: [
    SpinnerComponent
  ]
})
export class CoreModule {

}
