import { NgModule } from "@angular/core";
import { TopNavComponent } from "./TopNavComponent";
import { CoreModule } from "../core";
import { LogoutButtonComponent } from "./LogoutButtonComponent";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    TopNavComponent,
    LogoutButtonComponent
  ],
  imports: [
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    ProgressbarModule,
    RouterModule.forChild([])
  ],
  exports: [TopNavComponent]
})
export class NavigationModule { }
