import "hammerjs";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./AppComponent";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { ReportModule } from "./reports/ReportModule";
import { CoreModule } from "./core";
import { NavigationModule } from "./navigation/NavigationModule";
import { FreewallsModule } from "./freewalls";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialDesignFrameworkModule } from "@ajsf/material";
import { WelcomeComponent } from "./welcome/WelcomeComponent";
import { EditorsModule } from "./editors/editors.module";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { AuthModule } from "./auth/AuthModule";
import { routes } from "./routes";
import { CommonModule } from '@angular/common';
import { DemosModule } from "./demos";

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    WelcomeComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    DemosModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(routes),
    AuthModule,
    CoreModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NavigationModule,
    MaterialDesignFrameworkModule,
    FreewallsModule,
    EditorsModule,
    ReportModule,
  ]
})
export class AppModule { }
