<div class="creative-cap" *ngIf="(entries$ | async) as entries; else loading">
  <h3>Custom capping rules</h3>
  <ng-container *ngIf="entries | configId as configIds">
  <div class="header">
    <h4 class="adId">{{adId}}</h4>
    <div class="action-buttons">
      <button mat-button color="primary" (click)="submit(entries)">Save & Deploy</button>
    </div>
  </div>
    <app-config-multiselector [configType]="ConfigType.ConfigId"
                              [selected]="configIds"
                              [disabled]="false"
                              (selectedChange)="addOrRemoveEntries($event, entries)">
    </app-config-multiselector>
  </ng-container>
  <h3>Site overrides</h3>
  <div class="entries" *ngFor="let entry of entries">
      <app-creative-cap-entry class="entry"
                              (remove)="remove($event, entries)"
                              [entry]="entry">
      </app-creative-cap-entry>
  </div>
</div>

<ng-template #loading>
  <spinner [isRunning]="true"></spinner>
</ng-template>
