import {keyPrefix} from "./key.prefix";
import {StringParser} from "./string.parser";

export abstract class AbstractKeyParser<T> extends StringParser<T> {

    isValid(keyString: string): boolean {
        return keyString.startsWith(keyPrefix) && this.isValidSuffix(this.toSuffix(keyString));
    }

    abstract isValidSuffix(suffix: string): boolean;
    abstract encodeSuffix(input: T): string;
    abstract parseSuffix(input: string): T;

    encode(key: T): string {
        return keyPrefix + this.encodeSuffix(key);
    }

    toSuffix(input: string): string {
        return input.replace(keyPrefix, "");
    }

    parseString(input: string): T {
        return this.parseSuffix(this.toSuffix(input));
    }
}
