import { Pipe, PipeTransform } from "@angular/core";
import dayjs, { Dayjs } from 'dayjs'

@Pipe({
  name: "msToDayJs"
})
export class MsToDayJsPipe implements PipeTransform {

  transform(time: number): Dayjs {
    return dayjs(time);
  }

}
