import {Schema} from "jsonschema";
export const subSitesSchema: Schema = {
  type: "object",
  properties: {
    subSites:
      { type: "array",
        maxItems: 1000,
        uniqueItems: true,
        title: "Sub-sites",
        items: {
          type: "string",
          minLength: 5,
          pattern: "^\\S*$",
          not: { type: "null" }
        }
      }
  }
};
