import {BrowserBase64Encoder} from "@rezonence/dit";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class Base64EncoderService extends BrowserBase64Encoder {

  constructor() {
    super();
  }

}
