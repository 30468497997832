import {Injectable} from "@angular/core";
import {ReportNode} from "./report.node";
import {ReportService} from "../services/report.service";
import {Report} from "@rezonence/core";
import {ReportType} from "@rezonence/core/report/ReportType";
import {Hierarchy} from "./hierarchy";
import {FlatNodeFactory} from "./flat.node.factory";
import {DateNodeFactory} from "./date.node.factory";
import {CampaignNodeFactory} from "./campaign.node.factory";
import {NetworkNodeFactory} from "./network.node.factory";

@Injectable()
export class ReportTreeService {

  hierarchyMap: { [reportType: string]: Hierarchy };

  constructor(private reportService: ReportService) {

    this.hierarchyMap = {};

    this.hierarchyMap[ReportType.article] = Hierarchy.FLAT;
    this.hierarchyMap[ReportType.publisher] = Hierarchy.DATE_NETWORK_CAMPAIGN;
    this.hierarchyMap[ReportType.agency] = Hierarchy.DATE_CAMPAIGN;
  }

  async loadTree(reportType: string): Promise<ReportNode[]> {

    let reports = await this.reportService.listReports();
    reports = reports.filter(report => report.reportType === reportType);
    const hierarchy = this.hierarchyMap[reportType];

    return this.constructHierarchy(reports, hierarchy);

  }

  constructHierarchy(reports: Report[], hierarchy: Hierarchy): ReportNode[] {

    let nodeFactory = new FlatNodeFactory();

    if (hierarchy === Hierarchy.DATE_CAMPAIGN) {

      nodeFactory = new CampaignNodeFactory(nodeFactory);
      nodeFactory = new DateNodeFactory(nodeFactory);

    } else if (hierarchy === Hierarchy.DATE_NETWORK_CAMPAIGN) {

      nodeFactory = new CampaignNodeFactory(nodeFactory);
      nodeFactory = new NetworkNodeFactory(nodeFactory);
      nodeFactory = new DateNodeFactory(nodeFactory);

    }

    return nodeFactory.getChildren(reports);

  }

}
