import { Injectable, NgZone } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {FreeWallProgressComponent} from "../freewall-progress/freewall-progress.component";

@Injectable({
  providedIn: "root"
})
export class ProgressDialogService {

  matDialogRef: MatDialogRef<FreeWallProgressComponent, any>;

  set message(message: string) {
    this.matDialogRef.componentInstance.message = message;
  }

  constructor(private matDialog: MatDialog) {
  }

  open() {
    this.matDialogRef = this.matDialog.open(FreeWallProgressComponent, {disableClose: true});
  }

  async close() {
    this.matDialogRef.close();
    await this.matDialogRef.afterClosed();
  }

}
