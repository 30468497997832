import { DemosTableComponent } from "./table";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DemosComponent } from "./DemosComponent";
import { CoreModule } from "../core";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { Route } from "../freewalls"
import { DemoLinkPipe } from "./DemoLinkPipe";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { DemoPreviewComponent } from "./DemoPreviewComponent";
import { AdIdPipe } from "./AdIdPipe";
import { BannerPipe } from "./BannerPipe";
import { FreeWallConfigPipe } from "./FreeWallConfigPipe";

@NgModule({
  imports: [
    CoreModule,
    MatInputModule,
    MatFormFieldModule,
    BrowserModule,
    MatTableModule,
    CommonModule,
    MatSortModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatToolbarModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: Route.Demos,
        component: DemosComponent
      },
    ])
  ],
  declarations: [
    AdIdPipe,
    BannerPipe,
    FreeWallConfigPipe,
    DemoPreviewComponent,
    DemoLinkPipe,
    DemosComponent,
    DemosTableComponent,
  ]
})
export class DemosModule {
}
