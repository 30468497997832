<div>
  <div *ngIf="mode === DialogMode.Initial">
    <p class="{{DialogMode.Initial}}">Are you sure you want to delete <strong>{{deleteRequest.configId}}</strong>?</p>
    <div>
      <button mat-button color="warn" (click)="deleteConfig()">Delete</button>
      <button mat-button color="primary" (click)="cancel()">Cancel</button>
    </div>
  </div>
  <div *ngIf="mode === DialogMode.Deleting">
    <p>{{deletionMessage}}</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="mode === DialogMode.Error">
    <p>{{errorMessage}}</p>
  </div>
</div>
