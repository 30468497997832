import { Component, Input, EventEmitter, Output } from "@angular/core";
import {KeyValue} from "@rezonence/dao";
import {CreativeCap} from "@rezonence/core/config-extractor/publisher/creative.cap";
import { defaultCap } from "../creative-cap/default.cap";
import {frequencyCapTitle} from "./frequency.cap.title";
import {FrequencyCap} from "@rezonence/core";

@Component({
  selector: "app-creative-cap-entry",
  templateUrl: "./creative-cap-entry.component.html",
  styleUrls: ["./creative-cap-entry.component.css"]
})
export class CreativeCapEntryComponent {

  DefaultCap = defaultCap;
  CapTitle = frequencyCapTitle;
  requiredCaps = [FrequencyCap.Unlock, FrequencyCap.InView];

  @Input()
  entry: KeyValue<string, CreativeCap>;

  @Output()
  remove = new EventEmitter<KeyValue<string, CreativeCap>>();

  constructor() { }

}
