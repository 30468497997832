import { Pipe, PipeTransform } from "@angular/core";
import { FreeWallConfig, CDN } from "@rezonence/core";
import { ConfigResolverService } from "../core";
import { Optional } from "@rezonence/sdk";

@Pipe({
    name: "freewallConfig"
})
export class FreeWallConfigPipe implements PipeTransform {

    constructor(private configResolver: ConfigResolverService) {
    }

    async transform(adId: string): Promise<Optional<FreeWallConfig>> {
        return this.configResolver.resolveFreeWallConfig({ adId, cdn: new URL(CDN.S3) })
    }

}
