import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "removeDisplayPrefix"
})
export class RemoveDisplayPrefixPipe implements PipeTransform {

  private displayPrefix = "display";

  transform(value: string): string {
    return value.startsWith(this.displayPrefix) ? value.replace(this.displayPrefix, "") : value;
  }

}
