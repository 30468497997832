import {Injectable} from "@angular/core";
import {NexusService} from "@rezonence/core/nexus/nexus.service";
import {NexusPlacement} from "@rezonence/core/nexus/nexus.placement";
import {NexusCampaign} from "@rezonence/core/nexus/nexus.campaign";

@Injectable()
export class FakeNexusDataService implements NexusService {
  campaigns: NexusCampaign[];
  placements: {[campaignId: number]: Promise<NexusPlacement[]>};
  constructor() {
    this.placements = {};
    this.campaigns = [
      {Id: 1, ClientName: "Ebay", Description: "Ebay summer deals 2018"} as NexusCampaign,
      {Id: 2, ClientName: "Sky", Description: "Sky more money 2018"} as NexusCampaign
    ];
  }

  listPlacements(campaign: NexusCampaign): Promise<NexusPlacement[]> {

    const placementsPromise = this.placements[campaign.Id];
    if (!placementsPromise) {

      const sites = ["www.mirror.co.uk", "www.independent.co.uk"];
      const placements =  sites.map((site, index) => ({
          Id: campaign.Id * sites.length + index,
          Description: `${campaign.ClientName} on ${site}`
        } as any as NexusPlacement));

      this.placements[campaign.Id] = Promise.resolve(placements);
    }

    return this.placements[campaign.Id];

  }

  async listCampaigns(): Promise<NexusCampaign[]> {
    return this.campaigns;
  }

}
