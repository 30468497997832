import { Placeholder } from './placeholder'
import { campaignIdPlaceholder } from './campaign.id.placeholder'

export const PLACEHOLDER_VALUES: Record<Placeholder, string[]> = {
  networkId: [
    '<NETWORK_ID>',
    '[NETWORK_ID]'
  ],
  configId: [
    '[CONFIG_ID]',
    '<CONFIG_ID>'
  ],
  engagementMacro: [
    '[YOUR_ENGAGEMENT_TRACKER]'
  ],
  dealId: [
    '[DEAL_ID]'
  ],
  lineItemId: [
    '${CPG_ID}'
  ],
  adId: [
    '<ADVERTISER_ID>/<CAMPAIGN_ID>/<AD_ID>',
    '[ADVERTISER_ID]/[CAMPAIGN_ID]/[AD_ID]'
  ],
  nexusPlacementId: [
    '[PLACEMENT_ID]'
  ],
  vastTagUrl: [
    '[VAST_TAG]'
  ],
  imageSource: [
    '[IMG_SRC]'
  ],
  imageLink: [
    '[IMG_LNK]'
  ],
  campaignId: [
    campaignIdPlaceholder
  ]
}
