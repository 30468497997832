import {DB} from "idb";
import idb from "idb";
import {CacheItem} from "./cache.item";
import {KeyValueCacheConfig} from "./key.value.cache.config";
import {Duration} from "@rezonence/duration";

/**
 * A simple key-value store based on IndexedDB
 */
export class KeyValueCache {

  private dbPromise: Promise<DB>;

  constructor(protected config: KeyValueCacheConfig) {
  }

  async openDb(): Promise<DB> {
    this.dbPromise = this.dbPromise || idb.open(this.config.dbName, this.config.version, upgradeDB => {
      const storeName = this.config.objectStoreName;
      if (upgradeDB.objectStoreNames.contains(storeName)) {
        upgradeDB.deleteObjectStore(storeName);
      }
      upgradeDB.createObjectStore(this.config.objectStoreName);
    });
    return this.dbPromise;
  }

  async remove(key: string): Promise<void> {
    const db = await this.openDb();
    const transaction = db.transaction(this.config.objectStoreName, "readwrite");
    const store = transaction.objectStore(this.config.objectStoreName);
    await store.delete(key);
  }

  isCacheItemInDate<T>(item: CacheItem<T>, maxAgeMs: number): boolean {
    const timeStamp = new Date().getTime();
    const minTime = timeStamp - maxAgeMs;
    return item.modified.getTime() > minTime;
  }

  async putCacheItem<T>(key: string, cacheItem: CacheItem<T>): Promise<void> {
    const db = await this.openDb();
    const writeTransaction = db.transaction(this.config.objectStoreName, "readwrite");
    const writeStore = writeTransaction.objectStore(this.config.objectStoreName);
    await writeStore.put(cacheItem, key);
  }

  async findCacheItem<T>(key: string): Promise<CacheItem<T> | undefined> {
    const db = await this.openDb();
    const readTransaction = db.transaction(this.config.objectStoreName, "readonly");
    const store = readTransaction.objectStore(this.config.objectStoreName);
    return (await store.get(key)) as CacheItem<T>;
  }

  async refreshCacheItem<T>(key: string, executor: () => Promise<T>): Promise<CacheItem<T>> {
    const cacheItem = {
      value: await executor(),
      modified: new Date()
    } as CacheItem<T>;
    await this.putCacheItem(key, cacheItem);
    return cacheItem;
  }

  async get<T>(key: string, executor: () => Promise<T>, maxAgeMs: number = Duration.MsInHour): Promise<T> {
    let cacheItem = await this.findCacheItem<T>(key);
    if (!cacheItem || !this.isCacheItemInDate(cacheItem, maxAgeMs)) {
      cacheItem = await this.refreshCacheItem(key, executor);
    }
    return cacheItem.value;
  }
}
