import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "reports",
  templateUrl: "./reports.component.html"
})
export class ReportsComponent {

  tabs = [
    {
      title: "Agency",
      path: "agency"
    },
    {
      title: "Publisher",
      path: "publisher"
    }
  ];

  private _selectedIndex: number;

  constructor(private route: ActivatedRoute, private router: Router) {

  }

  get selectedIndex(): number {

    const state = this.router.routerState.snapshot;

    const url = state.url;

    const index = this.tabs.findIndex((tab) => {

      const listPath = tab.path;

      return url.indexOf("/" + listPath) > -1;

    });

    if (index > -1) {

      return index;

    } else {

      return this._selectedIndex;

    }

  }

  set selectedIndex(index: number) {
    // console.log("Set selected index to ", index);
    this._selectedIndex = index;
    const path = this.tabs[index].path;
    console.log("Navigating to", path);
    this.router.navigate([path], {relativeTo: this.route});

  }

}
