import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CampaignFileService } from "../../core/campaign.file.service";
import { FreewallService } from "../services/FreewallService";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Route } from "../Route";
import { DestinationPrefix, FreewallFilenamePrefix } from "@rezonence/sdk";
import { TemplateFileSuffix, compileBucket } from "@rezonence/core";
import { toFreeWallEditorUrl } from "../toFreeWallEditorUrl";
import { AdIdValidationError } from "./AdIdValidationError";

@Component({
  selector: "freewalls-freewall-create",
  templateUrl: "CreateFreeWallComponent.html",
  styleUrls: [
    "CreateFreeWallComponent.css"
  ]
})
export class CreateFreeWallComponent {

  sourceAdId: string;
  destinationAdId: string;
  creating: boolean;

  // eslint-disable-next-line max-params
  constructor(private fileService: CampaignFileService,
    private router: Router,
    private freewallService: FreewallService,
    public dialogRef: MatDialogRef<CreateFreeWallComponent>,
    private snackbar: MatSnackBar,
  ) { }

  get parsedSourceAdId(): string | undefined {
    return decodeURIComponent(this.sourceAdId);
  }

  async adIdExists(adId: string): Promise<boolean> {

    if (!adId) {
      return false;
    }

    const existingFile = await this.fileService.isFile({
      key: `${DestinationPrefix.Ads}/${adId}/${FreewallFilenamePrefix.Ad}${TemplateFileSuffix.Conf}`,
      bucket: compileBucket
    });

    return !!existingFile;
  }

  async create() {
    try {
      await this.validateImportFreeWallRequest();
    } catch (err) {
      if (err instanceof AdIdValidationError) {
        this.snackbar.open(err.message, "OK");
      }
      throw err;
    }
    await this.importFreeWall();
  }

  async importFreeWall(): Promise<void> {
    this.creating = true;
    try {
      await this.freewallService.createNew({ sourceId: this.parsedSourceAdId, destinationId: this.destinationAdId });
      location.href = toFreeWallEditorUrl(this.destinationAdId);
    } catch (err) {
      console.error(err);
      this.snackbar.open("Failed to import FreeWall: ", err);
    } finally {
      this.creating = false;
    }
  }

  close() {
    this.dialogRef.close();
    this.router.navigate([Route.FreeWalls]);
  }

  async validateImportFreeWallRequest(): Promise<void> {
    await Promise.all([
      this.validateExists(this.parsedSourceAdId),
      this.validateFormat(this.destinationAdId)
    ]);
  }

  async validateFormat(adId: string): Promise<void> {
    if (adId?.split("/").length !== 3) {
      throw new AdIdValidationError(`"${adId}" isn't a valid ad ID`);
    }
  }

  async validateExists(adId: string): Promise<void> {
    if (!await this.adIdExists(adId)) {
      throw new AdIdValidationError(`FreeWall "${adId}" doesn't exist`);
    }
  }
}
