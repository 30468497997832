import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
import { DatatableComponent } from '@swimlane/ngx-datatable'
import { FreewallService } from '../services/FreewallService'
import { ConfigListItem } from '../config.list.item'
import { ConfigType } from '@rezonence/core'
import { CreateFreeWallComponent } from '../freewall-create'
import { ConfigWizardComponent } from '../config-wizard/config-wizard.component'
import { DuplicateWizardComponent } from '../duplicate-wizard/duplicate-wizard.component'
import { MatDialog } from '@angular/material/dialog'
import { DitKeysComponent } from '../dit-keys/dit-keys.component'
import { Route } from '../Route'
import { FreeWallDeployer } from '../services/FreeWallDeployer'
import { CreateTagComponent } from '../tag-create/tag-create.component'
import { demoSiteOrigin } from '@rezonence/freewall-demo-client'

@Component({
  selector: 'config-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'ConfigListComponent.html',
  styleUrls: [
    'ConfigListComponent.css'
  ]
})

export class ConfigListComponent implements OnInit {
  @ViewChild(DatatableComponent)
  table: any

  @Input()
  configLabelName: string

  @Input()
  configType: ConfigType

  @Output()
  edit = new EventEmitter<string>()

  adIdConfigType = ConfigType.AdId

  demoPage = demoSiteOrigin

  createButtonText: string

  configListItems: Promise<ConfigListItem[]>

  constructor(
    private freewallService: FreewallService,
    private router: Router,
    public dialog: MatDialog,
    private zone: NgZone,
    private freeWallDeployer: FreeWallDeployer
  ) {

  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row)
    this.table.rowDetail.toggleExpandRow(row)
  }

  ngOnInit(): void {
    this.createButtonText = this.configType === ConfigType.AdId ? 'Import demo' : 'Create new'
    this.configListItems = this.listItems()
  }

  refreshItems() {
    this.configListItems = this.freewallService.refresh(this.configType)
  }

  async editItem(configId: string) {
    if (this.configType === ConfigType.AdId) {
      this.edit.emit(configId)
    } else if (this.configType === ConfigType.ConfigId) {
      await this.router.navigate(['config'], { queryParams: { configId } })
    }
  }

  getRowHeight(): number {
    return window.innerHeight - 100
  }

  async listItems(): Promise<ConfigListItem[]> {
    return await this.freewallService.list(this.configType)
  }

  createNew() {
    const dialogOptions = { closeOnNavigation: true };
    if (this.configType === ConfigType.AdId) {
      this.dialog.open(CreateFreeWallComponent, dialogOptions)
    } else if (this.configType === ConfigType.ConfigId) {
      this.dialog.open(ConfigWizardComponent, dialogOptions)
    }
  }

  duplicate(configId: string) {
    const ref = this.dialog.open(DuplicateWizardComponent, {
      data: { configId }
    })
    ref.afterClosed().subscribe((duplicated) => {
      if (duplicated) {
        this.refreshItems()
      }
    })
  }

  openDitDialog(adId: string) {
    const ditKeysDialog = this.dialog.open(DitKeysComponent)
    ditKeysDialog.componentInstance.adId = adId
  }

  async editCreativeCap(adId: string): Promise<void> {
    await this.router.navigate([Route.CreativeCap], { queryParams: { adId } })
  }

  async deployFreeWall(adId: string): Promise<void> {
    await this.freeWallDeployer.validateAndDeploy(adId)
  }

  createTag(adId: string): void {
    const dialogRef = this.dialog.open(CreateTagComponent);
    dialogRef.componentInstance.adId = adId;
  }
}
