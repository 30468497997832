import {PublisherConfigMappingDao} from "./publisher.config.mapping.dao";
import {PublisherConfigMapping} from "./publisher.config.mapping";
import {ConfigIdKey} from "./config.id.key";
import {DynamoError} from "@rezonence/core/dynamo/dynamo.error";
import {Optional} from "@rezonence/sdk";
import cloneDeep from "lodash/cloneDeep";

export class MockPublisherConfigMappingDao extends PublisherConfigMappingDao {

  constructor(public mappings: PublisherConfigMapping[] = []) {
    super(Promise.resolve(null), "foo");
  }

  cloneIfTruthy<T>(input: T): T | undefined {
    return input ? cloneDeep(input) as T : undefined;
  }

  async find(key: ConfigIdKey): Promise<Optional<PublisherConfigMapping>> {
    const existingItem = this.mappings.find(m => m.configId === key.configId);
    return Optional.of(this.cloneIfTruthy(existingItem));
  }

  async listMappingsForConfig(configId: string): Promise<PublisherConfigMapping[]> {
    return this.cloneIfTruthy(this.mappings.filter(mapping => mapping.parentConfigId === configId));
  }

  async updateLastModified(configId: string, date: Date): Promise<void> {
    const existingMapping = this.mappings.find(m => m.configId === configId);
    if (existingMapping) {
      existingMapping.lastModified = date.toISOString();
    } else {
      const error = new Error();
      (error as any).code = DynamoError.ConditionalCheckFailedException;
      throw error;
    }
  }

  async save(objects: PublisherConfigMapping[]): Promise<void> {
    for (const object of objects) {
      const existingObject = this.mappings.find(m => m.configId === object.configId);
      if (existingObject) {
        Object.assign(existingObject, object);
      } else {
        this.mappings.push(this.cloneIfTruthy(object));
      }
    }
  }

  async delete(objectsToDelete: ConfigIdKey[]): Promise<void> {
    const configIdsToDelete = objectsToDelete.map(o => o.configId);
    this.mappings = this.mappings.filter(m => !configIdsToDelete.includes(m.configId));
  }
}
