import { Injectable } from '@angular/core'
import { Auth0Client, IdToken } from '@auth0/auth0-spa-js'
import {
  switchMap,
  filter,
  Observable,
  map
} from 'rxjs'
import { AppState } from './AppState'
import { AuthNav } from './AuthNav'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  readonly idToken$: Observable<IdToken> = this.nav.routeEntered$.pipe(
    switchMap(route => this.onRouteEntered(route)),
    switchMap(() => this.auth0.getIdTokenClaims()),
    filter(token => token !== undefined),
    map((token) => token as IdToken)
  )

  constructor(private auth0: Auth0Client, private nav: AuthNav) { }

  logout(returnTo: string): Promise<void> {
    return this.auth0.logout({ logoutParams: { returnTo } })
  }

  private async handleCallback(requestedRoute: string): Promise<void> {
    const result =
      await this.auth0.handleRedirectCallback<AppState>(requestedRoute)
    await this.nav.redirectToRequestedRoute(result)
  }

  private async validateSession(requestedRoute: string): Promise<void> {
    try {
      await this.auth0.getTokenSilently()
    } catch (thrown) {
      if (thrown.error === 'login_required') {
        await this.auth0.loginWithRedirect({ appState: { requestedRoute } })
      } else {
        throw thrown
      }
    }
  }

  private async onRouteEntered(route: string): Promise<void> {
    if (this.nav.isCallback(route)) {
      await this.handleCallback(route)
    } else {
      await this.validateSession(route)
    }
  }
}
