import {Injectable} from "@angular/core";
import {DitEncoder, Encodable} from "@rezonence/dit";
import {Base64EncoderService} from "./base64.encoder.service";

@Injectable({
  providedIn: "root"
})
export class DitEncoderService extends DitEncoder<Encodable> {

  constructor(private base64EncoderService: Base64EncoderService) {
    super(base64EncoderService);
  }

}
