import { Pipe, PipeTransform } from "@angular/core";
import {ConfigListItem} from "../config.list.item";
import { FreewallSearchService } from "./freewall-search.service";

@Pipe({
    name: "freewallsearch",
    pure: false
})
export class FreewallSearchPipe implements PipeTransform {

    constructor(private freewallSearchService: FreewallSearchService) {

    }

    // Only called after search term checked for not null
    cleanSearchTerm(): string {
        return this.freewallSearchService.getSearch().toLowerCase();
    }

    searchTermNotNull(): boolean {
        return !!this.freewallSearchService.getSearch();
    }

    filterFreewalls(freewalls: ConfigListItem[]): ConfigListItem[] {
        return freewalls.filter(
            freewall => freewall.configId.toLowerCase().indexOf(
                this.cleanSearchTerm()) !== -1
            );
    }

    transform(freewalls: ConfigListItem[]): ConfigListItem[] {
        if (!freewalls) {
            return [];
        } else if (!this.searchTermNotNull()) {
            return freewalls;
        }
        return this.filterFreewalls(freewalls);
    }
}
