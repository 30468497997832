import { Injectable } from "@angular/core";
import {SubSitesAdapter} from "@rezonence/publisher-config-dao";
import {PublisherConfigMappingService} from "../../reports/services/publisher.config.mapping.service";

@Injectable({
  providedIn: "root"
})
export class SubSitesAdapterService extends SubSitesAdapter {

  constructor(configMappingService: PublisherConfigMappingService) {
    super(configMappingService);
  }

}
