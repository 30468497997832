<form>
  <div class="search-container">
    <mat-form-field class="search">
      <input [(ngModel)]="searchTerm" placeholder="Search" type="text" name="search" matInput>
    </mat-form-field>
    <div class="form-group create-new">
      <button title="Create a new report" *ngIf="reportService.canWrite$ | async" type="button" mat-button color="primary"
              (click)="createNew()">
        Create new
      </button>
    </div>
  </div>

</form>
<div class="report-list">
  <accordion>
    <report-node-component *ngFor="let reportNode of reportNodes | async" [reportNode]="reportNode"
                           (onReportDeleted)="onReportDeleted($event)">
    </report-node-component>
  </accordion>
</div>

