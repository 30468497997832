import {AnswerData} from "./answer.data";
import {Encodable} from "./encodable";
import {DitEncoder} from "./dit.encoder";
import {CryptoUtils} from "@rezonence/core";

export class UserPreferenceEncoder {

    constructor(private encoder: DitEncoder<Encodable>) {
    }

    encode(answerData: AnswerData): string {
        return CryptoUtils.calculateMd5Hash(this.encoder.encode(answerData));
    }

}
