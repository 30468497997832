import {Injectable} from "@angular/core";

@Injectable()
export class ProgressService {

  private listeners: any[] = [];

  private _max: number = 0;

  get max(): number {
    return this._max;
  }

  set max(newMax: number) {
    this._max = newMax;

    this.updateListeners();
  }

  private _value: number = 0;

  get value(): number {
    return this._value;
  }

  set value(newValue: number) {
    this._value = newValue;
    this.updateListeners();
  }

  updateListeners() {
    this.listeners.forEach(listener => {
      listener();
    });
  }

  addProgressListener(listener: () => void) {
    this.listeners.push(listener);
  }

}
