import { NoShowReason } from '@rezonence/freewall-events'
import { Duration } from '@rezonence/duration'
import { demoSiteOrigin } from '@rezonence/freewall-demo-client'

export const environment = {
  production: true,
  tagDownloadBase: 'https://r3z.co/tag',
  reportServiceLambdaName: 'report-prod-data',
  devReportServiceLambdaName: 'report-dev-data',
  writeCheckReportName: 'check_can_write_report',
  identityPoolId: 'eu-west-1:0c1216f6-9d6c-402e-ba72-a35cbf6d90a6',
  listS3FunctionName: 'full-list-service',
  auth0RezonenceDomain: 'rezonence.auth0.com',
  configEndpoint: 'https://mfk43w9xte.execute-api.eu-west-1.amazonaws.com/production/config',
  auth0Key: 'aTaQlnxGs32kKckJp0P8sV5GUiv6u2aL',
  localStorageTokenKey: 'token',
  localStorageProfileKey: 'profile',
  defaultArticle: demoSiteOrigin,
  rezonenceAPI: 'https://mfk43w9xte.execute-api.eu-west-1.amazonaws.com/production',
  configModifiedDataMaxAge: 15 * Duration.MsInMinute,
  redirectUrlKey: 'redirectUrl',
  loggedInKey: 'loggedIn',
  eventTimeIndexName: 'eventTime-index',
  siteTimeIndexName: 'pid-eventTime-index',
  unintendedNoShows: [NoShowReason.NoPlaceholder, NoShowReason.VideoError]
}
