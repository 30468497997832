import { Component, OnInit } from "@angular/core";
import {TitleService} from "../../core";
import { ConfigType } from "@rezonence/core";

@Component({
  selector: "app-publisher-list",
  templateUrl: "./publisher-list.component.html",
  styleUrls: ["./publisher-list.component.css"]
})
export class PublisherListComponent implements OnInit {

  configType = ConfigType.ConfigId;
  constructor(public titleService: TitleService) { }

  ngOnInit() {
    this.titleService.title = "Publisher configs";
  }

}
