import {Component, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TitleService} from "../../core";
import {ActivatedRoute, Router} from "@angular/router";
import {SchemaService} from "../services/schema.service";
import {PublisherConfigType, EditableType, ConfigItem} from "@rezonence/publisher-config-dao";
import {FreewallService} from "../services/FreewallService";
import {CampaignUtils} from "../../core/campaign.utils";
import {FormService} from "../services/form.service";
import {LayoutDefinition} from "../services/layout.definition";
import {SaveService} from "../services/save.service";
import {DsTagService} from "../services/ds-tag.service";
import {WidgetLibraryService} from "@ajsf/core";
import { CustomNumberComponent } from "../custom-number/custom.number.component";
import {Optional} from "@rezonence/sdk";
import {tabTitle} from "./tab.title";
import {Config} from "@rezonence/core/freewall-compiler/config";
import {EditorComponent} from "../../editors/editor/editor.component";
import { Route } from "../Route";
import { JsonSchemaFormErrorMessage } from "../../editors";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {Schema} from "jsonschema";
import {ProgressDialogService} from "../services/progress-dialog.service";

@Component({
  selector: "app-pub-conf-editor.component",
  templateUrl: "./pub-conf-editor.component.html",
  styleUrls: ["./pub-conf-editor.component.css"],
  encapsulation: ViewEncapsulation.None
})

export class PubConfEditorComponent implements OnInit {
  isValid: boolean;
  configId: string;
  schemaPromise: Promise<Optional<Schema>>;
  configPromise: Promise<Optional<ConfigItem<EditableType>>>;
  tabs: {title: string}[];
  configType: PublisherConfigType;
  layoutPromise: Promise<Optional<LayoutDefinition<EditableType>>>;
  PublisherConfigType = PublisherConfigType;
  validationErrors: JsonSchemaFormErrorMessage[];

  @ViewChild(EditorComponent)
  editor: EditorComponent;

  private _advancedMode: boolean;

  constructor(public titleService: TitleService,
              private route: ActivatedRoute,
              private schemaService: SchemaService,
              private router: Router,
              public progressDialogService: ProgressDialogService,
              private freewallService: FreewallService,
              private snackbar: MatSnackBar,
              private utils: CampaignUtils,
              private formService: FormService,
              private saveService: SaveService,
              private dsTagService: DsTagService,
              private widgetService: WidgetLibraryService) {
    this.widgetService.registerWidget("range", "number");
    this.widgetService.registerWidget("number", CustomNumberComponent);
  }

  get advancedMode(): boolean {
    return this._advancedMode;
  }

  set advancedMode(mode: boolean) {
    this._advancedMode = mode;
    this.applyConfigAndSchema();
  }

  getConfigType(label: string): PublisherConfigType {
    return Object.keys(tabTitle).find(configType => label === tabTitle[configType]) as PublisherConfigType;
  }

  async onLinkClick(event: MatTabChangeEvent) {
    this.validationErrors = [];
    this.configType = this.getConfigType(event.tab.textLabel);
    await this.applyConfigAndSchema();
  }

  toConfigItem(configObject: EditableType): ConfigItem<EditableType> {
    const configString = typeof configObject === "string" ?
      configObject : configObject ? JSON.stringify(configObject) : configObject as unknown as string;
    return new ConfigItem(configString);
  }

  async saveData(configObject: EditableType, schema: Schema) {
      console.log("Saving...");
      this.progressDialogService.open();
      this.progressDialogService.message = `Saving ${this.configId}...`;
      try {
        const config = this.toConfigItem(configObject);
        await this.saveService.save({
          config,
          schema,
          configId: this.configId,
          configType: this.configType,
          debug: this.isDebugMode()
        });
        await this.progressDialogService.close();
        this.snackbar.open(`Saved ${this.configId}`, "OK");
      } catch (err) {
        console.error(err);
        await this.progressDialogService.close();
        this.snackbar.open(`Failed to save ${this.configId}: ${err}`, "OK");
      } finally {
        await this.progressDialogService.close();
      }
  }

  isDebugMode(): boolean {
    const debugValue = this.route.snapshot.queryParamMap.get("debug");
    return debugValue ? debugValue.toLowerCase() === "true" : debugValue === "";
  }

  async createDoubleserveTag() {
    console.log("Downloading tag...");
    await this.dsTagService.downloadTag(this.configId);
  }

  async deploy() {

    this.progressDialogService.open();

    try {
      this.progressDialogService.message = "Writing files to the CDNs";
      await this.freewallService.writePublisherFilesToCDN(this.configId);
      await this.progressDialogService.close();
      this.snackbar.open(`Completed deploying ${this.configId}`, "OK");
    } catch (err) {
      console.error(err);
      await this.progressDialogService.close();
      this.snackbar.open(`Failed to deploy: ${err}`, "OK");
    } finally {
      await this.progressDialogService.close();
    }

  }

  async getFormLayout(): Promise<Optional<LayoutDefinition<EditableType>>> {
    const schema = await this.schemaPromise;
    return schema.exists && this.configType !== PublisherConfigType.SubSites
    && this.configType !== PublisherConfigType.CustomCode ?
      Optional.of(await this.formService.getLayout<EditableType>(this.configType, schema.item, this.advancedMode)) :
      Optional.empty<LayoutDefinition<EditableType>>();
  }

  applyFormLayout() {
    this.layoutPromise = this.getFormLayout();
  }

  toVersion(config: Optional<ConfigItem<EditableType>>): string {
    return (config.item?.json as Config).version;
  }

  async getSchema(version?: string): Promise<Optional<Schema>> {
    if (!version) {
      const config = await this.configPromise;
      if (config.exists && config.item.isJson() && config.item.json) {
        version = this.toVersion(config);
      }
    }
    return await this.schemaService.getSchema(this.configType, version);
  }

  async applySchema(version?: string) {
    this.schemaPromise = this.getSchema(version);
    const schema = await this.schemaPromise;
    this.configPromise = this.toConfigItemWithDefaults(schema, version);
    this.applyFormLayout();
  }

  async toConfigItemWithDefaults(schema: Optional<Schema>, version?: string):
      Promise<Optional<ConfigItem<EditableType>>> {
    if (version) {
      const config = await this.configPromise;
      const updatedConfig = await this.schemaService
        .mergeConfigWithDefaults({
          configString: config.item.raw,
          type: this.configType,
          schemaOptional: schema
        });
      return Optional.of(new ConfigItem(JSON.stringify(updatedConfig)));
    } else {
      return this.configPromise;
    }
  }

  applyConfig() {
    this.configPromise = this.schemaService.getConfig(this.configType, this.configId);
  }

  applyConfigAndSchema(version?: string) {
    this.applyConfig();
    this.applySchema(version);
  }

  async ngOnInit() {
    this._advancedMode = true;
    this.tabs = Object.keys(tabTitle)
      .map(configType => ({title: tabTitle[configType]}));
    this.configId = this.route.snapshot.queryParamMap.get("configId");
    this.titleService.title = `Edit Config ${this.configId}`;
    this.configType = PublisherConfigType.Doubleserve;
    await this.applyConfigAndSchema();
  }

  async onDeleted(): Promise<void> {
    console.log(`Deleted ${this.configId}`);
    await this.router.navigate([Route.Configs]);
  }
}
