import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {PublisherCreativeCappingDao} from "@rezonence/publisher-capping-dao";
import {PublisherConfigMappingService} from "../../reports/services/publisher.config.mapping.service";
import {ConfigDownloaderService} from "./config-downloader.service";
import {SaveService} from "./save.service";
import {ConfigType} from "@rezonence/core/report/ConfigType";

@Injectable()
export class PublisherCreativeCappingDaoService extends PublisherCreativeCappingDao {

  constructor(
    publisherConfigMappingDao: PublisherConfigMappingService,
    configDownloader: ConfigDownloaderService,
    saveService: SaveService,
    route: ActivatedRoute
  ) {
    super(route.snapshot.queryParamMap.get(ConfigType.AdId), publisherConfigMappingDao, configDownloader, saveService);
  }

}
