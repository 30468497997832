import {Base64Encoder} from "./base64.encoder";

export class BrowserBase64Encoder extends Base64Encoder {

    parseString(input: string): string {
        return atob(input);
    }

    encode(input: string): string {
        return btoa(input);
    }

}
