<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-chip-list #configList [disabled]="disabled">
    <mat-chip
      [ngClass]="{'disabled': disabled}"
      *ngFor="let config of selected"
      (removed)="configRemoved(config)">
      {{config}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input #chipListInput
      [formControl]="chipListControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="configList"
      [matChipInputAddOnBlur]="true">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="configSelected($event)">
    <mat-option *ngFor="let configOption of optionList$ | async"
                [value]="configOption">
      {{configOption}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
