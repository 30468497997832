import {PublisherConfigMapping} from "./publisher.config.mapping";
import {DocumentClient} from "aws-sdk/lib/dynamodb/document_client";
import QueryInput = DocumentClient.QueryInput;
import {rootPublisherConfigId} from "./root.publisher.config.id";
import {ConfigIdKey} from "./config.id.key";
import {Dao} from "@rezonence/core/dynamo/dao";
import {publisherConfigMappingTable} from "./publisher.config.mapping.table";

/**
 * Saves and updates publisher config mappings
 */
export class PublisherConfigMappingDao extends Dao<ConfigIdKey, PublisherConfigMapping> {

  constructor(dbPromise: Promise<DocumentClient>,
              tableName: string) {
    super(dbPromise, tableName);
  }

  toListQuery(publisherConfigId: string): QueryInput  {
    const parentAttributes = this.toExpressionAttributes("parentConfigId", publisherConfigId);
    const indexConfig = publisherConfigMappingTable.overrides.GlobalSecondaryIndexes[0];
    return {
      TableName: this.tableName,
      IndexName: indexConfig.IndexName,
      KeyConditionExpression: `${parentAttributes.keyName} = ${parentAttributes.valueName}`,
      ExpressionAttributeNames: {
        [parentAttributes.keyName]: parentAttributes.key
      },
      ExpressionAttributeValues: {
        [parentAttributes.valueName]: parentAttributes.value
      }
    };
  }

  async listTopMappings(): Promise<PublisherConfigMapping[]> {
    return this.listMappingsForConfig(rootPublisherConfigId);
  }

  async listMappingsForConfig(configId: string): Promise<PublisherConfigMapping[]> {
    const query = this.toListQuery(configId);
    return this.findAll(query);
  }

  async updateLastModified(configId: string, date: Date): Promise<void> {
    const db = await this.dbPromise;
    const modifiedExpressionAttributes = this.toExpressionAttributes("lastModified", date.toISOString());
    const configIdExpression = this.toExpressionAttributes("configId", configId);
    await db.update({
      TableName: this.tableName,
      Key: {
        configId
      },
      ExpressionAttributeNames: {
        [configIdExpression.keyName]: configIdExpression.key,
        [modifiedExpressionAttributes.keyName]: modifiedExpressionAttributes.key
      },
      ExpressionAttributeValues: {
        [modifiedExpressionAttributes.valueName]: modifiedExpressionAttributes.value
      },
      ConditionExpression: `attribute_exists(${configIdExpression.keyName})`,
      UpdateExpression: `SET ${modifiedExpressionAttributes.keyName} = ${modifiedExpressionAttributes.valueName}`
    }).promise();
  }
}
