import {AdServerMacro} from "./ad.server.macro";
import {AdServer} from "./ad.server";

export const AD_SERVER_MACRO_MAP: Record<AdServerMacro, Record<AdServer, string>> = {
    Engagement: {
        GAM: "%%CLICK_URL_ESC%%",
        AppNexus: "${CLICK_URL}",
        TheTradeDesk: "%%TTD_CLK%%",
        DoubleClickBidManager: "${CLICK_URL}",
        Mediamath: "[UNENCODED_CLICK_REDIRECT]",
        TURN: "XXCLICK_FORM_URL[]XX"
    }
};
