import { Pipe, PipeTransform } from "@angular/core";
import {SchemaService} from "./services/schema.service";
import {PublisherConfigType} from "@rezonence/publisher-config-dao";

@Pipe({
  name: "fileName"
})
export class FileNamePipe implements PipeTransform {

  constructor(private schemaService: SchemaService) {
  }

  transform(value: PublisherConfigType): string {
    return this.schemaService.getFilename(value);
  }

}
