import { Component, Input } from '@angular/core'
import { catchError, filter, Observable, of, pluck, shareReplay, tap } from 'rxjs'
import { AuthService } from '../auth/AuthService'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent {
  error$: Observable<Error> = this.auth.idToken$.pipe(
    catchError((thrown) => of(thrown)),
    filter(({ constructor }) => constructor === Error)
  )

  constructor(public auth: AuthService) { }
}
