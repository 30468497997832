<div class="my-theme">
  <ng-container *ngIf="auth.idToken$ | async; else loading">
    <top-nav></top-nav>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
  <ng-template #loading>
    <app-welcome></app-welcome>
  </ng-template>
</div>
