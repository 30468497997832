import { Injectable, NgZone } from "@angular/core";
import { AWSService, FwIndexClient, ReportDataService } from "../../core";
import { ActivatedRoute } from "@angular/router";
import { Utils } from "@rezonence/core/utils";
import { ReportSummary } from "../report.summary";
import { FullReport } from "../full-report";
import { ReportTable } from "@rezonence/core/report/ReportTable";
import { NetworkIdBlacklistKey } from "@rezonence/core/report/NetworkIdBlacklistKey";
import { environment } from "../../../environments/environment";
import { ReportNode } from "./report.node";
import { ReportDataCache } from "./report.data.cache";
import { ConfigListCache } from "./config.list.cache";
import { DynamoReportDB } from "@rezonence/core/report/DynamoReportDB";
import { PublisherConfigMappingService } from "./publisher.config.mapping.service";
import { DateRangeKey, Report, ReportType, defaultReportDomain } from "@rezonence/core";
import { ReportDB } from "@rezonence/core/report/ReportDB";
import { DefaultReport } from "@rezonence/core/report/DefaultReport";
import { Duration } from "@rezonence/duration";
import { ConfigType } from "@rezonence/core/report/ConfigType";
import { ParamListService } from "./ParamListService";
import { EventCountRecord, ReportDataQueryType } from "@rezonence/analytics-dao";
import { guid } from "@rezonence/sdk";
import { concatMap, Observable, shareReplay } from "rxjs";
import { AuthService } from "../../auth/AuthService";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Injectable()
export class ReportService {

  canWrite$: Observable<boolean>;
  selectedReport: Report;
  reportListPromise: Promise<Report[]>;
  utils: Utils;
  reportDBPromise: Promise<ReportDB>;
  tableName: string;
  lastWeek: number;
  defaultCachePeriod: number;
  blacklistTableName: string;

  constructor(private awsService: AWSService,
    authService: AuthService,
    private fwIndex: FwIndexClient,
    private reportDataService: ReportDataService,
    private route: ActivatedRoute,
    private zone: NgZone,
    private reportDataCache: ReportDataCache,
    private configsCache: ConfigListCache,
    private paramListService: ParamListService,
    private publisherConfigMappingService: PublisherConfigMappingService) {
    this.canWrite$ = authService.idToken$.pipe(concatMap(() => this.checkCanWrite()), shareReplay(1));
    this.initialise();
  }

  initialise() {
    console.log("Listing configs");
    this.tableName = ReportTable.Reports;
    this.blacklistTableName = ReportTable.NetworkIdBlacklist;
    this.defaultCachePeriod = Duration.MsInHour;
    this.lastWeek = (new Date()).getTime() - (Duration.MsInWeek);
    this.warmCache();
  }

  warmCache() {
    this.zone.runOutsideAngular(() => {
      // Warm the configs cache
      for (const configType of Object.values(ConfigType).filter(c => c !== ConfigType.PubId)) {
        this.listConfigs(configType);
      }
    });

  }

  isCurrent(report: Report): boolean {
    return report.endDate > this.lastWeek;
  }

  async checkCanWrite(): Promise<boolean> {
    try {
      const report = await this.createNewReport(environment.writeCheckReportName, "test" as ReportType);
      await this.saveReport(report);
      await this.deleteReport(report);
      return true;
    } catch (err) {
      return false;
    }

  }

  listNetworkIds(): Promise<string[]> {
    return this.listConfigs(ConfigType.NetworkId);
  }

  listPubIds(): Promise<string[]> {
    return this.listConfigs(ConfigType.PubId);
  }

  listConfigIds(): Promise<string[]> {
    return this.listConfigs(ConfigType.ConfigId);
  }

  async clearConfigsCache(configType: ConfigType) {
    await this.configsCache.remove(configType);
  }

  async listConfigs(listType: ConfigType): Promise<string[]> {
    return listType === ConfigType.NetworkId ? this.doListNetworkIds() : this.doListConfigs(listType)
  }

  async getCurrentStatusReports(): Promise<Report[]> {

    const reports = await this.listReports();
    const currentReports = reports.filter(report =>
      // Filter by current reports
      report.statusReport && this.isCurrent(report) && report.targetEngagements > 0
    );

    // Remove reports with duplicate configs

    const uniqueReports = [];

    for (const report of currentReports) {

      if (typeof uniqueReports
        .find(otherReport => otherReport.id !== report.id && this.configEqual(report, otherReport)) === "undefined") {
        uniqueReports.push(report);
      }
    }
    return uniqueReports;
  }

  toReportNodes(currentReports: Report[]): ReportNode[] {
    // Generate the report nodes and download the data associated with each one
    const reportNodes = currentReports
      .filter(report => report.adIds.length >= 1
        && report.configIds.length === 0
        && report.pubIds.length === 0
        && report.networkIds.length === 0
        && report.campaignIds.length === 0
        && report.statusReport)
      .map(report => ({ report, children: [] }));

    // Populate child reports
    currentReports
      .filter(childReport =>
        // Consider other reports as potential children
        typeof reportNodes.find(node => node.report.id === childReport.id) === "undefined")
      .forEach(childReport => {
        reportNodes.forEach(reportNode => {
          // If the ad ids are the same then add it as a child report
          if (JSON.stringify([].concat(reportNode.report.adIds).sort()) ===
            JSON.stringify([].concat(childReport.adIds).sort())) {

            reportNode.children.push({
              report: childReport,
              children: []
            });
          }
        });
      });
    return reportNodes;
  }

  async downloadReportData(reportNode: ReportNode): Promise<void> {
    const report = reportNode.report;
    if (this.isCurrent(report)) {
      await this.getReportData(report.id);
    }
    await Promise.all(reportNode.children.map(c => this.downloadReportData(c)));
  }

  async doListReports(): Promise<Report[]> {

    const reportDB = await this.reportDB();
    const allReports = await reportDB.findReports();
    const reports = allReports.filter(report => report.title !== environment.writeCheckReportName);
    this.zone.runOutsideAngular(() => {
      // Download the report data for every report in the background
      const reportNodes = this.toReportNodes(reports);
      reportNodes.forEach(reportNode => {
        this.downloadReportData(reportNode);
      });
    });
    return reports;

  }

  async getReportNodes(): Promise<ReportNode[]> {
    const reports = await this.getCurrentStatusReports();
    return this.toReportNodes(reports);
  }

  listReports(): Promise<Report[]> {
    if (!this.reportListPromise) {
      this.reportListPromise = this.doListReports();
    }
    return this.reportListPromise;
  }

  sameValues(arr1?: string[], arr2?: string[]): boolean {
    const arrayStrings = [arr1, arr2].map(arr => arr || [])
      .map(arr => arr.sort())
      .map(arr => JSON.stringify(arr));
    const [arrayString1, arrayString2] = arrayStrings;
    return arrayString1 === arrayString2;

  }

  sameDay(timestamp1: number, timestamp2: number): boolean {
    return (new Date(timestamp1)).toDateString() === (new Date(timestamp2)).toDateString();
  }

  isFuture(timestamp: number): boolean {
    return timestamp > (new Date()).getTime();
  }

  isToday(timestamp: number): boolean {
    const currentDate = new Date();
    return new Date(timestamp).toDateString() === currentDate.toDateString();
  }

  configEqual(report1: Report, report2: Report): boolean {
    return Object.values(ConfigType).map(c => `${c}s`)
      .every(key => this.sameValues(report1[key], report2[key]))
      && Object.values(DateRangeKey).every(key => this.sameDay(report1[key], report2[key]));
  }

  async createNewReport(title: string, reportType: ReportType): Promise<Report> {
    const report = new DefaultReport(defaultReportDomain, title, guid(), reportType);
    report.cpe = 0;
    return report;
  }

  duplicate(report: Report): Report {
    const newReport = Object.assign({}, report);
    newReport.id = guid();
    newReport.title += " copy";
    return newReport;
  }

  noCache(): boolean {
    return !!this.route.snapshot.queryParamMap.get("noCache");
  }

  getReportDataCachePeriod(report: Report): number {
    if (report.endDate) {
      const currentTime = new Date().getTime();
      const yesterday = new Date(currentTime - Duration.MsInDay);
      yesterday.setHours(0, 0, 0, 0);
      if (report.endDate < yesterday.getTime()) {
        return currentTime;
      }
    }
    return this.defaultCachePeriod;
  }

  async getReportData(reportId: string): Promise<FullReport> {
    const correspondingReport = await this.findReport(reportId);
    const cachePeriod = this.getReportDataCachePeriod(correspondingReport);
    let freshReport = false;
    const reportDataFetcher: () => Promise<FullReport> = async () => {
      const response = await this.reportDataService.request({
        reportType: ReportDataQueryType.EventCounts,
        reportId
      });
      console.log(`Downloaded report data for ${correspondingReport.title}`);
      freshReport = true;
      const data = response.item as EventCountRecord<FreewallEventColumn>[];
      return {
        data,
        summary: this.calculateReportSummary(data, correspondingReport)
      };
    };
    let reportData = await this.reportDataCache.get<FullReport>(reportId, reportDataFetcher, cachePeriod);

    if (!freshReport) {
      // If the params don't match those in the current report db then invalidate the cache

      if (!this.configEqual(correspondingReport, reportData.summary.report)) {
        console.log("Refreshing report data since report params don't match those in the cache for" +
          ` ${correspondingReport.title}`);
        await this.reportDataCache.remove(reportId);
        reportData = await this.reportDataCache.get<FullReport>(reportId, reportDataFetcher, cachePeriod);
      }
    }

    return reportData;
  }

  async saveReport(report: Report): Promise<Report> {
    const reportDB = await this.reportDB();
    await reportDB.saveReport(report);
    this.reportListPromise = null;
    await this.reportDataCache.remove(report.id);
    console.log("Saved report");
    return report;
  }

  async getCurrentConfigs(type: string): Promise<string[]> {
    const reports = await this.getCurrentStatusReports();
    const configs = {};

    for (const report of reports) {
      const reportConfigs = report[`${type}s`] as string[] || [];
      for (const config of reportConfigs) {
        configs[config] = true;
      }
    }
    return Object.keys(configs).sort();
  }

  async findReport(id: string): Promise<Report> {
    const reports = await this.listReports();
    return reports.find(report => report.id === id);
  }

  async doGetReportDB(): Promise<ReportDB> {
    const db = await this.awsService.dynamodb();
    return new DynamoReportDB(db, defaultReportDomain, this.tableName);
  }

  reportDB(): Promise<ReportDB> {
    if (!this.reportDBPromise) {
      this.reportDBPromise = this.doGetReportDB();
    }
    return this.reportDBPromise;
  }

  async deleteReport(report: Report) {
    const reportDB = await this.reportDB();
    await reportDB.deleteReport(report.id);
    console.log("Deleted report");
    await this.reportDataCache.remove(report.id);
    this.reportListPromise = null;
    return report;

  }

  private calculateReportSummary(data: EventCountRecord<FreewallEventColumn>[], report: Report): ReportSummary {
    const engagements = data.map(row => row.unlk).reduce((total, count) => total + count, 0);
    return {
      report,
      engagements
    };
  }

  private async doListNetworkIds(): Promise<string[]> {
    const configsPromise = this.doListConfigs(ConfigType.NetworkId);
    const blackListNetworks = await this.doListBlacklistNetworks();
    const networks = await configsPromise;

    return networks.filter(network => blackListNetworks.indexOf(network) === -1);

  }

  private async doListBlacklistNetworks(): Promise<string[]> {

    const db = await this.awsService.dynamodb();

    let data = await db.scan({
      TableName: this.blacklistTableName,
      ExclusiveStartKey: null
    }).promise();

    let blackListItems = (data.Items || []) as NetworkIdBlacklistKey[];

    while (data.LastEvaluatedKey) {

      data = await db.scan({
        TableName: this.blacklistTableName,
        ExclusiveStartKey: data.LastEvaluatedKey
      }).promise();

      blackListItems = blackListItems.concat(data.Items as NetworkIdBlacklistKey[] || []);
    }

    return blackListItems.map(item => item.nid);
  }

  private async doListConfigs(listType: ConfigType): Promise<string[]> {
    console.log("Listing", listType, "items");

    if (listType === ConfigType.AdId) {
      const items = await this.fwIndex.listAll();
      return items.map(v => v.configId);
    } else if (listType === ConfigType.ConfigId) {
      const topConfigMappings = await this.publisherConfigMappingService.listTopMappings();
      return topConfigMappings.map(entry => entry.configId);
    } else {
      const response = await this.paramListService.request({ type: listType });
      const configList = response.item.map(record => record.value || "");
      return configList.filter(entry => !entry.toLowerCase().includes("_deprecated"));
    }
  }

}
