<h1 mat-dialog-title>
  Deploy Configs
</h1>
<div>
  <ul *ngFor="let configId of data.configIds">
    <li role="listitem">{{configId}}</li>
  </ul>
</div>
<mat-dialog-actions align="center">
  <button mat-button color="warn" (click)="confirm()" tabindex="1">Deploy</button>
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button>
</mat-dialog-actions>
