import { Injectable } from "@angular/core";
import { ReportDataClient, ReportDataQueryType, resolveDefaultDateRangeOptions } from "@rezonence/analytics-dao";
import { InfrastructureResolver } from "../core";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Injectable({
  providedIn: "root"
})
export class ReportDataService extends ReportDataClient<ReportDataQueryType, FreewallEventColumn> {
  constructor(infra: InfrastructureResolver) {
      super(new URL(infra.config.analytics.reportDataEndpoint), resolveDefaultDateRangeOptions());
    }
}
