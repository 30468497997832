import {NodeFactory} from "./node.factory";
import {Report} from "@rezonence/core";
import {ReportGroup} from "./report.group";

export class FlatNodeFactory extends NodeFactory {
  split(reports: Report[]): ReportGroup[] {
    return reports.map(report => ({
        title: report.title,
        reports: [report]
      }));
  }

}
