<p mat-dialog-title>
  Import demo FreeWall
</p>
<div *ngIf="creating">
  <p>
    Creating FreeWall
  </p>
  <mat-progress-bar mode="indeterminate" color="primary">
  </mat-progress-bar>
</div>
<div *ngIf="!creating">
  <mat-dialog-content>
    <mat-form-field>
      <input matInput placeholder="Source ad id" [(ngModel)]="sourceAdId" required minlength="4" class="ad-id-input">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-content>
    <mat-form-field>
      <input matInput placeholder="Destination ad id" [(ngModel)]="destinationAdId" required minlength="4"
             class="ad-id-input">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="create()" color="primary">
      Create
    </button>
  </mat-dialog-actions>
</div>

