import { HourPartitionColumn } from "@rezonence/freewall-events";
import { DateColumn } from "./DateColumn";

// eslint-disable-next-line local-rules/multi-exports
export type RawTablePartitionColumn = DateColumn | HourPartitionColumn;
// eslint-disable-next-line @typescript-eslint/naming-convention
export const RawTablePartitionColumn = {
  ...DateColumn,
  ...HourPartitionColumn
}
