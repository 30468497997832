import { Component, ViewEncapsulation } from "@angular/core";
import { TitleService } from "../core/title.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "top-nav",
  templateUrl: "./top.nav.html",
  styleUrls: ["./top.nav.css"]
})
export class TopNavComponent {
  version: string;

  constructor(public titleService: TitleService) { }
}
