import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-doubleserve-editor",
  templateUrl: "./doubleserve-editor.component.html",
  styleUrls: ["./doubleserve-editor.component.css"]
})
export class DoubleserveEditorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
