<div class="config-list">
  <div *ngIf="!(configListItems | async)">
    <spinner [isRunning]="true">
    </spinner>
  </div>
  <div *ngIf="configListItems | async">
    <div class="config-search-container">
      <freewalls-freewall-search></freewalls-freewall-search>
      <ng-container class="create-config">
        <button mat-button color="primary" class="create-config-button" (click)="createNew()">
          {{createButtonText}}
        </button>
      </ng-container>
    </div>

    <ngx-datatable class="material"
                   [rowHeight]="40"
                   [rows]="configListItems | async | freewallsearch"
                   [headerHeight]="35"
                   [columnMode]="'flex'"
                   [scrollbarH]="true"
                   [scrollbarV]="true"
                   [sorts]="[{prop: 'lastModified', dir: 'desc'}]">
      <ngx-datatable-row-detail>
        <ng-template let-row="row" ngx-datatable-row-detail-template>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column [flexGrow]="1"
                            [minWidth]="200"
                            [name]="configLabelName"
                            [prop]="'configId'"
                            [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template let-value="value">
          <a (click)="editItem(row.configId)">{{value}}</a>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="0"
                            [minWidth]="200"
                            [name]="'Updated'"
                            [prop]="'lastModified'"
                            [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template let-value="value">
          {{value | date:'medium'}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="0"
                            [minWidth]="80" [cellClass]="'controlColumn'">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="row-control">
            <button mat-icon-button [matMenuTriggerFor]="appMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #appMenu="matMenu">
              <a *ngIf="configType === 'adId'" class="preview-link" target="_blank"
                 href="{{demoPage + '?aid=' + row.configId}}">
                <button mat-menu-item>
                  <mat-icon color="primary">remove_red_eye</mat-icon><span>Preview</span>
                </button></a>
              <button *ngIf="configType === 'adId'" mat-menu-item (click)="deployFreeWall(row.configId)">
                <mat-icon color="primary">rocket_launch</mat-icon><span>Deploy</span>
              </button>
              <button mat-menu-item (click)="editItem(row.configId)">
                <mat-icon color="primary">create</mat-icon><span>Edit</span>
              </button>
              <button  *ngIf="configType === 'adId'" mat-menu-item (click)="createTag(row.configId)">
                <mat-icon color="primary">handyman</mat-icon>
                <span>Create Tag</span>
              </button>
              <ng-container *ngIf="configType === 'configId'">
                <button mat-menu-item (click)="duplicate(row.configId)">
                  <mat-icon color="primary">file_copy</mat-icon><span>Duplicate</span>
                </button>
              </ng-container>
              <ng-container *ngIf="configType === adIdConfigType">
                <button mat-menu-item (click)="openDitDialog(row.configId)">
                  <mat-icon color="primary">vpn_key</mat-icon><span>DIT Keys</span>
                </button>
                <button mat-menu-item (click)="editCreativeCap(row.configId)">
                  <mat-icon color="primary">lock_clock</mat-icon><span>Creative Cap</span>
                </button>
              </ng-container>
              <app-delete-config-button [configType]="configType"
                                        [configId]="row.configId"
                                        (deleted)="refreshItems()">
              </app-delete-config-button>
            </mat-menu>
          </div>
        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
  </div>
</div>


