import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ReportService} from "./services/report.service";
import {Report} from "@rezonence/core";
import {Subscription} from "rxjs";
import {TitleService} from "../core/title.service";
import {Location} from "@angular/common";

@Component({
  styleUrls: [
    "report.editor.css"
  ],
  selector: "report-editor",
  template: "<div *ngIf='report' class='report-detail'>" +
  "<report-detail [isReportTypeSelectable]='true' [report]='report' (reportDeleted)='deleteReport()'>" +
  "</report-detail>" +
  "</div>"
})
export class ReportEditorComponent implements OnInit {

  report: Report;

  private sub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private reportService: ReportService,
              private titleService: TitleService, private _location: Location) {

  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      const id = params["id"];
      this.reportService.findReport(id).then(report => {
        this.report = report;
        this.reportService.selectedReport = report;
      });
    });
  }

  deleteReport() {
    const destination = ["reports", this.report.reportType];
    this.router.navigate(destination);
    /*this.reportService.deleteReport(this.report).then(reportDeleted => {

    });*/
  }

}
