<mat-toolbar class="top-nav">

  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <!--<div *ngIf="(campaignDB.getPermissions() | async)?.read">
      <button mat-menu-item title="Campaigns" routerLink="/campaigns">
        Campaigns
      </button>
    </div>-->
    <button mat-menu-item title="Demos" routerLink="/demos">
      Demos
    </button>
    <button mat-menu-item title="FreeWalls" routerLink="/freewalls">
      FreeWalls
    </button>
    <button mat-menu-item title="Publisher configs" routerLink="/configs">
      Publisher configs
    </button>
    <button mat-menu-item title="Reports" routerLink="/reports">
      Reports
    </button>
<!--    <button mat-menu-item title="Site stats" routerLink="/sites">
      Site stats
    </button>-->
<!--    <button mat-menu-item title="Campaign status" routerLink="/status">
      Status
    </button>-->
  </mat-menu>
  <span class="title">{{titleService.title}}</span>


  <logout-button></logout-button>


  <!--<ul class="nav navbar-nav navbar-right">
    <li>
      <a>Version {{version}}</a>
    </li>
  </ul>-->
  <!--      <div class="nav navbar-nav navbar-right" *ngIf="!loggedIn()">
          <login-button></login-button>
        </div>-->

</mat-toolbar>

<!--<div [style.margin-top]="el.nativeElement.childNodes.item(0).scrollHeight+'px'"
     class="top-progress navbar-fixed-top">
  <progressbar *ngIf="max"
               [value]="value"
               [max]="max"
               [animate]="true">
  </progressbar>
  &lt;!&ndash;<div *ngIf="!max" class="progress-space">

  </div>&ndash;&gt;
</div>-->
