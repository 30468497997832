<mat-vertical-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="configIdFormGroup">
    <form [formGroup]="configIdFormGroup">
      <ng-template matStepLabel>Choose a new configuration ID</ng-template>
      <mat-form-field>
        <input matInput placeholder="New configuration ID" formControlName="configIdInput" required>
        <ng-container *ngIf="configIdFormGroup.controls['configIdInput'].errors as errors">
          <mat-error *ngIf="errors['minlength']">Must be at least 5 characters long</mat-error>
          <mat-error *ngIf="errors['pattern']">Must not contain invalid characters</mat-error>
        </ng-container>
      </mat-form-field>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <div>
      <button mat-button (click)="save()">Save</button>
    </div>
  </mat-step>
</mat-vertical-stepper>
