import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import startCase from "lodash/startCase";
import {extname} from "path";
import { JsonSchemaFormErrorMessage } from "../JsonSchemaFormErrorMessage";
import {Schema} from "jsonschema";
import {EditorStyling, Language} from "@rezonence/code-editor";

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.css"]
})
export class EditorComponent implements OnInit {
  _data: any;

  codeEditorStyling: EditorStyling = {theme: "chrome"};

  @Input()
  get data() {
    return this._data;
  }

  set data(value) {
    this._data = value;
    this.dataChange.emit(this._data);
  }

  @Input()
  schema: Schema;

  @Input()
  layout: any;

  @Input()
  fileName: string;

  @Output()
  dataChange = new EventEmitter<any>();

  @Output()
  isValid: EventEmitter<boolean> = new EventEmitter();

  @Output()
  validationErrors: EventEmitter<JsonSchemaFormErrorMessage[]> = new EventEmitter();

  mode: Language;
  fileType: string;

  constructor() { }

  ngOnInit() {
    this.fileType = extname(this.fileName);
    this.mode = Language[startCase(this.fileType)];
  }

}
