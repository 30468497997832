import { Component, ViewEncapsulation } from "@angular/core";
import { AuthService } from "./auth/AuthService";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.css"]
})
export class AppComponent {
  constructor(public auth: AuthService) { }
}
