import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "freewalls-freewall-progress",
  templateUrl: "freewall-progress.component.html",
  styleUrls: [
    "freewall-progress.component.css"
  ]
})
export class FreeWallProgressComponent {

  message: string;

  constructor(public ref: MatDialogRef<FreeWallProgressComponent>) {

  }

}
