import {Dao} from "@rezonence/core/dynamo/dao";
import {DocumentClient} from "aws-sdk/lib/dynamodb/document_client";
import QueryInput = DocumentClient.QueryInput;
import {UserRecord} from "./user.record";
import {emailIndexName, emailKeyName} from "@rezonence/freewall-creator-user";
import {UserKey} from "./user.key";

export class UserDetailsDao extends Dao<UserKey, UserRecord> {
  constructor(dbPromise: Promise<DocumentClient>, tableName: string) {
    super(dbPromise, tableName);
  }

  toQuery(email: string): QueryInput {
    const emailAttributes = this.toExpressionAttributes(emailKeyName as keyof UserRecord, email);
    return {
      TableName: this.tableName,
      IndexName: emailIndexName,
      KeyConditionExpression:  `${emailAttributes.keyName} = ${emailAttributes.valueName}`,
      ExpressionAttributeNames: {
        [emailAttributes.keyName]: emailAttributes.key as string
      },
      ExpressionAttributeValues: {
        [emailAttributes.valueName]: emailAttributes.value
      }
    };
  }
}
