import {NodeFactory} from "./node.factory";
import {Report} from "@rezonence/core";
import {ReportGroup} from "./report.group";

export class NetworkNodeFactory extends NodeFactory {

  split(reports: Report[]): ReportGroup[] {

    const networksMap: { [network: string]: Report[] } = {};

    const otherReports = [];

    for (const report of reports) {

      if (report.networkIds && report.networkIds.length === 1) {

        const networkId = report.networkIds[0];
        let networkReports = networksMap[networkId];
        if (!networkReports) {
          networkReports = [];
          networksMap[networkId] = networkReports;
        }
        networkReports.push(report);
      } else {
        otherReports.push(report);
      }
    }

    return Object.keys(networksMap).map(networkId => ({
        title: networkId, reports:
          networksMap[networkId]
      })).concat(otherReports.map(report => ({
        title: report.title,
        reports: [report]
      })));

  }

}
