import {Injectable} from "@angular/core";
import {AWSService} from "./aws.service";
import {AuthorisationService} from "@rezonence/core/authorisation/authorisation.service";
import {CampaignUtils} from "./campaign.utils";
import SES from "aws-sdk/clients/ses";

@Injectable()
export class CampaignAuthorisationService extends AuthorisationService {

  constructor(private awsService: AWSService, utils: CampaignUtils) {

    super(awsService.dynamodb(), awsService.getService(SES), utils);

  }
}
