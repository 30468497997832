import { Routes } from "@angular/router";
import { WelcomeComponent } from "./welcome/WelcomeComponent";

export const routes: Routes = [
  {
    path: "",
    component: WelcomeComponent
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full"
  }
];
