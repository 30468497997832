import {Injectable, NgZone} from "@angular/core";
import {BackgroundRefreshCache} from "../../cache/background.refresh.cache";

/**
 * Used for storing metadata about S3 objects
 */
@Injectable()
export class S3StatusCache extends BackgroundRefreshCache {

  constructor(zone: NgZone) {
    super({
      version: 4,
      dbName: "s3StatusCache",
      objectStoreName: "s3HeadData"
    }, zone);
  }
}
