import {UserPreferenceEncoder} from "./user.preference.encoder";
import {AnswerData} from "./answer.data";
import {KeyValueStore} from "@rezonence/dao";
import {ArrayUtils} from "@rezonence/array-utils";

export class UserPreferenceExtractor {

    constructor(private dao: KeyValueStore<Date, AnswerData>,
                private userPreferenceEncoder: UserPreferenceEncoder) {
    }

    async extractUserPreferences(): Promise<string[]> {
        const entries = await ArrayUtils.collect(this.dao.listEntries());
        return entries.map(e => this.userPreferenceEncoder.encode(e.value as AnswerData));
    }
}
