import {Component, Input, OnInit} from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import {JsonSchemaFormService} from "@ajsf/core";

@Component({
  selector: "app-custom-slider",
  templateUrl: "./custom-slider.component.html",
  styleUrls: ["./custom-slider.component.css"]
})
export class CustomSliderComponent implements OnInit {

  formControl: FormControl;
  controlName: string;
  controlValue: any;
  controlDisabled = false;
  boundControl = false;
  options: any;
  allowNegative = true;
  allowDecimal = true;
  allowExponents = false;
  lastValidNumber = "";
  @Input() layoutNode: any;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(
    private jsf: JsonSchemaFormService
  ) { }

  ngOnInit() {
    this.options = this.layoutNode.options || {};
    this.jsf.initializeControl(this, !this.options.readonly);
  }

  updateValue(event) {
    this.options.showErrors = true;
    this.jsf.updateValue(this, event.value);
  }
}
