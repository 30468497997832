import { UserDetailsDao } from "@rezonence/authenticator";
import { Injectable } from "@angular/core";
import { InfrastructureResolver, AWSService } from "../core";
import { Cacheable } from "caching-decorator";

@Injectable({
  providedIn: "root"
})
export class DemoUserDetailsDao extends UserDetailsDao {

  constructor(awsService: AWSService, infra: InfrastructureResolver) {
    super(awsService.dynamodb(), infra.config.tables.UserDetails);
  }

  @Cacheable()
  async resolveEmail(identityId: string): Promise<string | undefined> {
    const result = await this.find({ identityId });
    return result.map(user => user.email).item;
  }
}
