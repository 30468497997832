<div class="control-container">
  <mat-tab-group class="tabs" (selectedTabChange)="onLinkClick($event)">
    <mat-tab *ngFor="let tab of tabs" [label]="tab.title"></mat-tab>
  </mat-tab-group>
  <div class="button-container">
    <button mat-button color="primary" (click)="createDoubleserveTag()" matTooltip="Download DoubleServe tag" >Create DS tag</button>
    <div matTooltip="{{!isValid ? (validationErrors | formErrorFormatting | join) : ''}}">
      <button mat-button color="primary" (click)="saveData(editor.data, editor.schema)" matTooltip="Save config to S3" [disabled]="!editor || !isValid">Save</button>
    </div>
    <app-delete-config-button [configId]="configId" (deleted)="onDeleted()"></app-delete-config-button>
    <button mat-button color="warn" (click)="deploy()" matTooltip="Deploy config">Deploy</button>
  </div>
</div>

<div class="config-editor" *ngIf="configPromise | async as config; else loading">
  <ng-container *ngIf="schemaPromise | async as schema; else loading">
    <ng-container *ngIf="layoutPromise | async as layout; else loading">
      <ng-container *ngIf="config.exists; else noEdit">
        <div class="editor-form">
          <ng-container *ngIf="config.item.isJson(); else stringEditor">
            <ng-container *ngIf="schema.exists && schema.item.properties && schema.item.properties.version">
              <app-version-selector [(version)]="$any(config.item.json).version" (versionChange)="applySchema($event)">
              </app-version-selector>
            </ng-container>
            <app-editor
              [layout]="layout.item"
              [schema]="schema.item"
              [(data)]="config.item.json"
              [fileName]="configType | fileName"
              (isValid)="isValid=$event"
              (validationErrors)="validationErrors=$event">
            </app-editor>
          </ng-container>
          <ng-template #stringEditor>
            <app-editor
              [layout]="layout.item"
              [schema]="schema.item"
              [(data)]="config.item.raw"
              [fileName]="configType | fileName"
              (isValid)="isValid=$event"
              (validationErrors)="validationErrors=$event">
            </app-editor>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #noEdit>
        <no-edit (isValid)="isValid=$event"></no-edit>
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <spinner [isRunning]="true"></spinner>
</ng-template>

