import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, OnDestroy} from "@angular/core";
import {ConfigType} from "@rezonence/core/report/ConfigType";
import {ReportService} from "../services";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {combineLatest, Observable, ReplaySubject, Subscription} from "rxjs";
import {map, startWith, debounceTime} from "rxjs/operators";
import {FormControl} from "@angular/forms";

@Component({
  selector: "app-config-multiselector",
  templateUrl: "./config-multiselector.component.html",
  styleUrls: ["./config-multiselector.component.css"]
})
export class ConfigMultiselectorComponent implements OnInit, OnDestroy {
  label: string;
  inputValue$: Observable<string>;
  optionList$: Observable<string[]>;
  chipListControl: FormControl;
  selectedSubscription: Subscription;
  labelByConfigType: Record<ConfigType, string> = {
    [ConfigType.NetworkId]: "Network IDs",
    [ConfigType.PubId]: "Sites",
    [ConfigType.ConfigId]: "Configuration IDs",
    [ConfigType.AdId]: "Ad IDs",
    [ConfigType.CampaignId]: "Campaign IDs"
  };
  selected$ = new ReplaySubject<string[]>(1);
  allOptions$ = new ReplaySubject<string[]>(1);

  @Input()
  selected: string[];

  @Input()
  disabled: boolean;

  @Output()
  selectedChange = new EventEmitter<string[]>();

  @ViewChild("chipListInput") chipListInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  constructor(private reportService: ReportService) {
    this.chipListControl = new FormControl({disabled: this.disabled});
  }

  @Input()
  set configType(configType: ConfigType) {
    this.label = this.labelByConfigType[configType];
    this.initialiseOptions(configType);
  }

  async ngOnInit() {
    this.inputValue$ = this.chipListControl.valueChanges.pipe(startWith(""), debounceTime(200));
    this.selectedSubscription = this.selected$.subscribe(this.selectedChange);
    this.optionList$ = combineLatest([this.selected$, this.allOptions$, this.inputValue$])
      .pipe(map(([selected, allOptions, inputValue]) => allOptions.filter(
        o => !selected.includes(o) && o.toUpperCase().startsWith(inputValue.toUpperCase())
      )));
    this.selected$.next(this.selected);
  }

  configSelected(event: MatAutocompleteSelectedEvent) {
    const configId = event.option.viewValue;
    this.selected.push(configId);
    this.chipListControl.setValue("");
    this.chipListInput.nativeElement.value = "";
    this.selected$.next(this.selected);
  }

  configRemoved(configId: string) {
    this.selected.splice(this.selected.indexOf(configId), 1);
    this.selected$.next(this.selected);
  }

  async initialiseOptions(configType: ConfigType) {
    const configList = await this.reportService.listConfigs(configType);
    this.allOptions$.next(configList);
  }

  ngOnDestroy() {
    this.selectedSubscription.unsubscribe();
  }

}
