import { Component, OnInit } from "@angular/core";
import {Router} from "@angular/router";
import {ReportService} from "../services";
import {MatDialogRef} from "@angular/material/dialog";
import { ReportType } from "@rezonence/core";

@Component({
  selector: "app-create-report",
  templateUrl: "./create-report.component.html",
  styleUrls: ["./create-report.component.css"]
})
export class CreateReportComponent implements OnInit {

  title: string;

  public reportType: ReportType;
  public creating: boolean;
  public error: string;

  constructor(public ref: MatDialogRef<CreateReportComponent>,
              private router: Router,
              private reportService: ReportService) {

  }

  async create() {
    this.ref.disableClose = true;
    this.creating = true;

    try {

      const report = await this.reportService.createNewReport(this.title, this.reportType);
      if (this.reportType === "publisher") {
        report.displayECPM = true;
      }
      await this.reportService.saveReport(report);
      await this.router.navigate(["report", report.id]);
      this.ref.close();
    } catch (err) {
      this.error = err;
    }

    this.ref.disableClose = false;
    this.creating = false;
  }

  ngOnInit() {
  }

}
