import { Component, OnInit } from "@angular/core";
import { yougovProfilesTrackers, appConfigId } from "@rezonence/core";
import { saveAs } from "file-saver";
import { CampaignUtils } from "../../core/campaign.utils";
import { AdServer } from "@rezonence/core/tag/ad.server";
import { TagType } from "@rezonence/core/tag/tag.type";
import { TagGenerationService } from "../../core/tag.generation.service";
import { CONTENT_TYPE } from "../../core/content.type";
import { ArrayUtils } from "@rezonence/array-utils";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "freewalls-tag-create",
  templateUrl: "tag-create.component.html",
  styleUrls: [
    "tag-create.component.css"
  ]
})
export class CreateTagComponent implements OnInit {
  tagType: TagType;
  TagType = TagType;
  tagTypes: TagType[] = [TagType.Test, TagType.Live];
  adId: string;
  adServers: (AdServer | string)[];
  adServer: AdServer | string;
  campaignId: string;
  unknownAdServer = "Unknown";
  inApp = false;
  profiles = false;

  constructor(private utils: CampaignUtils,
    private tagGenerator: TagGenerationService,
    public dialogRef: MatDialogRef<CreateTagComponent>) {
  }

  ngOnInit() {
    this.adServers = [...Object.values(AdServer), this.unknownAdServer];
    this.adServer = ArrayUtils.first(this.adServers);
  }

  async download() {
    const fileName = this.generateFileName();
    const blob = this.toBlob(await this.toTagString());
    saveAs(blob, fileName);
    this.dialogRef.close();
  }

  async toTagString(): Promise<string> {
    const adServer = this.adServer === this.unknownAdServer ? null : this.adServer as AdServer;
    const branch = this.utils.getBranch();
    const configId = this.inApp ? appConfigId : "";
    return await this.tagGenerator.generateTagString({
      tagType: this.tagType,
      adId: this.adId,
      campaignId: this.campaignId,
      adServerType: adServer as AdServer,
      branch,
      configId,
      trackers: this.profiles ? yougovProfilesTrackers : {}
    });
  }

  private toBlob(tagString: string): Blob {
    return new Blob([tagString],
      {
        type: CONTENT_TYPE.plainText
      }
    );
  }

  private generateFileName(): string {
    const fileSuffix = `_${this.tagType}.txt`;
    const formattedAdId = this.adId.replace(/\//g, "-");
    return `${formattedAdId}${fileSuffix}`;
  }

}
