import { Injectable } from "@angular/core";
import { CampaignFileService } from "../../core/campaign.file.service";
import { CONTENT_TYPE } from "../../core/content.type";
import { saveAs } from "file-saver";
import { CampaignUtils } from "../../core/campaign.utils";
import { ScannedFile } from "@djabry/fs-s3";
import { tag } from "@rezonence/git"
import { CampaignTemplateResolver } from "../../core";
import { CompileFolder } from "@rezonence/core";

@Injectable({
  providedIn: "root"
})
export class DsTagService {

  constructor(private fileService: CampaignFileService,
    private utils: CampaignUtils,
    private templateResolver: CampaignTemplateResolver) {

  }

  toFileName(configId: string): string {
    return `${this.utils.replaceAll("/", "-", configId)}_Doubleserve.txt`;
  }

  async toTagFile(configId: string): Promise<ScannedFile> {
    const templateFolder = this.templateResolver.resolve({ version: tag, compileFolder: CompileFolder.Tags });
    const dsTagFileName = "doubleserve.tag.txt";
    return await this.fileService.isFile({
      ...templateFolder,
      key: `${templateFolder.key}/${dsTagFileName}`
    });
  }

  async downloadTag(configId: string): Promise<void> {
    const tagFile = await this.toTagFile(configId);
    const tagString = await this.fileService.readString(tagFile);
    const fileName = this.toFileName(configId);
    const blob = new Blob([tagString],
      {
        type: CONTENT_TYPE.plainText
      }
    );
    await saveAs(blob, fileName);
  }
}
