import { PublisherConfigType } from "./publisher.config.type";
import { environment } from "@rezonence/core/aws/environment";
import { AnyFile, FileService } from "@djabry/fs-s3";
import { DestinationPrefix, R3ZConfig } from "@rezonence/sdk";
import { publisherConfigFile } from "./publisher.config.file";
import { CompileFolder, TemplateFolderResolver } from "@rezonence/core";
import { Optional } from "@rezonence/sdk";
import { ConfigItem } from "./config.item";
import { subSitesSchema } from "./sub.sites.schema";
import { SubSitesAdapter } from "./sub-sites-adapter";
import { configFileNameByType } from "./config.file.name.by.type";
import { Schema } from "jsonschema";
import schemaDefaults from "json-schema-defaults";
import merge from "lodash/merge";
import { PublisherConfigMappingDao } from "./publisher.config.mapping.dao";

export class SchemaLocator {

  schemaFileNameMap: Record<PublisherConfigType, string> = {
    [PublisherConfigType.PublisherConfig]: publisherConfigFile.publisherConfigSchema,
    [PublisherConfigType.Doubleserve]: publisherConfigFile.doubleserveSchema,
    [PublisherConfigType.Css]: null,
    [PublisherConfigType.SubSites]: null,
    [PublisherConfigType.CustomCode]: null
  };

  schemaDataSources: Record<PublisherConfigType, (version: string) => Promise<Optional<Schema>>> = {
    [PublisherConfigType.PublisherConfig]:
      version => this.readSchemaFromS3(PublisherConfigType.PublisherConfig, version),
    [PublisherConfigType.Doubleserve]:
      version => this.readSchemaFromS3(PublisherConfigType.Doubleserve, version),
    [PublisherConfigType.Css]:
      async version => Optional.empty(),
    [PublisherConfigType.SubSites]:
      async version => Optional.of(subSitesSchema),
    [PublisherConfigType.CustomCode]:
      async version => Optional.empty()
  };

  configDataSources: Record<PublisherConfigType, (configId: string) => Promise<Optional<string>>> = {
    [PublisherConfigType.PublisherConfig]:
      (configId) => this.readConfigFileStringFromS3(PublisherConfigType.PublisherConfig, configId),
    [PublisherConfigType.Doubleserve]:
      (configId) => this.readConfigFileStringFromS3(PublisherConfigType.Doubleserve, configId),
    [PublisherConfigType.Css]: (configId) => this.readConfigFileStringFromS3(PublisherConfigType.Css, configId),
    [PublisherConfigType.SubSites]: async configId => {
      const mappings = await this.mappingsDao.listMappingsForConfig(configId);
      const subSitesList = this.subSitesAdapter.convertConfigMappings(mappings);
      return Optional.of(JSON.stringify(subSitesList));
    },
    [PublisherConfigType.CustomCode]:
      async (configId) => {
        const result = await this.readConfigFileStringFromS3(PublisherConfigType.CustomCode, configId);
        return Optional.of(result.item || "");
      }
  };

  constructor(private fileService: FileService,
    private subSitesAdapter: SubSitesAdapter,
    private mappingsDao: PublisherConfigMappingDao,
    private templateResolver: TemplateFolderResolver) {

  }

  toPublisherConfigFolder(configId: string): AnyFile {
    return {
      bucket: environment.COMPILE_BUCKET,
      key: `${DestinationPrefix.Pub}/${configId}`
    };
  }

  async readConfigFileStringFromS3(type: PublisherConfigType, configId: string): Promise<Optional<string>> {
    const configFileName = this.getFilename(type);
    const configFolder = this.toPublisherConfigFolder(configId);
    const configFile = {
      ...configFolder,
      key: `${configFolder.key}/${configFileName}`
    };

    if (await this.fileService.isFile(configFile)) {
      const configString = await this.fileService.readString(configFile);
      return Optional.of(configString);
    }

    return Optional.empty();
  }

  async readConfig<T>(type: PublisherConfigType, configId: string): Promise<Optional<ConfigItem<T>>> {
    const configString = await this.configDataSources[type](configId);
    return (await Optional.switchPromise(configString.map(async (s) => this.isJsonConfig(type)
      ? JSON.stringify(await this.mergeConfigWithDefaults({ configString: s, type }))
      : s))).map(mergedConfig => Optional.of(new ConfigItem<T>(mergedConfig)));
  }

  isJsonConfig(type: PublisherConfigType): boolean {
    return [PublisherConfigType.Doubleserve, PublisherConfigType.PublisherConfig].includes(type);
  }

  async mergeConfigWithDefaults(request: {
    configString: string;
    type: PublisherConfigType;
    schemaOptional?: Optional<Schema>;
  }): Promise<R3ZConfig> {
    const parsedConfig = JSON.parse(request.configString) as R3ZConfig;
    const schema = request.schemaOptional || await this.readSchema(request.type, parsedConfig.version);
    const defaults = schemaDefaults(schema.item || {});
    return merge(defaults, parsedConfig);
  }

  getFilename(type: PublisherConfigType) {
    return configFileNameByType[type];
  }

  async readSchemaForVersion(version: string, schemaFileName: string): Promise<Optional<Schema>> {
    const templateFolder = this.templateResolver.resolve({ version, compileFolder: CompileFolder.Pub });
    const schemaFile = {
      ...templateFolder,
      key: `${templateFolder.key}/${schemaFileName}`
    };

    if (await this.fileService.isFile(schemaFile)) {
      const schemaString = await this.fileService.readString(schemaFile);
      return Optional.of(JSON.parse(schemaString) as Schema);
    }

    return Optional.empty();
  }

  async readSchemaFromS3(type: PublisherConfigType, version: string): Promise<Optional<Schema>> {
    const schemaFileName = this.schemaFileNameMap[type];
    const versionsToTry = [
      version
    ].filter(element => !!element);

    for (const versionToTry of versionsToTry) {
      const schema = await this.readSchemaForVersion(versionToTry, schemaFileName);
      if (schema.exists) {
        return schema;
      }
    }
    return Optional.empty();
  }

  async readSchema(type: PublisherConfigType, version: string): Promise<Optional<Schema>> {
    return this.schemaDataSources[type](version);
  }

  getConfig<T>(configType: PublisherConfigType, configId: string): Promise<Optional<ConfigItem<T>>> {
    return this.readConfig(configType, configId);
  }

  getSchema(configType: PublisherConfigType, version: string): Promise<Optional<Schema>> {
    return this.readSchema(configType, version);
  }
}
