import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ReportNode} from "./tree/report.node";
import {SearchService} from "./services/search.service";
import {Report} from "@rezonence/core";

@Component({
  selector: "report-node-component",
  templateUrl: "./report.node.component.html",
  styleUrls: [
    "./report.node.component.css"
  ]
})
export class ReportNodeComponent {

  @Output()
  onReportDeleted: EventEmitter<Report>;
  @Input()
  reportNode: ReportNode;

  constructor(private searchService: SearchService) {

    this.onReportDeleted = new EventEmitter<Report>();
  }

  isOpen(): boolean {
    return this.reportNode.title === "Current" || this.expandNode(this.reportNode);

  }

  reportDeleted(report: Report) {
    this.onReportDeleted.emit(report);
  }

  display(): boolean {
    return !this.searchService.search || this.isOpen();
  }

  expandNode(reportNode: ReportNode): boolean {

    if (this.searchService.search) {

      const searchString = this.searchService.search.toLowerCase();
      const title = reportNode.report ? reportNode.report.title : reportNode.title;
      return title.toLowerCase().indexOf(searchString) > -1 ||
        !!(reportNode.children || []).find(childNode => this.expandNode(childNode));

    } else {

      return false;
    }

  }

}
