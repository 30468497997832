<mat-form-field
  [class]="options?.htmlClass || ''"
  [floatLabel]="options?.floatLabel || (options?.notitle ? 'never' : 'auto')"
  [style.width]="'100%'">
      <span matPrefix *ngIf="options?.prefix || options?.fieldAddonLeft"
            [innerHTML]="options?.prefix || options?.fieldAddonLeft"></span>
  <input matInput *ngIf="boundControl"
         [formControl]="formControl"
         [attr.aria-describedby]="'control' + layoutNode?._id + 'Status'"
         [max]="options?.maximum"
         [min]="options?.minimum"
         [step]="options?.multipleOf || options?.step || 'any'"
         [id]="'control' + layoutNode?._id"
         [name]="controlName"
         [placeholder]="options?.notitle ? options?.placeholder : options?.title"
         [readonly]="options?.readonly"
         [required]="options?.required"
         [style.width]="'100%'"
         [type]="'number'"
         (blur)="options.showErrors = true">
  <input matInput *ngIf="!boundControl"
         [attr.aria-describedby]="'control' + layoutNode?._id + 'Status'"
         [max]="options?.maximum"
         [min]="options?.minimum"
         [attr.step]="options?.multipleOf || options?.step || 'any'"
         [disabled]="controlDisabled"
         [id]="'control' + layoutNode?._id"
         [name]="controlName"
         [placeholder]="options?.notitle ? options?.placeholder : options?.title"
         [readonly]="options?.readonly"
         [required]="options?.required"
         [style.width]="'100%'"
         [type]="'number'"
         [value]="controlValue"
         (input)="updateValue($event)"
         (blur)="options.showErrors = true">
  <span matSuffix *ngIf="options?.suffix || options?.fieldAddonRight"
        [innerHTML]="options?.suffix || options?.fieldAddonRight"></span>
  <!--<mat-hint *ngIf="layoutNode?.type === 'range'" align="start"-->
            <!--[innerHTML]="controlValue"></mat-hint>-->
  <mat-hint *ngIf="options?.description && (!options?.showErrors || !options?.errorMessage)"
            align="end" [innerHTML]="options?.description"></mat-hint>
</mat-form-field>
<mat-error *ngIf="options?.showErrors && options?.errorMessage"
           [innerHTML]="options?.errorMessage"></mat-error>
