import {ConfigDownloader, Optional, DestinationPrefix, ResolveConfigRequest, R3ZConfig} from "@rezonence/sdk";
import {CDN} from "./cdn";
import {FreeWallConfig} from "./freewall/freewall.config"; 
import {IdWithPrefix} from "./id.with.prefix";
import {PublisherConfig} from "./publisher/publisher.config";

export class FreeWallConfigDownloader extends ConfigDownloader {
    
    constructor(private cdnUrl = new URL(CDN.S3)) {
        super();
    }

    toDownloadRequest(request: IdWithPrefix): ResolveConfigRequest {
        return {
            cdn: this.cdnUrl,
            ...request
        };
    }

    downloadFromIdAndPrefix<T extends R3ZConfig>(request: IdWithPrefix): Promise<Optional<T>> {
        return this.downloadConfig<T>(this.toDownloadRequest(request));
    }

    downloadFreeWallConfig(adId: string): Promise<Optional<FreeWallConfig>> {
        return this.downloadFromIdAndPrefix<FreeWallConfig>({id: adId, prefix: DestinationPrefix.Ads});
    }

    downloadPublisherConfig(configId: string): Promise<Optional<PublisherConfig>> {
        return this.downloadFromIdAndPrefix<PublisherConfig>({id: configId, prefix: DestinationPrefix.Pub});
    }

}