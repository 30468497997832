<div *ngIf="reportNode && display()">


  <report-item [report]="reportNode.report" *ngIf="reportNode.report" (reportDeleted)="reportDeleted($event)">
  </report-item>

  <accordion-group *ngIf="!reportNode.report &&
  reportNode.children &&
  reportNode.children.length"
                   [heading]="reportNode.title"
                   [isOpen]="isOpen()">
    <report-node-component *ngFor="let node of reportNode.children" [reportNode]="node"
                           (onReportDeleted)="reportDeleted($event)">
    </report-node-component>
  </accordion-group>

</div>
