import { Pipe, PipeTransform } from "@angular/core";
import { FreeWallConfig } from "@rezonence/core";

@Pipe({
    name: "banner"
})
export class BannerPipe implements PipeTransform {

    transform(config: FreeWallConfig): string | undefined {
        return config.image?.img;
    }
}
