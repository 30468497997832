import {DateKeyParser} from "./date.key.parser";
import {AbstractKeyParser} from "./abstract.key.parser";
import {DitKey} from "./dit.key";
import {EventType} from "@rezonence/sdk";

/**
 * Translates legacy DIT keys into the current format
 */
export class DitKeyAdapter extends AbstractKeyParser<DitKey> {

    constructor(private delegate: DateKeyParser,
                private type: EventType = EventType.AnswerClicked) {
        super();
    }

    encodeSuffix(input: DitKey): string {
        return this.delegate.encodeSuffix(input.date);
    }

    isValidSuffix(suffix: string): boolean {
        return this.delegate.isValidSuffix(suffix);
    }

    parseSuffix(input: string): DitKey {
        const date = this.delegate.parseSuffix(input);
        return {
            date,
            type: this.type
        };
    }
}
