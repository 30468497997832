import {Component, EventEmitter, Input, Output} from "@angular/core";
import {DatePipe} from "@angular/common";
import {DateField} from "../date-field";

@Component({
  selector: "app-report-datepicker",
  templateUrl: "./report-datepicker.component.html",
  styleUrls: ["./report-datepicker.component.css"]
})
export class ReportDatepickerComponent {

  label: string;
  formattedDate: string;
  _dateField: DateField;
  dateFormats = ["y", "MM", "dd"];
  labelByDateField: Record<DateField, string> = {
    [DateField.StartDate]: "Start date",
    [DateField.EndDate]: "End date"
  };

  timeStampByDateField: Record<DateField, (date: Date) => number> = {
    [DateField.StartDate]: (date) => date.getTime(),
    [DateField.EndDate]: (date) => {
      const newDate = new Date(date);
      return newDate.setHours(23, 59, 59, 999);
    }
  };

  @Input()
  set dateField(dateField: DateField) {
    this._dateField = dateField;
    this.label = this.labelByDateField[dateField];
  }

  get dateField(): DateField {
    return this._dateField;
  }

  @Input()
  disabled: boolean;

  @Output()
  dateChange = new EventEmitter<number>();

  constructor(protected datePipe: DatePipe) { }

  @Input()
  set date(date: number) {
    this.formattedDate = this.toFormattedDate(date);
  }

  onChange(dateString: string) {
    const timeStamp = this.timeStampByDateField[this.dateField](new Date(dateString));
    this.dateChange.emit(timeStamp);
  }

  private toFormattedDate(time: number): string {
      const date = new Date(time);
      return this.dateFormats
        .map(format => this.datePipe.transform(date, format))
        .join("-");
  }

}
