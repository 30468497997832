import { BehaviorSubject } from 'rxjs';
import { SubjectMap } from './SubjectMap';
import { createSubjectMap } from './createSubjectMap';

export function createBehaviorSubjectMap<T>(
  initialValues: T
): SubjectMap<T, BehaviorSubject<any>> {
  return createSubjectMap(
    Object.keys(initialValues) as (keyof T)[],
    (key) => new BehaviorSubject(initialValues[key])
  );
}
