import {SubSitesList} from "./sub.sites.list";
import {PublisherConfigMapping} from "./publisher.config.mapping";
import {PublisherConfigMappingDao} from "./publisher.config.mapping.dao";

export class SubSitesAdapter {

  constructor(private configMappingDao: PublisherConfigMappingDao) { }

  toSubSites(fileString: string): SubSitesList {
    const list = fileString ? JSON.parse(fileString) as string[] : [];
    return {
      subSites: list.sort()
    };
  }

  convertConfigMappings(mappings: PublisherConfigMapping[]): SubSitesList {
    return {
      subSites: mappings.map(m => m.configId).sort()
    };
  }

  async saveSubSites(configId: string, subSitesList: SubSitesList) {
    const newMappings = (subSitesList.subSites || []).map(s => ({configId: s, parentConfigId: configId}));
    const existingMappings = await this.configMappingDao.listMappingsForConfig(configId);
    await this.configMappingDao.delete(existingMappings.map(m => ({configId: m.configId})));
    await this.configMappingDao.save(newMappings);
  }

  toFileString(list: SubSitesList): string {
    return JSON.stringify(list.subSites.sort());
  }
}
