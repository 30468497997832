import { CfLogColumn, RealtimeLogColumn } from "@rezonence/cloudfront-logs";
import { LogSource } from "@rezonence/log";
import { RsLogColumn } from "@rezonence/rackspace-logs";
import { SourceColumnMapping } from "./SourceColumnMapping";
import { PascalCase } from "type-fest";

export const logColumnsBySource: {[L in keyof SourceColumnMapping]:{[ K in SourceColumnMapping[L] as PascalCase<K>]: K}} = {
    [LogSource.CF]: CfLogColumn,
    [LogSource.RS]: RsLogColumn,
    [LogSource.CFRealtime]: RealtimeLogColumn
}
