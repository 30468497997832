import {ConfigItemState} from "./config.item.state";

export class ConfigItem<T> {

  private state: ConfigItemState;
  private parsedItem: T;

  constructor(private rawString: string) {
    this.raw = this.rawString;
  }

  get raw(): string {
    return this.isJson() ? JSON.stringify(this.parsedItem) : this.rawString;
  }

  set raw(rawString: string) {
    this.rawString = rawString;
    this.state = ConfigItemState.Raw;
    try {
      this.parsedItem  = JSON.parse(rawString) as T;
      this.state = ConfigItemState.Json;
    } catch (err) {
    }
  }

  isJson(): boolean {
    return this.state === ConfigItemState.Json;
  }

  get json(): T {
    return this.parsedItem;
  }

  set json(object: T) {
    this.parsedItem = object;
  }
}
