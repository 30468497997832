import { Injectable } from "@angular/core";

@Injectable()
export class FreewallSearchService {
  private searchTerm: string;

  setSearch(text: string): void {
    this.searchTerm = text;
  }

  getSearch(): string {
    return this.searchTerm;
  }

}
