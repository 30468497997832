export enum CommonColumn {
   Bytes = "bytes",
   Ip = "ip",
   Method = "method",
   Host = "host",
   UriStem = "uri_stem",
   Status = "status",
   Referer = "referer",
   UserAgent = "user_agent",
   UriQuery = "uri_query",
   ProtocolVersion = "protocol_version"
}