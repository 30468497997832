import { Injectable } from '@angular/core'
import { NavigationEnd, Router, RouterEvent } from '@angular/router'
import { RedirectLoginResult } from '@auth0/auth0-spa-js'
import { filter, map, Observable } from 'rxjs'
import { AppState } from './AppState'
import { RouteEnteredEvent } from './RouteEnteredEvent'

@Injectable({
  providedIn: 'root'
})
export class AuthNav {
  readonly routeEntered$: Observable<string> =
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(({ url: path }) => path)
    )

  constructor(private router: Router) { }

  async redirectToRequestedRoute(
    result: RedirectLoginResult<AppState>
  ): Promise<boolean> {
    return this.router.navigateByUrl(`${result.appState?.requestedRoute}`)
  }

  isCallback(path: string): boolean {
    const query = new URL(path, location.origin).searchParams
    return query.has('state') && (query.has('code') || query.has('error'))
  }
}
