import { Component, OnInit } from "@angular/core";
import { Duration } from "@rezonence/duration";
import { TitleService } from "../core";

@Component({
  selector: 'demos',
  styles: [
    `
      .spacer {
        flex: 1 1 auto;
      }

      :host ::ng-deep .mat-body .mat-form-field-wrapper {
        font-size: 14px;
      }
    `
  ],
  template: `
    <mat-toolbar>
      <mat-toolbar-row>
        <mat-slide-toggle [(ngModel)]="externalOnly" class="mat-body">External only</mat-slide-toggle>
        <span class="spacer"></span>
        <mat-form-field class="mat-body">
          <input [(ngModel)]="search" placeholder="Search" type="text" matInput class="mat-body">
        </mat-form-field>
      </mat-toolbar-row>
    </mat-toolbar>
    <demos-table [dateRange]="dateRange" [externalOnly]="externalOnly" [filter]="search">
    </demos-table>
  `
})
export class DemosComponent implements OnInit {

  externalOnly = false;
  search: string = "";
  dateRange: [Date, Date];

  constructor(private titleService: TitleService) {
  }

  ngOnInit() {
    this.titleService.title = "Demos";
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - (30 * Duration.MsInDay))
    this.dateRange = [
      startDate,
      endDate
    ]
  }
}
