import { Injectable } from "@angular/core";
import { SchemaValidator, createAjv } from "@rezonence/schema-validator";

@Injectable({
  providedIn: "root"
})
export class ValidationService extends SchemaValidator {

  constructor() {
    super(createAjv({ strict: false }));
  }
}
