import { FreewallEventColumn } from "@rezonence/freewall-events";
import { TimestampColumn } from "./TimestampColumn";
import { EdgeLocationColumn } from "./EdgeLocationColumn";
import { UserAgentColumn } from "./UserAgentColumn";
import { FreewallEventRecord } from "./FreewallEventRecord";
import { LogSourceColumn } from "@rezonence/log-column-mappings";

// eslint-disable-next-line local-rules/multi-exports
export type FreewallTableColumn = keyof FreewallEventRecord;
// eslint-disable-next-line @typescript-eslint/naming-convention
export const FreewallTableColumn = {
  ...TimestampColumn,
  ...FreewallEventColumn,
  ...EdgeLocationColumn,
  ...UserAgentColumn,
  ...LogSourceColumn
}
