import { Injectable } from "@angular/core";
import { SchemaLocator } from "@rezonence/publisher-config-dao";
import { CampaignTemplateResolver } from "../../core";
import {CampaignFileService} from "../../core/campaign.file.service";
import {PublisherConfigMappingService} from "../../reports/services/publisher.config.mapping.service";
import {SubSitesAdapterService} from "../sub-sites/sub-sites-adapter.service";

@Injectable({
  providedIn: "root"
})
export class SchemaService extends SchemaLocator {

  constructor(campaignFileService: CampaignFileService,
              subSitesAdapterService: SubSitesAdapterService,
              mappingsDao: PublisherConfigMappingService,
              templateResolver: CampaignTemplateResolver) {
    super(campaignFileService, subSitesAdapterService, mappingsDao, templateResolver);
  }

}
