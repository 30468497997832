import {Pipe, PipeTransform} from "@angular/core";
import { JsonSchemaFormErrorMessage } from "../editors";

@Pipe({
  name: "formErrorFormatting"
})
export class FormErrorFormattingPipe implements PipeTransform {
  transform(errors: JsonSchemaFormErrorMessage[] = []): string[] {
    return errors.map(error => this.formatError(error));
  }

  formatError(error: JsonSchemaFormErrorMessage): string {
    return `${this.toField(error)}${error.message}`;
  }

  private removeInitialSlash(field: string) {
    return field.startsWith("/")
      ? field.substring(1)
      : field;
  }

  private titleCase(field: string): string {
    return `${field.charAt(0).toUpperCase()}${field.slice(1)}`;
  }

  private toField(error: JsonSchemaFormErrorMessage): string {
    const field = error.dataPath;
    return field.length > 0
      ? `${this.titleCase(this.removeInitialSlash(field))} `
      : "Form ";
  }
}
