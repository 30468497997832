import {PublisherConfigType} from "./publisher.config.type";
import { publisherConfigFile } from "./publisher.config.file";
import {CompileFolder, TemplateFolderResolver} from "@rezonence/core";
import {AnyFile, FileService, ScannedFile} from "@djabry/fs-s3";
import {CompileConfigRequest} from "@rezonence/core/freewall-compiler/compile.config.request";
import {PublisherConfigPlaceholder} from "@rezonence/core/publisher-config-compiler/publisher.config.placeholder";
import {SaveFileRequest} from "./save.file.request";
import {configFileNameByType} from "./config.file.name.by.type";
import {SubSitesList} from "./sub.sites.list";
import {Config} from "@rezonence/core/freewall-compiler/config";
import {Optional} from "@rezonence/sdk";
import {StringFromTemplating} from "@rezonence/sdk";
import {ConfigItem} from "./config.item";
import {SchemaLocator} from "./schema.locator";
import {SaveError} from "./save.error";
import {rootPublisherConfigId} from "./root.publisher.config.id";
import {ArrayUtils} from "@rezonence/array-utils";
import {SchemaValidator} from "@rezonence/schema-validator";
import {SaveConfigRequest} from "./save.config.request";
import {ConfigCompiler} from "@rezonence/core/freewall-compiler/config.compiler";
import {DefaultUtils} from "@rezonence/core/utils/default.utils";
import {PublisherConfigMappingDao} from "./publisher.config.mapping.dao";
import { SubSitesAdapter } from "./sub-sites-adapter";

export class PublisherConfigCompiler {

  concurrency = 10;

  templateFileNameByConfigType: Record<PublisherConfigType, Optional<string>> = {
    [PublisherConfigType.PublisherConfig]: Optional.of(publisherConfigFile.publisherConfigJs),
    [PublisherConfigType.Doubleserve]: Optional.of(publisherConfigFile.doubleserveJs),
    [PublisherConfigType.Css]: Optional.empty(),
    [PublisherConfigType.SubSites]: Optional.empty(),
    [PublisherConfigType.CustomCode]: Optional.empty()
  };

  affectedConfigTypes: Record<PublisherConfigType, PublisherConfigType[]> = {
    [PublisherConfigType.PublisherConfig]: [PublisherConfigType.PublisherConfig],
    [PublisherConfigType.Doubleserve]: [PublisherConfigType.Doubleserve],
    [PublisherConfigType.Css]: [PublisherConfigType.PublisherConfig],
    [PublisherConfigType.SubSites]: [PublisherConfigType.PublisherConfig, PublisherConfigType.Doubleserve],
    [PublisherConfigType.CustomCode]: [PublisherConfigType.PublisherConfig]
  };

  configStringProcessorByConfigType: Record<PublisherConfigType, (request: SaveConfigRequest) => string> = {
    [PublisherConfigType.PublisherConfig]: request => this.formatConfigItemJson(request.config.json),
    [PublisherConfigType.Doubleserve]: request => this.formatConfigItemJson(request.config.json),
    [PublisherConfigType.Css]: request => request.config.raw,
    [PublisherConfigType.SubSites]: request =>
      this.formatConfigItemJson((request.config.json as SubSitesList).subSites),
    [PublisherConfigType.CustomCode]: request => request.config.raw
  };

  placeholderByConfigType: Record<PublisherConfigType, Optional<string>> = {
    [PublisherConfigType.PublisherConfig]: Optional.of(StringFromTemplating.Config),
    [PublisherConfigType.Doubleserve]: Optional.of(PublisherConfigPlaceholder.DoubleserveConfig),
    [PublisherConfigType.Css]: Optional.empty(),
    [PublisherConfigType.SubSites]: Optional.empty(),
    [PublisherConfigType.CustomCode]: Optional.empty()
  };

  codeConfigTypes = [PublisherConfigType.CustomCode, PublisherConfigType.Css];

  constructor(private configCompiler: ConfigCompiler,
              private validator: SchemaValidator,
              private fileService: FileService,
              private schemaLocator: SchemaLocator,
              private utils: DefaultUtils,
              private subSitesAdapter: SubSitesAdapter,
              private mappingsDao: PublisherConfigMappingDao,
              private templateResolver: TemplateFolderResolver) {
  }

  formatConfigItemJson(input: any): string {
    return JSON.stringify(input, null, 4);
  }

  toTemplateFile(versionOptional: Optional<string>, fileName: string): Optional<AnyFile> {
    if (!versionOptional.exists) {
      return Optional.empty();
    }
    const templateFolder = this.templateResolver.resolve({version: versionOptional.item, compileFolder: CompileFolder.Pub});
    return Optional.of({
      ...templateFolder,
      key: `${templateFolder.key}/${fileName}`
    });
  }

  async toScannedTemplateFile(version: Optional<string>, fileName: string): Promise<ScannedFile> {
    const templateFile = this.toTemplateFile(version, fileName);
    return templateFile.exists ? this.fileService.isFile(templateFile.item) : undefined;
  }

  async findFirstFile(files: AnyFile[]): Promise<ScannedFile | undefined> {
    for (const file of files) {
      const scannedFile = await this.fileService.isFile(file);
      if (scannedFile) {
        return scannedFile;
      }
    }
  }

  async validateRequest(request: SaveConfigRequest) {
    if (request.schema) {
      this.validator.validate(request.config.json, request.schema);
    }

    if (!request.configId) {
      throw new Error(SaveError.NoConfigId);
    }

    if (!request.config) {
      throw new Error(SaveError.NoConfig);
    }

    if (!request.configType) {
      throw new Error(SaveError.NoConfigType);
    }

    if (request.configType === PublisherConfigType.SubSites) {
      const subSites = (request.config.json as SubSitesList).subSites;
      await Promise.all(subSites.map(async subSite => {
        const rootConfig = await this.mappingsDao.find({configId: subSite});
        if (rootConfig.exists && rootConfig.item.parentConfigId === rootPublisherConfigId) {
          throw new Error(`${SaveError.ExistingSubSite}: ${rootConfig.item.configId}`);
        }
      }));
    }
  }

  sanitizeJSON(jsonString: string): string {
    return jsonString.replace(/\\/g, "\\\\")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/\t/g, "\\t")
      .replace(/\f/g, "\\f")
      .replace(/"/g, "\\\"")
      .replace(/'/g, "\\\'")
      .replace(/\&/g, "\\&");
  }

  async saveFile(request: SaveFileRequest): Promise<void> {
    const destinationFolder = this.schemaLocator.toPublisherConfigFolder(request.configId);
    const destinationFile = {
      ...destinationFolder,
      key: `${destinationFolder.key}/${request.fileName}`
    };

    await this.fileService.write(request.fileContent, destinationFile, {
      overwrite: true,
      makePublic: true,
      skipSame: true
    });
  }

  async getSubSitesList(request: SaveConfigRequest): Promise<string[]> {
    if (request.configType === PublisherConfigType.SubSites) {
      const config = request.config.json as SubSitesList;
      return config.subSites;
    } else {
      const childMappings = await this.mappingsDao.listMappingsForConfig(request.configId);
      return childMappings.map(m => m.configId);
    }
  }

  async saveConfigFileForConfigId(request: SaveConfigRequest, configId: string): Promise<void> {
    const configString = this.configStringProcessorByConfigType[request.configType](request);
    const configFileName = configFileNameByType[request.configType];
    await this.saveFile({
      fileName: configFileName,
      configId,
      fileContent: configString
    });
  }

  async readCustomConfigFileStrings(request: SaveConfigRequest): Promise<Partial<Record<PublisherConfigType, string>>> {
    return (await Promise.all(this.codeConfigTypes
      .filter(configType => configType !== request.configType)
      .map(async configType => ({configType,
        configStringOptional: (await this.schemaLocator.readConfig(configType, request.configId))}))))
      .filter(configItem => configItem.configStringOptional.exists)
      .reduce((previous, configItem) => ({...previous,
        [configItem.configType]: configItem.configStringOptional.item.raw}), {});
  }

  async save(request: SaveConfigRequest): Promise<void> {
    await this.validateRequest(request);
    if (request.configType === PublisherConfigType.SubSites) {
      await this.subSitesAdapter.saveSubSites(request.configId, request.config.json as SubSitesList);
    } else {
      await this.saveConfigFileForConfigId(request, request.configId);
    }
    const configFolder = this.schemaLocator.toPublisherConfigFolder(request.configId);
    const customCodeFiles = await this.readCustomConfigFileStrings(request);
    const cssTemplateFileName = publisherConfigFile.publisherFormat;
    const affectedTypes = this.affectedConfigTypes[request.configType];
    const configs = (await Promise.all(affectedTypes.map(async configType => {
      const configFileName = configFileNameByType[configType];
      const configFile = await this.fileService.isFile({
        ...configFolder,
        key: `${configFolder.key}/${configFileName}`
      });
      const configFileString = configFile ? await this.fileService.readString(configFile) : undefined;
      return {
        configType,
        configFileString
      };
    })))
      .filter(c => !!c.configFileString)
      .map(c => ({
        configType: c.configType,
        configItem: new ConfigItem<Config>(c.configFileString)
      })).map(c => ({
        ...c,
        version: c.configItem.isJson() ?
          Optional.of<string>(c.configItem.json.version) : Optional.empty<string>()
      }));

    const publisherConfig = configs.find(c => c.configType === PublisherConfigType.PublisherConfig);
    const templates = (await Promise.all(configs.map(async config => {
      const templateFileName = this.templateFileNameByConfigType[config.configType];
      if (!templateFileName.exists) {
        return null;
      }
      const scannedFile = await this.toScannedTemplateFile(config.version, templateFileName.item);
      return {
        file: scannedFile,
        fileName: templateFileName.item
      };
    }))).filter(t => !!t.file);

    let configsToInsert = {};

    if (publisherConfig) {
      const cssTemplateFile = await this.findFirstFile([
        {
          ...configFolder,
          key: `${configFolder.key}/${cssTemplateFileName}`
        },
        this.toTemplateFile(publisherConfig.version, cssTemplateFileName).item
      ].filter(f => !!f));

      if (cssTemplateFile) {
        templates.push({
          file: cssTemplateFile,
          fileName: cssTemplateFileName
        });
        configsToInsert[StringFromTemplating.Css] = await this.fileService.readString(cssTemplateFile);
      }
    }

    const extensionFiles = await Promise.all(templates
      .map(t => this.configCompiler.getFileExtensionPointName(t.fileName))
      .map(extensionFileName => ({
      ...configFolder,
      key: `${configFolder.key}/${extensionFileName}`
    })).map(async file => this.fileService.isFile(file)));

    const templateFiles = [
      ...templates.map(t => t.file),
      ...extensionFiles
    ].filter(f => !!f);

    configsToInsert = configs.map(c => {
      const placeholder = this.placeholderByConfigType[c.configType];
      return {
        placeholder,
        ...c
      };
    }).filter(c => c.placeholder.exists).map(c => ({
      placeholder: c.placeholder.item,
      value: c.configItem.raw
    })).reduce((previous, current) => ({
      ...previous,
      [current.placeholder]: current.value
    }), configsToInsert);

    const subSites = await this.getSubSitesList(request);
    const uniqueSubSites = ArrayUtils.uniqueValues(subSites.concat([request.configId]));
    await this.utils.process(uniqueSubSites, async subSite => {
      console.log("Saving config to:", subSite);
      const destinationFolder = this.schemaLocator.toPublisherConfigFolder(subSite);
      if (templateFiles.length) {
        const compilationRequest: CompileConfigRequest = {
          templateFiles,
          destinationFolder,
          configsToInsert,
          resourceFolders: [],
          options: {
            debug: request.debug
          }
        };
        await this.configCompiler.compile(compilationRequest);
      }
      if (request.configType !== PublisherConfigType.SubSites) {
        await this.saveConfigFileForConfigId(request, subSite);
      }
      for (const customCodeType of Object.keys(customCodeFiles)) {
        console.log(`Saving ${configFileNameByType[customCodeType]} to ${subSite}`);
        await this.saveFile({
          fileName: configFileNameByType[customCodeType],
          configId: subSite,
          fileContent: customCodeFiles[customCodeType]
        });
      }

    }, this.concurrency);
  }
}
