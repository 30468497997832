import {AuthorisationRequest} from "./authorisation.request";
import DynamoDB from "aws-sdk/clients/dynamodb";
import {AuthorisationStatus} from "./authorisation.status";
import {AUTHORISATION_STATE} from "./authorisation.state";
import SES from "aws-sdk/clients/ses";
import {Utils} from "../utils";
import {AuthorisationToken} from "./authorisation.token";
import {ApproversItem} from "./approvers.item";
import {UserDetails} from "./user.details";
import {AuthorisationKey} from "./authorisation.key";
import {TableName} from "./table.name";
import {DynamoError} from "../dynamo/dynamo.error";

export class AuthorisationService {

    requestTable: string;
    statusTable: string;
    userDetailsTable: string;
    tokenTable: string;
    approversTable: string;
    emailCharset = "UTF-8";
    emailSource = "tech@rezonence.com";

    constructor(private dbPromise: Promise<DynamoDB.DocumentClient>,
                private sesPromise: Promise<SES>,
                private utils: Utils) {
        this.requestTable = `${TableName.AuthorisationRequests}`;
        this.userDetailsTable = `${TableName.UserDetails}`;
        this.statusTable = `${TableName.AuthorisationStatus}`;
        this.tokenTable = `${TableName.AuthorisationTokens}`;
        this.approversTable = `${TableName.Approvers}`;

    }

    getDB(): Promise<DynamoDB.DocumentClient> {
        return this.dbPromise;
    }

    toAuthRequest(identityId: string, action: string, resourceId: string): AuthorisationRequest {

        return {
            identityId,
            action,
            resourceId,
            requestCode: this.toRequestCode(action, resourceId)
        };
    }

    async setAuthorisationEmailAddresses(action: string, emails: string[]) {

        const db = await this.getDB();
        const item: ApproversItem = {
            action,
            emails
        };
        await db.put({
            TableName: this.approversTable,
            Item: item

        }).promise();

    }

    async updateDetails(identityId: string, email: string, profile: any) {

        const db = await this.getDB();
        const userDetails: UserDetails = {
            identityId,
            email,
            profile
        };
        await db.put({
            TableName: this.userDetailsTable,
            Item: userDetails
        }).promise();

    }

    async getRequesterEmail(identityId: string): Promise<string> {

        const db = await this.getDB();
        const data = await db.get({
            TableName: this.userDetailsTable,
            Key: {
                identityId
            }
        }).promise();

        const userDetails = data.Item as UserDetails;

        if (userDetails) {
            return userDetails.email;

        } else {

            return Promise.reject("Details not found");
        }

    }

    async sendResultEmail(identityId: string, subject: string, emailBody: string, htmlEmailBody: string): Promise<any> {

        const userEmail = await this.getRequesterEmail(identityId);
        const ses = await this.sesPromise;

        console.log("Sending result email to requester:", userEmail);
        await ses.sendEmail({
            Source: this.emailSource,
            Destination: {
                ToAddresses: [userEmail]
            },
            Message: {
                Body: {
                    Html: {
                        Charset: this.emailCharset,
                        Data: this.processEmailText(htmlEmailBody, userEmail)
                    },
                    Text: {
                        Charset: this.emailCharset,
                        Data: this.processEmailText(emailBody, userEmail)
                    }
                },
                Subject: {
                    Charset: this.emailCharset,
                    Data: this.processEmailText(subject, userEmail)
                }
            }
        }).promise();

    }

    replacePlaceholder(input: string, placeholder: string, replaceWith: string): string {
        const replacer = new RegExp(`{{${placeholder}}}`, "g");
        return input.replace(replacer, replaceWith);
    }

    processEmailText(input: string, recipient: string): string {
        return this.replacePlaceholder(input, "recipient", recipient);
    }

    async sendRequestEmail(email: string, subject: string, emailBody: string, htmlEmailBody: string): Promise<any> {

        //  let emails = await this.getAuthorisationEmailAddresses(item.action);

        const ses = await this.sesPromise;

        await ses.sendEmail({
            Source: this.emailSource,
            Destination: {
                ToAddresses: [email]
            },
            Message: {
                Body: {
                    Html: {
                        Charset: this.emailCharset,
                        Data: this.processEmailText(htmlEmailBody, email)
                    },
                    Text: {
                        Charset: this.emailCharset,
                        Data: this.processEmailText(emailBody, email)
                    }
                },
                Subject: {
                    Charset: this.emailCharset,
                    Data: this.processEmailText(subject, email)
                }
            }

        }).promise();

    }

    async updateRequestStatus(key: AuthorisationKey, newStatus: string, responder: string) {
        const db = await this.getDB();
        const currentDate = (new Date()).getTime();

        try {

            await db.update({
                TableName: this.statusTable,
                Key: {
                    identityId: key.identityId,
                    requestCode: key.requestCode
                },
                UpdateExpression: "set #state = :state, #lastUpdated = :lastUpdated, #responder = :responder",
                ExpressionAttributeNames: {
                    "#responder": "responder",
                    "#state": "state",
                    "#lastUpdated": "lastUpdated"
                },
                ConditionExpression: "#state = :submitted",
                ExpressionAttributeValues: {
                    ":submitted": AUTHORISATION_STATE.submitted,
                    ":state": newStatus,
                    ":lastUpdated": currentDate,
                    ":responder": responder
                }
            }).promise();

        } catch (err) {

            if (err && err.code === DynamoError.ConditionalCheckFailedException) {

                throw new Error("The response has already been submitted");

            } else {

                throw err;
            }
        }

    }

    async getToken(tokenId: string): Promise<AuthorisationToken> {
        const tokenKey = {
            tokenId
        };
        const db = await this.getDB();
        const data = await db.get({
            TableName: this.tokenTable,
            Key: tokenKey
        }).promise();

        return data.Item as AuthorisationToken;

    }

    async deleteAllTokens(authKey: AuthorisationKey): Promise<any> {

        const db = await this.getDB();

        const query = {
            ExclusiveStartKey: null,
            TableName: this.tokenTable,
            IndexName: "identityId-requestCode-index",
            KeyConditionExpression: "#identityId = :identityId and #requestCode = :requestCode",
            ExpressionAttributeNames: {
                "#identityId": "identityId",
                "#requestCode": "requestCode"
            },
            ExpressionAttributeValues: {
                ":identityId": authKey.identityId,
                ":requestCode": authKey.requestCode
            }
        };

        let data = await db.query(query).promise();

        const deleteItem = async (item: Partial<AuthorisationToken>) => {

            await db.delete({
                TableName: this.tokenTable,
                Key: {
                    tokenId: item.tokenId
                }
            }).promise();
        };

        for (const item of data.Items) {
            await deleteItem(item);
        }

        while (data.LastEvaluatedKey) {
            query.ExclusiveStartKey = data.LastEvaluatedKey;
            data = await db.query(query).promise();
            for (const item of data.Items) {
                await deleteItem(item);
            }

        }

    }

    async deleteToken(tokenId: string) {
        const tokenKey = {
            tokenId
        };
        const db = await this.getDB();

        await db.delete({
            TableName: this.tokenTable,
            Key: tokenKey
        }).promise();

    }

    async deleteAuthorisationRequest(authKey: AuthorisationKey): Promise<any> {

        const db = await this.getDB();
        const key: AuthorisationKey = {
            identityId: authKey.identityId,
            requestCode: authKey.requestCode
        };

        await db.delete({
            TableName: this.requestTable,
            Key: key
        }).promise();

    }

    async respondToAuthorisationRequest(tokenId: string, accept: boolean): Promise<AuthorisationKey> {

        const authorisationToken = await this.getToken(tokenId);

        if (authorisationToken) {

            const state = accept ? AUTHORISATION_STATE.approved : AUTHORISATION_STATE.rejected;

            await this.updateRequestStatus(authorisationToken, state, authorisationToken.email);

            return authorisationToken;

        } else {

            throw new Error("Invalid token");
        }

    }

    async getAuthorisationEmailAddresses(action: string): Promise<string[]> {

        const db = await this.getDB();

        const data = await db.get({
            TableName: this.approversTable,
            Key: {
                action
            }
        }).promise();

        const item = data.Item;

        return item.emails;

    }

    toRequestCode(action: string, resourceId: string): string {
        return `${resourceId}:${action}`;
    }

    async isPermitted(requestKey: AuthorisationKey): Promise<boolean> {

        const db = await this.getDB();

        const data = await db.get({
            TableName: this.statusTable,
            Key: {
                identityId: requestKey.identityId,
                requestCode: requestKey.requestCode
            }
        }).promise();

        if (data.Item) {

            return data.Item.state === AUTHORISATION_STATE.approved;

        } else {

            return false;
        }

    }

    async createAuthorisationToken(key: AuthorisationKey, email: string): Promise<AuthorisationToken> {

        const db = await this.getDB();

        const authToken: AuthorisationToken = {
            identityId: key.identityId,
            requestCode: key.requestCode,
            tokenId: this.utils.guid(),
            email
        };

        await db.put({
            TableName: this.tokenTable,
            Item: authToken
        }).promise();

        return authToken;
    }

    async getRequestItem(key: AuthorisationKey): Promise<AuthorisationRequest> {

        const db = await this.getDB();

        const data = await db.get({
            TableName: this.requestTable,
            Key: {
                identityId: key.identityId,
                requestCode: key.requestCode
            }
        }).promise();

        return data.Item as AuthorisationRequest;

    }

    async getStatusItem(key: AuthorisationKey): Promise<AuthorisationStatus> {

        const db = await this.getDB();

        // Check if the status item exists already
        const data = await db.get({
            TableName: this.statusTable,
            Key: {
                identityId: key.identityId,
                requestCode: key.requestCode
            }
        }).promise();

        const statusItem = data.Item as AuthorisationStatus;

        return statusItem;
    }

    async createStatusItem(key: AuthorisationKey): Promise<AuthorisationStatus> {

        let statusItem = await this.getStatusItem(key);

        // If the request has already been rejected then start the request process again
        if (statusItem && statusItem.state === AUTHORISATION_STATE.rejected) {
            statusItem = null;
        }

        if (!statusItem) {

            const currentDate = (new Date()).getTime();
            statusItem = {
                requestDate: currentDate,
                requestCode: key.requestCode,
                identityId: key.identityId,
                state: AUTHORISATION_STATE.submitted,
                lastUpdated: currentDate
            };

            const db = await this.getDB();

            await db.put({
                TableName: this.statusTable,
                Item: statusItem
            }).promise();
        }

        return statusItem;

    }

    async requestAuthorisation(request: AuthorisationRequest): Promise<AuthorisationRequest> {

        // Check if the request has been authorised already
        const db = await this.getDB();

        request.requestCode = request.requestCode || `${request.resourceId}:${request.action}`;

        const data = await db.put({
            Item: request,
            TableName: this.requestTable
        }).promise();

        return request;

    }

}
