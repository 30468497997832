import {BackgroundRefreshCache} from "../../cache/background.refresh.cache";
import {Injectable, NgZone} from "@angular/core";

@Injectable()
export class ConfigListCache extends BackgroundRefreshCache {
  constructor(zone: NgZone) {
    super({
      version: 2,
      dbName: "configListCache",
      objectStoreName: "configList"
    }, zone);
  }
}
