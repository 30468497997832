import { Component, OnInit } from "@angular/core";

import {TitleService} from "../../core";
import { ConfigType } from "@rezonence/core";
import { toFreeWallEditorUrl } from "../toFreeWallEditorUrl";

@Component({
  selector: "app-freewall-list",
  templateUrl: "./freewall-list.component.html",
  styleUrls: ["./freewall-list.component.css"]
})
export class FreewallListComponent implements OnInit {
  configType = ConfigType.AdId;

  constructor(public titleService: TitleService) {
  }

  ngOnInit() {
    this.titleService.title = "FreeWalls";
  }

  onEdit(adId: string) {
    location.href = toFreeWallEditorUrl(adId)
  }
}
