import { NgModule } from "@angular/core";
import { Auth0Module } from "@rezonence/ng-auth0";
import { environment } from "../../environments/environment";
import { AuthService } from "./AuthService";

@NgModule({
  imports: [
    Auth0Module.forRoot({
      auth0: {
        authorizationParams: {
          scope: 'openid email',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          redirect_uri: location.origin
        },
        clientId: environment.auth0Key,
        domain: environment.auth0RezonenceDomain,
        useCookiesForTransactions: true,
        authorizeTimeoutInSeconds: 300,
        httpTimeoutInSeconds: 120,
        cacheLocation: 'localstorage',
      }
    }),
  ],
  providers: [
    AuthService
  ]
})
export class AuthModule { }
