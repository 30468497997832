import {AbstractKeyValueStore, KvStorageEvent, KeyValueStore, KeyValue} from "@rezonence/dao";
import {Optional} from "@rezonence/sdk";
import {KeyValueParser} from "./key.value.parser";

/**
 * Stores DIT records as strings
 */
export class BackedDao<K, V> extends AbstractKeyValueStore<K, V> {

    constructor(private backingStore: KeyValueStore<string, string>,
                private parsers: KeyValueParser<K, V>) {
        super();
    }

    async read(key: K): Promise<Optional<V>> {
        const stringKey = this.parsers.key.encode(key);
        const optional = await this.backingStore.read(stringKey);
        return optional.map(v => this.parsers.value.parse(v));
    }

    async* list(): AsyncIterable<K> {
        for await (const key of this.backingStore.list()) {
            const parsedKey = this.parsers.key.parse(key);
            if (parsedKey.exists) {
                yield parsedKey.item;
            }
        }
    }

    async writeValue(request: KeyValue<K, V>): Promise<void> {
        const key = this.parsers.key.encode(request.key);
        const value = this.parsers.value.encode(request.value);
        await this.backingStore.write({key, value});
    }

    async* listenToChanges(): AsyncIterable<KvStorageEvent<K, V>> {
        for await (const entry of this.backingStore.listenToChanges()) {
            const parsedKey = this.parsers.key.parse(entry.key);
            if (parsedKey.exists) {
                yield {
                    key: parsedKey.item,
                    newValue: entry.newValue.map(v => this.parsers.value.parse(v)),
                    oldValue: entry.oldValue.map(v => this.parsers.value.parse(v))
                };
            }
        }
    }

}
