import {InMemoryKeyValueStore} from "./in.memory.key.value.store";
import {Optional} from "@rezonence/sdk";
import {Repeater} from "@repeaterjs/repeater";
import {KeyValue, KvStorageEvent} from "@rezonence/dao";
import {CustomStorageEvent} from "./custom.storage.event";
import {DitStorageEvent} from "./dit.storage.event";
import {DitStorageEventType} from "./dit.storage.event.type";

export class LocalStorageKeyValueStore extends InMemoryKeyValueStore<string> {

    constructor() {
        super(localStorage);
    }

    listenToChanges(): AsyncIterable<KvStorageEvent<string, string>> {
        return new Repeater(async (push, stop) => {
            const listener = (event: DitStorageEvent) => {
                if (event.detail.store === this.store) {
                    const rawEvent = event.detail.storageEvent;
                    const storageEvent = this.toStorageEvent(Optional.of(rawEvent.oldValue), {
                        key: rawEvent.key,
                        value: rawEvent.newValue
                    })
                    push(storageEvent);
                }
            };
            window.addEventListener(DitStorageEventType as any, listener);
            await stop;
            window.removeEventListener(DitStorageEventType as any, listener);
        });
    }

    async writeValue(item: KeyValue<string, string>): Promise<void> {
        const oldValue = localStorage.getItem(item.key);
        localStorage.setItem(item.key, item.value);
        const detail: CustomStorageEvent = {
            store: this.store,
            storageEvent: {
                oldValue,
                newValue: item.value,
                key: item.key
            }
        };
        const event = new CustomEvent(DitStorageEventType, {detail});
        window.dispatchEvent(event);
    }


}
