import {Injectable} from "@angular/core";
import {AWSService} from "../../core";
import {tableName} from "@rezonence/publisher-config-mapper";
import {PublisherConfigMappingDao} from "@rezonence/publisher-config-dao";

@Injectable()
export class PublisherConfigMappingService extends PublisherConfigMappingDao {
  constructor(awsService: AWSService) {
    super(awsService.dynamodb(), tableName);
  }
}
