<div *ngIf="report$ | async as report; else loading">
  <p><strong>Live report: </strong>
    <a href="{{reportLink}}" target="_blank">{{reportLink}}</a></p>
  <ng-container *ngIf="!(report.reportType === ReportType.article)">
    <p><strong>Live report with impressions: </strong>
      <a href="{{impressionsLink}}" target="_blank">{{impressionsLink}}</a></p>
    <p><strong>Trackers report: </strong>
      <a href="{{trackersLink}}" target="_blank">{{trackersLink}}</a></p>
    <p><strong>EOC report: </strong>
      <a href="{{eocLink}}" target="_blank">{{eocLink}}</a></p>
  </ng-container>
  <div class="report-detail">
    <form (ngSubmit)="saveReport(report)" #reportForm="ngForm" fxLayout="column" fxLayoutGap="10px">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput
               [disabled]="!(canWrite$ | async)"
               [(ngModel)]="report.title"
               name="title" required>
      </mat-form-field>
      <mat-form-field *ngIf="isReportTypeSelectable">
        <mat-label>Report type</mat-label>
        <mat-select
          [(ngModel)]="report.reportType"
          [disabled]="!(canWrite$ | async)"
          (selectionChange)="report.displayECPM = $event.value === ReportType.publisher"
          name="reportType">
          <mat-option *ngFor="let reportType of Object.values(ReportType)" [value]="reportType">
            {{reportType | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="!(report.reportType === ReportType.article)">
        <mat-form-field>
          <mat-label *ngIf="report.displayECPM; else agencyLabel">Publisher cost per engagement</mat-label>
          <ng-template #agencyLabel><mat-label>Agency cost per engagement</mat-label></ng-template>
          <input matInput
                 type="number"
                 step="0.01"
                 [(ngModel)]="report.cpe"
                 [disabled]="!(canWrite$ | async)"
                 name="cpe">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Currency</mat-label>
          <mat-select [(ngModel)]="report.cpeCurrency"
                      [disabled]="!(canWrite$ | async)"
                      name="cpeCurrency">
            <mat-option *ngFor="let currency of Object.values(Currency)" [value]="currency">
              {{currency}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Internal reference</mat-label>
          <input matInput
                 type="text"
                 [(ngModel)]="report.internalReference"
                 [disabled]="!(canWrite$ | async)"
                 name="internalReference">
        </mat-form-field>
        <mat-form-field>
          <mat-label>External reference</mat-label>
          <input matInput
                 type="text"
                 [(ngModel)]="report.externalReference"
                 name="externalReference"
                 [disabled]="!(canWrite$ | async)">
        </mat-form-field>
        <app-report-datepicker *ngFor="let dateField of Object.values(DateField)"
                               [disabled]="!(canWrite$ | async)"
                               [dateField]="dateField"
                               [(date)]="report[dateField]">
        </app-report-datepicker>
        <app-config-multiselector *ngFor="let configType of [ConfigType.AdId, ConfigType.ConfigId, ConfigType.NetworkId]"
                                  [disabled]="!(canWrite$ | async)"
                                  [(selected)]="report[configType + 's']"
                                  [configType]="configType">
        </app-config-multiselector>
        <mat-form-field>
          <mat-label>Engagements</mat-label>
          <input matInput
                 type="number"
                 [(ngModel)]="report.targetEngagements"
                 [disabled]="!(canWrite$ | async)"
                 name="engagements"
                 required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Adjustment</mat-label>
          <input matInput
                 type="number"
                 [(ngModel)]="report.adjustment"
                 [disabled]="!(canWrite$ | async)"
                 name="adjustment">
        </mat-form-field>
        <div *ngFor="let checkboxField of checkboxFields">
          <mat-checkbox [(ngModel)]="report[checkboxField]"
                        color="primary"
                        [name]=checkboxField
                        [disabled]="!(canWrite$ | async)">
            Display {{checkboxField | decamelise | removeDisplayPrefix }}
          </mat-checkbox>
        </div>
      </ng-container>
      <div>
        <button type="submit"
                mat-button color="primary"
                [disabled]="!reportForm.form.valid || !(canWrite$ | async)">Save</button>
        <button class="delete-button"
                mat-button color="warn"
                [disabled]="!(canWrite$ | async)"
                (click)="deleteReport(report)">Delete</button>
      </div>
    </form>
  </div>
</div>
<ng-template #loading>
  <spinner [isRunning]="true"></spinner>
</ng-template>
