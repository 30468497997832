import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfigIdValidatorService} from "../config-id-validator.service";
import {SchemaService} from "../services/schema.service";
import {PublisherConfigType, EditableType, ConfigItem} from "@rezonence/publisher-config-dao";
import {FreeWallProgressComponent} from "../freewall-progress/freewall-progress.component";
import {SaveService} from "../services/save.service";
import {rootPublisherConfigId} from "@rezonence/publisher-config-dao";
import {PublisherConfigMappingService} from "../../reports/services/publisher.config.mapping.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-duplicate-wizard",
  templateUrl: "./duplicate-wizard.component.html",
  styleUrls: ["./duplicate-wizard.component.css"]
})
export class DuplicateWizardComponent implements OnInit {
  configIdFormGroup: FormGroup;
  configIdInput: string;
  isLinear: boolean;

  constructor(private formBuilder: FormBuilder,
              private configIdValidator: ConfigIdValidatorService,
              private schemaService: SchemaService,
              private saveService: SaveService,
              private mappingService: PublisherConfigMappingService,
              private snackbar: MatSnackBar,
              public dialog: MatDialog,
              public ref: MatDialogRef<DuplicateWizardComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {configId: string}) {
  }

  ngOnInit() {
    this.configIdFormGroup = this.formBuilder.group({
      configIdInput: ["", [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern("[^ |<>#%\"]+")
      ],
        this.configIdValidator.validateConfigId.bind(this.configIdValidator)]
    });
  }

  async save(): Promise<void> {
    const destinationId = this.configIdFormGroup.value.configIdInput;
    const saveMessage = `Copying ${this.data.configId} to ${destinationId}`;
    const progressDialog = this.dialog.open(FreeWallProgressComponent, {disableClose: true});
    progressDialog.componentInstance.message = saveMessage;
    try {
      await this.mappingService.save([{
        configId: destinationId,
        parentConfigId: rootPublisherConfigId,
        lastModified: new Date().toISOString()
      }]);
      const saveRequests = await Promise.all(Object.values(PublisherConfigType)
        .filter(configType => configType !== PublisherConfigType.SubSites)
        .map(async configType => ({
          configType,
          configId: destinationId,
          config: (await this.schemaService.getConfig(configType, this.data.configId)).item as ConfigItem<EditableType>,
          debug: false
        })));
      for (const request of saveRequests) {
        await this.saveService.save(request);
      }
      progressDialog.close();
      await progressDialog.afterClosed();
      this.snackbar.open(`Copied ${this.data.configId} to ${destinationId}`, "OK");
      this.ref.close(true);
    } catch (err) {
      console.error(err);
      progressDialog.close();
      await progressDialog.afterClosed();
      this.snackbar.open(`Failed to copy ${this.data.configId}: ${err.message}`, "OK");
      this.ref.close(false);
    }
  }

}
