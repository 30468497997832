<div>
  <accordion-group [class.publisher-item]="report.reportType === 'publisher'"
                   [class.agency-item]="report.reportType === 'agency'"
                   [class.article-item]="report.reportType === 'article'">
    <div accordion-heading class="report-heading">
      <div>
        <div class="row" [style.display]="'flex'">
          <i title="Expand report" class="glyphicon"
             [class.glyphicon-chevron-down]="showDetail()"
             [class.glyphicon-chevron-right]="!showDetail()"
             [style.margin-right]="'5px'"></i>
          <a (click)="openReportLink($event)" href={{reportLink}} target="_blank"
             [style.flex]="'1'">{{report.title}}</a>
          <div title="Progress" *ngIf="report.reportType !== 'article' && report.endDate > reportService.lastWeek"
               class="col-md-1">
            <progress-bars [reportId]="report.id"></progress-bars>
          </div>
          <div *ngIf="reportService.canWrite$ | async" class="btn-group btn-group-xs" role="group">
            <!-- <button type="button" class="btn btn-primary btn-xs" (click)="edit($event)">
               <span class="glyphicon glyphicon-pencil"></span>
             </button>-->
            <!-- <button type="button" class="btn btn-primary btn-xs dropdown-toggle" data-toggle="dropdown">
               <span class="caret"></span>
             </button>
             <ul class="dropdown-menu" role="menu">
               <li><a href="#">Tablet</a></li>
               <li><a href="#">Smartphone</a></li>
             </ul>-->
            <button mat-icon-button color="primary" title="Duplicate" type="button" class="btn" (click)="duplicate($event)">
              <!--<span class="glyphicon glyphicon-duplicate"></span>-->
              <mat-icon>file_copy</mat-icon>
            </button>
            <button mat-icon-button color="warn" title="Delete" type="button" class="btn" (click)="deleteReport($event)">
              <!--<span class="glyphicon glyphicon-remove"></span>-->
              <mat-icon>close</mat-icon>
            </button>

          </div>

        </div>

      </div>
    </div>
    <div *ngIf="showDetail()">
      <report-detail [isReportTypeSelectable]="false" [report]="report" (reportDeleted)="onReportDeleted()">
      </report-detail>
    </div>
  </accordion-group>

</div>

