import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { JsonCodeEditorComponent } from "./json-code-editor";
import { JsonFormEditorComponent } from "./json-form-editor";
import {FormsModule} from "@angular/forms";
import {MaterialDesignFrameworkModule} from "@ajsf/material";
import {EditorComponent} from "./editor/editor.component";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {CodeEditorModule} from "@rezonence/code-editor";

@NgModule({
  declarations: [
    JsonCodeEditorComponent,
    JsonFormEditorComponent,
    EditorComponent,
  ],
  imports: [
    CodeEditorModule,
    CommonModule,
    FormsModule,
    MaterialDesignFrameworkModule,
    MatButtonToggleModule
  ],
  exports: [
    EditorComponent
  ]
})
export class EditorsModule {
}
