import { TagService } from './tag.service'
import { GenerateTagRequest } from './generate.tag.request'
import { parseFragment } from 'parse5'
import { parseScript as parseCode } from 'esprima-next'
import { CompileFolder, TemplateFolderResolver } from '../freewall-templates'
import { TagType } from './tag.type'
import { AD_SERVER_MACRO_MAP } from './ad.server.macro.map'
import { PLACEHOLDER_VALUES } from './placeholder.values'
import { Placeholder } from './placeholder'
import { ConfigResolver, DestinationPrefix, FreeWallImage, Optional, SingleTrackerType, crossFetch } from '@rezonence/sdk'
import { CDN, FreeWallConfig } from '../config-extractor'
import { customTrackersVar } from './customTrackersVar'

export class DefaultTagService implements TagService {
  constructor (private configResolver: ConfigResolver, private templateResolver: TemplateFolderResolver, private cdn: CDN = CDN.S3) {
  }

  /**
   * Finds the template file for the tag type and branch
   * @param {TagType} tagType
   * @param {string} branch
   * @return {URL}
   */
  resolveTagUrl(tagType: TagType, version: string): URL {
    const fileName = tagType === TagType.Demo ? 'DemoTag' : `Sec${tagType}Tag`
    const url = this.templateResolver.toUrl({ version, compileFolder: CompileFolder.Tags }, this.cdn)
    url.pathname = `${url.pathname}/${fileName}.txt`
    return url
  }

  /**
   * Find the first script node containing javascript source in a hierarchy of nodes generated by parse5
   * @param node
   * @return {any}
   */
  findFirstScriptElement (node) {
    if (node && node.childNodes) {
      // Have to iterate in this stupid way since childNodes is not a proper array (FP)
      for (const childNode of node.childNodes) {
        if (childNode.tagName === 'script' && childNode.childNodes.length) {
          return childNode
        } else {
          const scriptNode = this.findFirstScriptElement(childNode)
          if (scriptNode) {
            return scriptNode
          }
        }
      }
    }
  }

  /**
  * Extracts the text contents of the first script element of an HTML string
  * @param {string} html - The html string
  * @return {string} - The first javascript code string
  */
  extractScriptFromHTML (html: string): string {
    const tagStructure = parseFragment(html)

    const scriptNode = this.findFirstScriptElement(tagStructure)

    if (scriptNode) {
      return scriptNode.childNodes[0].value
    } else {
      throw new Error('No script elements found in HTML')
    }
  }

  /**
   * Check if there are any syntax errors in the tag code due to the horrible hacks to insert params into the tag.
   * Throws an error if the syntax is invalid
   * @param {string} javascriptString
  */
  parseJavascript (javascriptString: string) {
    parseCode(javascriptString)
  }

  renderTagString (request: { tagString: string, placeholder: Placeholder, value: string }): string {
    return PLACEHOLDER_VALUES[request.placeholder].reduce((tagString, macroValue) => tagString.replace(macroValue, request.value), request.tagString)
  }

  toOptional (value: string | number | undefined): Optional<string> {
    return Optional.of(value).map(v => `${v}`)
  }

  async toFreeWallConfig (adId: string): Promise<Optional<FreeWallConfig>> {
    return this.configResolver.resolveFromId<FreeWallConfig>({
      cdn: new URL(this.cdn),
      id: adId,
      prefix: DestinationPrefix.Ads
    })
  }

  async toOptionalBannerImage (adId: string | undefined): Promise<Optional<FreeWallImage>> {
    const configOptional = await Optional.switchPromise(Optional.of(adId).map(adId => this.toFreeWallConfig(adId)))
    return Optional.unWrap(configOptional).map(config => config.image as FreeWallImage)
  }

  async toPlaceholderValues (request: GenerateTagRequest): Promise<Record<Placeholder, Optional<string>>> {
    const bannerImageOptional = await this.toOptionalBannerImage(request.adId)
    const engagementMacroOptional = Optional.of(request.adServerType).map(adServerType => AD_SERVER_MACRO_MAP.Engagement[adServerType])
    return {
      [Placeholder.AdId]: this.toOptional(request.adId),
      [Placeholder.DealId]: this.toOptional(request.dealId),
      [Placeholder.NexusPlacementId]: this.toOptional(request.nexusPlacementId),
      [Placeholder.VastTagUrl]: this.toOptional(request.vastTagUrl),
      [Placeholder.CampaignId]: this.toOptional(request.campaignId),
      [Placeholder.LineItemId]: this.toOptional(request.lineItemId),
      [Placeholder.NetworkId]: this.toOptional(request.networkId),
      [Placeholder.ConfigId]: this.toOptional(request.configId),
      [Placeholder.ImageLink]: bannerImageOptional.map(image => image.lnk as string),
      [Placeholder.ImageSource]: bannerImageOptional.map(image => image.img as string),
      [Placeholder.EngagementMacro]: engagementMacroOptional
    }
  }

  renderTrackers (tagString: string, trackers: Partial<Record<SingleTrackerType, string[]>>): string {
    return tagString.split(`window.${customTrackersVar}`).join(JSON.stringify(trackers));
  }

  renderPlaceholders (tagString: string, placeholderValues: Record<Placeholder, Optional<string>>): string {
    return Object.entries(placeholderValues)
      .filter(([_, optionalValue]) => optionalValue.exists)
      .map(([placeholder, optionalValue]) => [placeholder, optionalValue.item])
      .reduce((tag, [placeholder, value]) => this.renderTagString({ tagString: tag, placeholder: placeholder as Placeholder, value: value as string }), tagString)
  }

  /**
  * Generates a tag string for inserting a FreeWall into a publisher page
  * @param {GenerateTagRequest} request
  * @return {Promise<string>}
  */
  async generateTagString (request: GenerateTagRequest): Promise<string> {
    if (request.tagType === TagType.Live && !request.campaignId) {
      throw new Error('Live tags require a campaign ID to be specified')
    }
    const tagUrl = this.resolveTagUrl(request.tagType, request.branch)
    const response = await crossFetch(tagUrl.toString())
    const rawTagString = await response.text()
    const placeholderValues = await this.toPlaceholderValues(request)
    const customTrackers = request.trackers || {};
    const tagStringWithTrackers = this.renderTrackers(rawTagString, customTrackers);
    const tagString = this.renderPlaceholders(tagStringWithTrackers, placeholderValues)
    const javascriptString = this.extractScriptFromHTML(tagString)
    this.parseJavascript(javascriptString)
    return tagString
  }
}
