import {Component, Input, OnInit} from "@angular/core";
import { FreeWallConfig, Optional } from "@rezonence/sdk";
import { ConfigResolverService } from "../../core";
import { CDN } from "@rezonence/core";

@Component({
  selector: "app-dit-keys",
  templateUrl: "./dit-keys.component.html",
  styleUrls: ["./dit-keys.component.css"]
})
export class DitKeysComponent {

  @Input()
  get adId(): string {return this._adId; }
  set adId(adId: string) {
    this._adId = adId;
    this.freeWallConfigPromise = this.getConfig(adId);
  }

  freeWallConfigPromise: Promise<Optional<FreeWallConfig>>;

  private _adId: string;

  constructor(private configResolver: ConfigResolverService) {
  }

  async getConfig(adId: string = this.adId): Promise<Optional<FreeWallConfig>> {
    return this.configResolver.resolveFreeWallConfig({ adId, cdn: new URL(CDN.S3) })
  }

}
