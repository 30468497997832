import { Component, Input, Output } from "@angular/core";
import { Schema } from "jsonschema";
import Ajv from "ajv";
import { Language } from "@rezonence/code-editor";
import { ReplaySubject, combineLatest, Observable } from "rxjs";
import { map, filter, distinctUntilChanged } from "rxjs/operators";
import { JsonSchemaFormErrorMessage } from "../JsonSchemaFormErrorMessage";
import { ValidationService } from "../../core/validation.service";

@Component({
  selector: "app-json-code-editor",
  templateUrl: "./json-code-editor.component.html",
  styleUrls: ["./json-code-editor.component.css"]
})
export class JsonCodeEditorComponent {
  Language = Language;
  input = new ReplaySubject<string>(1);
  editorValue = this.input.pipe(distinctUntilChanged());
  isValidJson = new ReplaySubject<boolean>(1);
  schema$ = new ReplaySubject<Schema>(1);
  private readonly validator: Ajv;

  constructor(public schemaValidator: ValidationService) {
    this.validator = schemaValidator.ajv;
  }

  @Input()
  set data(d: any) {
    this.input.next(JSON.stringify(d, null, 4));
  };

  @Input()
  set schema(s: Schema) {
    this.schema$.next(s);
  }

  @Output()
  dataChange: Observable<any> = this.editorValue.pipe(
    filter(val => this.isParsable(val)),
    map(val => JSON.parse(val)),
  );

  isValidAgainstSchema = combineLatest([this.dataChange, this.schema$]).pipe(
    map(([json, schema]) => this.validator.validate(schema, json))
  );

  @Output()
  validationErrors: Observable<JsonSchemaFormErrorMessage[]> = this.isValidAgainstSchema.pipe(
    map(() => (this.validator.errors || []).map(e => ({
      message: e.message,
      dataPath: e.instancePath
    })))
  );

  @Output()
  isValid: Observable<boolean> = combineLatest([
    this.isValidJson,
    this.isValidAgainstSchema
  ]).pipe(map(([j, s]) => j && s));

  private isParsable(editorValue: string): boolean {
    try {
      JSON.parse(editorValue);
    } catch (e) {
      return false;
    }
    return true;
  }

}
