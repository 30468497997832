
/**
 * Escape a plain string for regex usage as-is
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 * @param input
 */
export function escapeRegExp(input: string): string {
  // $& means the whole matched string
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
