import {Component, Input} from "@angular/core";
import {CampaignAuthorisationService} from "../../core/campaign.authorisation.service";
import {AWSService} from "../../core/aws.service";
import {AUTHORISATION_ACTION} from "@rezonence/core";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "freewalls-request-deploy",
  templateUrl: "request-deploy.component.html",
  styleUrls: [
    "request-deploy.component.css"
  ]
})
export class RequestDeployComponent {

  @Input()
  public adId: string;

  constructor(private awsService: AWSService,
              private authorisationService: CampaignAuthorisationService,
              public ref: MatDialogRef<RequestDeployComponent>,
              private snackbar: MatSnackBar) {

  }

  async requestAuthorisation() {

    const identityId = await this.awsService.identityId();
    const action = AUTHORISATION_ACTION.DeployWithoutUnlockTrackers.key;
    const requestCode = this.authorisationService.toRequestCode(action, this.adId);
    await this.authorisationService.requestAuthorisation({
      identityId,
      resourceId: this.adId,
      action,
      requestCode
    });

    this.snackbar.open("Authorisation for deploying FreeWall has been requested", "OK");
    this.ref.close();

  }

  cancel() {
    this.ref.close();
  }

}
