import {Component, EventEmitter, Input, Output} from "@angular/core";
import {DeleteConfigDialogComponent} from "../delete-config-dialog/delete-config.dialog";
import {MatDialog} from "@angular/material/dialog";
import { ConfigType } from "@rezonence/core";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "app-delete-config-button",
  templateUrl: "./delete-config-button.component.html",
  styleUrls: ["./delete-config-button.component.css"]
})
export class DeleteConfigButtonComponent {
  @Input()
  configId: string;

  @Input()
  configType: ConfigType;

  @Output()
  deleted: EventEmitter<void> = new EventEmitter();

  private _successMessage: string;

  get successMessage(): string {
    if  (!this._successMessage) {
      this._successMessage = `Successfully deleted ${this.configId}`;
    }
    return this._successMessage;
  }

  set successMessage(message: string) {
    this._successMessage = message;
  }

  constructor(public dialog: MatDialog,
              public snackbar: MatSnackBar) { }

  deleteConfig(): void {
    const dialog = this.dialog.open(DeleteConfigDialogComponent);
    dialog.componentInstance.deleteRequest = {
      configId: this.configId,
      configType: this.configType
    };
    dialog.afterClosed().subscribe(async result => {
      if (result) {
        this.snackbar.open(this.successMessage, "OK");
        this.deleted.emit();
      }
    });
  }

}
