import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../auth/AuthService";

@Component({
  selector: "logout-button",
  templateUrl: "logout.button.component.html"
})
export class LogoutButtonComponent {

  constructor(public auth: AuthService) { }
}
