import { Optional, downloadJson } from "@rezonence/sdk";
import { RequestUrlBuilder } from "./RequestUrlBuilder";

export class AnalyticsClient<K extends object, V> {
  constructor(private endpoint: URL) {
  }

  async request(input: K): Promise<Optional<V>> {
    const requestUrl = RequestUrlBuilder.fromEndpoint(this.endpoint).withQueryParams(input).build();
    return downloadJson<V>(requestUrl);
  }
}
