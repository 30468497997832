export class RequestUrlBuilder {
  protected readonly url: URL;
  protected constructor(endpoint: URL) {
    this.url = new URL(endpoint.toString());
  }

  static fromEndpoint(endpoint: URL): RequestUrlBuilder {
    return new RequestUrlBuilder(endpoint);
  }

  stringifyEntries(input: object): [string, string][] {
    return Object.entries(input).map(([key, value]) => [`${key}`, `${value}`]);
  }

  withQueryParams(params: object): RequestUrlBuilder {
    const url = this.stringifyEntries(params).reduce((url, [key, value]) => {
      url.searchParams.append(key, `${value}`);
      return url;
    }, this.build());
    return new RequestUrlBuilder(url);
  }

  build(): URL {
    return new URL(this.url);
  }
}
