import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ReportService} from "./services";
import {Report, ReportType} from "@rezonence/core";
import {TitleService} from "../core";
import {Router} from "@angular/router";
import {ReportTreeService} from "./tree/tree.service";
import {ReportNode} from "./tree/report.node";
import {SearchService} from "./services/search.service";
import {CreateReportComponent} from "./create-report/create-report.component";
import {MatDialog} from "@angular/material/dialog";

interface ReportGroup {
  endDate: Date;
  title: string;
  reports: Report[];
}

const ReportTypeMap = {
  publisher: true,
  agency: false
};

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "report-list",
  templateUrl: "./report.list.html",
  styleUrls: ["./report.list.css"]
})
export class ReportListComponent implements OnInit {
  reportNodes: Promise<ReportNode[]>;
  firstOpen: boolean;
  @Input()
  reportType: ReportType;

  constructor(public reportService: ReportService,
              private titleService: TitleService,
              private router: Router,
              private reportTreeService: ReportTreeService,
              private dialog: MatDialog,
              private searchService: SearchService) {
  }

  set searchTerm(text: string) {
    this.searchService.search = text;
  }

  get searchTerm(): string {
    return this.searchService.search;
  }

  ngOnInit(): any {

    this.reportNodes = this.reportTreeService.loadTree(this.reportType);

  }

  isSelected(report: Report) {
    return report && this.reportService.selectedReport && this.reportService.selectedReport.id === report.id;
  }

  show(report: Report): boolean {

    if (!this.searchTerm) {
      return true;
    }

    return typeof Object.keys(report).find(key => {

      const value = report[key];

      if (value && (typeof value === "string")) {

        return value.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;

      }

      return false;

    }) === "string";

  }

  createNew() {

    const dialog = this.dialog.open(CreateReportComponent);
    dialog.componentInstance.reportType = this.reportType;

    /* const dialogRef = this.modal.prompt()
      .className("default")
      .message("Enter a title for the new report")
      .placeholder("Report title")
      .okBtn("Create")
      .open();

    dialogRef.result.then(title => {

        this.reportService.createNewReport(title, this.reportType).then((report) => {

          if (this.reportType === "publisher") {
            report.displayECPM = true;
          }

          this.reportService.saveReport(report).then(() => {
            this.router.navigate(["report", report.id]);
          });

        });

      }); */

  }

  onReportDeleted(report: Report) {
    this.reportNodes = this.reportTreeService.loadTree(this.reportType);
  }

}
