import { ModuleWithProviders, NgModule } from "@angular/core";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { AuthModuleOptions } from "./AuthModuleOptions";
import { AuthenticationService } from "./AuthenticationService";
import { AuthNav } from "./AuthNav";

@NgModule()
export class Auth0Module {
  static forRoot(options: AuthModuleOptions): ModuleWithProviders<Auth0Module> {
    return {
      ngModule: Auth0Module,
      providers: [
        {
          provide: Auth0Client,
          useValue: new Auth0Client(options.auth0)
        },
        AuthNav,
        AuthenticationService
      ]
    }
  }
}
