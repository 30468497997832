import { Injectable } from "@angular/core";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";
import { environment as awsEnv } from "@rezonence/core";
import { S3IndexClient } from "@rezonence/s3-index-client";
import { InfrastructureResolver } from "../core";
import { ConfigListItem } from "../freewalls/config.list.item";
import { collect } from "@rezonence/array-utils";
import { BehaviorSubject, Observable, combineLatest, debounceTime, lastValueFrom, map, shareReplay, switchMap, take } from "rxjs";
import { Duration } from "@rezonence/duration";
import { AuthService } from "../auth/AuthService";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class FwIndexClient {
  public readonly items$: Observable<Promise<ConfigListItem[]>>;
  protected readonly refresh$: BehaviorSubject<void>;
  protected client$: Observable<S3IndexClient>;

  constructor(infra: InfrastructureResolver, authService: AuthService) {
    this.client$ = authService.idToken$.pipe(map(idToken => fromCognitoIdentityPool({
      identityPoolId: environment.identityPoolId,
      logins: {
        [environment.auth0RezonenceDomain]: idToken.__raw
      },
      clientConfig: {
        region: awsEnv.REGION
      }
    })),
      map(credentials => new S3IndexClient(DynamoDBDocumentClient.from(new DynamoDBClient({
        region: awsEnv.REGION,
        credentials
      }), {
        marshallOptions: {
          convertEmptyValues: true,
          removeUndefinedValues: true
        }
      }), infra.config.tables.adIndex)));

    this.refresh$ = new BehaviorSubject(undefined);
    this.items$ = combineLatest([this.client$, this.refresh$]).pipe(debounceTime(Duration.MsInSecond), map(([client]) => this.listItems(client)), shareReplay(1))
  }

  refresh(): void {
    this.refresh$.next();
  }

  async listAll(): Promise<ConfigListItem[]> {
    return lastValueFrom(this.items$.pipe(take(1)));
  }

  toAdId(key: string): string {
    const parts = key.split("/");
    parts.shift();
    parts.pop();
    return parts.join("/");
  }

  protected async listItems(client: S3IndexClient): Promise<ConfigListItem[]> {
    const items = await collect(client.list());
    return items.map(item => ({
      configId: this.toAdId(item.s3Key),
      lastModified: new Date(item.created).getTime()
    }))
  }

}
