import { Injectable } from "@angular/core";
import { DashboardRequestConverter } from "@rezonence/freewall-compiler";


@Injectable({
  providedIn: "root"
})
export class DashboardRequestService extends DashboardRequestConverter {

  constructor() {
    super();
  }
}
