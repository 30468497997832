import { ReportDataRequest } from "./ReportDataRequest";
import { ReportDataValueTypes } from "./ReportDataValueTypes";
import { ReportDataQueryType } from "./ReportDataQueryType";
import { ReportDataSource } from "./ReportDataSource";
import { AnalyticsClient } from "./AnalyticsClient";
import { Optional } from "@rezonence/sdk";
import { resolveDefaultDateRangeOptions } from "./resolveDefaultDateRangeOptions";
import { FreewallEventColumn } from "@rezonence/freewall-events";
export class ReportDataClient<K extends ReportDataQueryType, V extends FreewallEventColumn> extends AnalyticsClient<ReportDataRequest<K, V>, ReportDataValueTypes<V>[K][]> implements ReportDataSource<K, V> {

    constructor(endpoint: URL, private defaultOptions = resolveDefaultDateRangeOptions()) {
        super(endpoint);
    }

  async request(input: ReportDataRequest<K, V>): Promise<Optional<ReportDataValueTypes<V>[K][]>> {
        return super.request({
            ...this.defaultOptions,
            ...input
        })
    }
}
