import { TableDefinition } from "@rezonence/core/dynamo/table.definition";
import { indexKeySchema } from "./index.key.schema";
import { partitionKeySchema } from "./partition.key.schema";

export const publisherConfigMappingTable: TableDefinition = {
    hash: partitionKeySchema,
    overrides: {
        AttributeDefinitions: [
            partitionKeySchema,
            indexKeySchema
        ],
        GlobalSecondaryIndexes: [
            {
                IndexName: `${indexKeySchema.AttributeName}-index`,
                Projection: {
                    ProjectionType: "ALL"
                },
                KeySchema: [
                    {
                        AttributeName: indexKeySchema.AttributeName,
                        KeyType: "HASH"
                    }
                ]
            }
        ]
    }

};
