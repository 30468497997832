import {Encodable} from "./encodable";
import {DitKey} from "./dit.key";
import {EventType, Optional} from "@rezonence/sdk";
import {KeyValue, KvStorageEvent, AbstractKeyValueStore, KeyValueStore} from "@rezonence/dao";

export class EventTypeDao<T> extends AbstractKeyValueStore<Date, T> implements KeyValueStore<Date, T> {

    constructor(private store: KeyValueStore<DitKey, Encodable>, private eventType: EventType) {
        super();
    }

    toKey(date: Date): DitKey {
        return {
            date,
            type: this.eventType
        };
    }

    async* list(): AsyncIterable<Date> {
        for await (const key of this.store.list()) {
            if (key.type === this.eventType) {
                yield key.date;
            }
        }
    }

    read(key: Date): Promise<Optional<T>> {
        return this.store.read(this.toKey(key)) as Promise<Optional<T>>;
    }

    writeValue(request: KeyValue<Date, T>): Promise<void> {
        return this.store.write({
            key: this.toKey(request.key),
            value: request.value
        });
    }

    async* listenToChanges(): AsyncIterable<KvStorageEvent<Date, T>> {
        for await (const event of this.store.listenToChanges()) {
            if (event.key.type === this.eventType) {
                yield this.toStorageEvent(event.oldValue as Optional<T>, {
                    key: event.key.date,
                    value: event.newValue.item as T
                });
            }
        }
    }
}
