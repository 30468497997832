import { Injectable } from "@angular/core";
import {InvocationResponse} from "aws-sdk/clients/lambda";
import {AWSService} from "./aws.service";
import Lambda from "aws-sdk/clients/lambda";

@Injectable({
  providedIn: "root"
})
export class LambdaService {

  constructor(private awsService: AWSService) { }

  async invoke<I, O>(functionName: string, input?: I): Promise<O> {
    const lambda = await this.awsService.getService(Lambda);
    const output = await lambda.invoke({
      FunctionName: functionName,
      Payload: input ? JSON.stringify(input) : undefined
    }).promise();
    return this.toResponse<O>(output);
  }

  toResponse<T>(input: InvocationResponse): T {
    const payload = JSON.parse(input.Payload.toString());
    if (input.FunctionError) {
      throw new Error(payload.errorMessage);
    }
    return payload as T;
  }
}
