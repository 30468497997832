import { Injectable } from "@angular/core";
import {ParamListClient} from "@rezonence/analytics-dao";
import { InfrastructureResolver } from "../../core";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Injectable({
    providedIn: "root"
})
export class ParamListService extends ParamListClient<FreewallEventColumn> {
    constructor(infra: InfrastructureResolver) {
        super(new URL(infra.config.analytics.paramListEndpoint));
    }
}
