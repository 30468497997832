import {AbstractKeyValueStore, KeyValue} from "@rezonence/dao";
import {CreativeCap} from "@rezonence/core/config-extractor/publisher/creative.cap";
import {
    ConfigItem,
    EditableType,
    PublisherConfigCompiler,
    PublisherConfigMappingDao,
    PublisherConfigType
} from "@rezonence/publisher-config-dao";
import {Optional} from "@rezonence/sdk";
import {FreeWallConfigDownloader, PublisherConfig} from "@rezonence/core";

export class PublisherCreativeCappingDao extends AbstractKeyValueStore<string, CreativeCap> {

    constructor(private adId: string,
                private publisherConfigMappingDao: PublisherConfigMappingDao,
                private configDownloader: FreeWallConfigDownloader,
                private publisherConfigCompiler: PublisherConfigCompiler) {
        super();
    }

    async* list(): AsyncIterable<string> {
        const publisherConfigMappings = await this.publisherConfigMappingDao.listTopMappings();
        for (const configMap of publisherConfigMappings) {
            const creativeCap = await this.read(configMap.configId);
            if (creativeCap.exists) {
                yield configMap.configId;
            }
        }
    }

    async read(configId: string): Promise<Optional<CreativeCap>> {
        const configOptional = await this.configDownloader.downloadPublisherConfig(configId);
        return configOptional.map(config => config?.customCapping?.find(cap => cap.adId === this.adId));
    }

    async writeValue(request: KeyValue<string, CreativeCap>): Promise<void> {
        const optionalConfig = (await this.configDownloader.downloadPublisherConfig(request.key))
            .map(config => ({
                ...config,
                customCapping: config.customCapping
                    ? [...config.customCapping.filter(cap => cap.adId !== request.value.adId), request.value]
                    : [request.value]
            }));
        await Optional
            .switchPromise(optionalConfig
                .map(async config => await this.savePublisherConfig(request.key, config)));
    }

    async delete(configId: string): Promise<void> {
        const optionalConfig = await this.configDownloader.downloadPublisherConfig(configId);
        await Optional.switchPromise(optionalConfig.map(async config => {
            const creativeCapIndex = config.customCapping.findIndex(cap => cap.adId === this.adId);
            if (creativeCapIndex > -1) {
                config.customCapping.splice(creativeCapIndex, 1);
                await this.savePublisherConfig(configId, config);
            }
        }));
    }

    private async savePublisherConfig(configId: string, config: PublisherConfig): Promise<void> {
        await this.publisherConfigCompiler.save({
            configType: PublisherConfigType.PublisherConfig,
            configId,
            config: new ConfigItem<EditableType>(JSON.stringify(config)),
            debug: false
        });
    }
}
