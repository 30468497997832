import { DefaultTagService } from '@rezonence/core/tag/default.tag.service'
import { Injectable } from '@angular/core'
import { CampaignTemplateResolver } from './CampaignTemplateResolver'
import { ConfigResolverService } from './config.resolver.service'

@Injectable()
export class TagGenerationService extends DefaultTagService {
  constructor(configResolver: ConfigResolverService,
    templateResolver: CampaignTemplateResolver) {
    super(configResolver, templateResolver as any)
  }
}
