import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { importFreeWallParamsSchema, ImportFreeWallParams } from "@rezonence/campaigns-config"
import { FreewallService } from "../services/FreewallService";
import { map, shareReplay, switchMap } from "rxjs";
import { ValidationService } from "../../core/validation.service";

@Injectable()
export class ImportFreeWallService {
  readonly importedAdId$ = this.activatedRoute.queryParams.pipe(
    map((queryParams) => this.validator.validate(queryParams as ImportFreeWallParams, importFreeWallParamsSchema)),
    switchMap((importParams) => this.importFreeWall(importParams)),
    shareReplay(1)
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private validator: ValidationService,
    private freeWall: FreewallService
  ) { }

  private async importFreeWall(request: ImportFreeWallParams): Promise<string> {
    await this.freeWall.createNew(request)
    return request.sourceId
  }
}
