import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {VersionService} from "../services/version.service";

@Component({
  selector: "app-version-selector",
  templateUrl: "./version-selector.component.html",
  styleUrls: ["./version-selector.component.css"]
})
export class VersionSelectorComponent implements OnInit {

  versionListPromise: Promise<string[]>;

  @Output()
  versionChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  version: string;

  constructor(private versionService: VersionService) { }

  ngOnInit() {
    this.versionListPromise = this.versionService.listVersions();
  }

}
