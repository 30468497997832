import {Base64Encoder} from "./base64.encoder";
import {StringParser} from "./string.parser";
import {KeySorter} from "@rezonence/key-sorter";
import {Encodable} from "./encodable";

export class DitEncoder<T extends Encodable> extends StringParser<T> {

    constructor(private b64Encoder: Base64Encoder) {
        super();
    }

    isValid(input: string): boolean {
        return this.b64Encoder.isValid(input);
    }

    encode(input: Encodable): string {
        const canonicalInput = KeySorter.sort(input);
        return this.b64Encoder.encode(JSON.stringify(canonicalInput));
    }

    parseString(input: string): T {
        return JSON.parse(this.b64Encoder.parse(input).item) as T;
    }
}
