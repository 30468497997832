import {StringParser} from "./string.parser";
import isBase64 from "is-base64";

export abstract class Base64Encoder extends StringParser<string> {

    isValid(input: string): boolean {
        return isBase64(input);
    }

}
