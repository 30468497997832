import {DitKey} from "./dit.key";
import {Encodable} from "./encodable";
import {KeyValueStore} from "@rezonence/dao";
import {DitEncoder} from "./dit.encoder";
import {BackedDao} from "./backed.dao";
import {Versioned} from "./versioned";
import {DitKeyParser} from "./dit.key.parser";
import {EventType} from "@rezonence/sdk";
import {EventTypeDao} from "./event.type.dao";
import {AnswerData} from "./answer.data";

export class DaoFactory {
    constructor(private ditEncoder: DitEncoder<Encodable>) {
    }

    create<T>(store: KeyValueStore<string, string>, eventType: EventType): KeyValueStore<Date, T> {
        const ditKeyParser = new DitKeyParser(this.ditEncoder as DitEncoder<Versioned<DitKey>>);
        const currentDao = new BackedDao(store, {key: ditKeyParser, value: this.ditEncoder});
        return new EventTypeDao(currentDao, eventType);
    }

    createAnswerClickDao(store: KeyValueStore<string, string>): KeyValueStore<Date, AnswerData> {
        return this.create<AnswerData>(store, EventType.AnswerClicked);
    }

    createDsImpressionDao(store: KeyValueStore<string, string>): KeyValueStore<Date, {}> {
        return this.create<{}>(store, EventType.DoubleserveImpression);
    }
}
