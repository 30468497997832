import {PublisherConfigCompiler} from "@rezonence/publisher-config-dao";
import {ValidationService} from "../../core/validation.service";
import {CampaignFileService} from "../../core/campaign.file.service";
import {CampaignUtils} from "../../core/campaign.utils";
import {PublisherConfigMappingService} from "../../reports/services/publisher.config.mapping.service";
import { Injectable } from "@angular/core";
import {SubSitesAdapterService} from "../sub-sites/sub-sites-adapter.service";
import {SchemaService} from "./schema.service";
import {CompilerService} from "./compiler.service";
import { CampaignTemplateResolver } from "../../core";

@Injectable({
  providedIn: "root"
})
export class SaveService extends PublisherConfigCompiler {

  constructor(compilerService: CompilerService,
              validator: ValidationService,
              fileService: CampaignFileService,
              schemaService: SchemaService,
              utils: CampaignUtils,
              subSitesAdapter: SubSitesAdapterService,
              mappingsDao: PublisherConfigMappingService,
              templateResolver: CampaignTemplateResolver) {
    super(compilerService, validator, fileService, schemaService, utils, subSitesAdapter, mappingsDao, templateResolver);
  }

}
