import { Injectable } from "@angular/core";
import {ConfigCompiler} from "@rezonence/core/freewall-compiler/config.compiler";
import {CampaignFileService} from "../../core/campaign.file.service";
import {CampaignTemplateService} from "../../core";
import {CampaignUtils} from "../../core/campaign.utils";

@Injectable({
  providedIn: "root"
})
export class CompilerService extends ConfigCompiler {

  constructor(fileService: CampaignFileService,
              templateService: CampaignTemplateService,
              utils: CampaignUtils) {
    super(fileService, templateService, utils, true);
  }
}
