import {FreeWallDB} from "@rezonence/core/freewall/freewall.db";
import {Injectable} from "@angular/core";
import {AWSService} from "./aws.service";
import { InfrastructureResolver } from "./InfrastructureResolver";

@Injectable()
export class FreeWallDBService extends FreeWallDB {

  constructor(awsService: AWSService, infra: InfrastructureResolver) {
    super(awsService.dynamodb(), infra.config.tables.UserData);
  }
}
