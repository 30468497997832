import {Pipe, PipeTransform} from "@angular/core";
import {Answer, Question} from "@rezonence/sdk";
import {UserPreferenceEncoderService} from "../services/user.preference.encoder.service";

@Pipe({
  name: "userPreferenceEncoder"
})
export class UserPreferenceEncoderPipe implements PipeTransform {

  constructor(private userPreferenceEncoderService: UserPreferenceEncoderService) {
  }

  transform(questions: Question[], question: Question, answer: Answer, adId: string): string {
    const answerData = {
      adId,
      questionIndex: questions.indexOf(question),
      answerIndex: question.answers.indexOf(answer)
    };
    return this.userPreferenceEncoderService.encode(answerData);
  }

}
