export enum RsLogColumn {
   Ip = "ip",
   User = "user",
   Auth = "auth",
   LocalDate = "local_date",
   LocalTime = "local_time",
   Timezone = "timezone",
   Method = "method",
   Host = "host",
   UriStem = "uri_stem",
   UriQuery = "uri_query",
   ProtocolVersion = "protocol_version",
   Status = "status",
   Bytes = "bytes",
   Referer = "referer",
   UserAgent = "user_agent"
}