import { Injectable } from "@angular/core";
import {AWSService} from "../../core";
import {environment} from "@rezonence/core/aws/environment";
import {ListObjectsV2Output, ListObjectsV2Request} from "aws-sdk/clients/s3";
import {desc} from "semver-sort";
import S3 from "aws-sdk/clients/s3";
import SemVer from "semver";

@Injectable({
  providedIn: "root"
})
export class VersionService {

  constructor(private awsService: AWSService) {
  }

  toFolderList(listOutput: ListObjectsV2Output): string[] {
    return listOutput.CommonPrefixes
      .map(commonPrefix => commonPrefix.Prefix.replace("/", ""))
      .filter(versionString => SemVer.valid(versionString));
  }

  async listVersions(): Promise<string[]> {
    const s3 = await this.awsService.getService(S3);
    const list = [];
    const listRequest: ListObjectsV2Request = {
      Bucket: environment.TEMPLATE_BUCKET,
      Prefix: "v",
      Delimiter: "/"
    };
    let data = await s3.listObjectsV2(listRequest).promise();
    list.push(...this.toFolderList(data));
    while (data.ContinuationToken) {
      listRequest.ContinuationToken = data.ContinuationToken;
      data = await s3.listObjectsV2(listRequest).promise();
      list.push(...this.toFolderList(data));
    }

    return desc(list);
  }
}
