import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService, IdToken } from '@rezonence/ng-auth0'
import { filter, map, Observable, of, shareReplay, switchMap } from 'rxjs'
import { environment } from '../../environments/environment'
import { AWSService } from '../core'
import { CampaignAuthorisationService } from '../core/campaign.authorisation.service'
import { ReportsRoutePath } from '../reports/ReportsRoutePath'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly idToken$: Observable<IdToken> = this.auth.idToken$.pipe(
    switchMap((token) => this.authorize(token)),
    shareReplay(1)
  )

  constructor(
    private auth: AuthenticationService,
    private aws: AWSService,
    private campaignAuth: CampaignAuthorisationService,
    private router: Router
  ) {
    this.idToken$.pipe(
      switchMap(() => of(location.pathname)),
      filter((path) => path === '/')
    ).subscribe(() => this.redirectToHome())
  }

  logout(): Promise<void> {
    this.aws.signOut()
    return this.auth.logout(`${location.origin}/`)
  }

  private async authorize(token: IdToken): Promise<IdToken> {
    await this.aws.switchToAuthenticatedProfile({
      providerName: environment.auth0RezonenceDomain,
      token: token.__raw
    })
    const identity = await this.aws.identityId()
    await this.campaignAuth.updateDetails(identity, token.email, {
      email: token.email
    })
    return token
  }

  private redirectToHome(): Promise<boolean> {
    return this.router.navigate([ReportsRoutePath.Reports])
  }
}
