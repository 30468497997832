<label>{{options?.notitle ? options?.placeholder : options?.title}}</label>
<mat-slider thumbLabel *ngIf="boundControl"
            [formControl]="formControl"
            [attr.aria-describedby]="'control' + layoutNode?._id + 'Status'"
            [id]="'control' + layoutNode?._id"
            [max]="options?.maximum"
            [min]="options?.minimum"
            [step]="options?.multipleOf || options?.step || 'any'"
            [style.width]="'100%'"
            (blur)="options.showErrors = true"></mat-slider>
<mat-slider thumbLabel *ngIf="!boundControl"
            [attr.aria-describedby]="'control' + layoutNode?._id + 'Status'"
            [disabled]="controlDisabled || options?.readonly"
            [id]="'control' + layoutNode?._id"
            [max]="options?.maximum"
            [min]="options?.minimum"
            [step]="options?.multipleOf || options?.step || 'any'"
            [style.width]="'100%'"
            [value]="controlValue"
            (blur)="options.showErrors = true"
            (change)="updateValue($event)"></mat-slider>
<mat-error *ngIf="options?.showErrors && options?.errorMessage"
           [innerHTML]="options?.errorMessage"></mat-error>
