<div class="dit-keys">
  <ng-container *ngIf="freeWallConfigPromise | async as freeWallConfig">
    <h3>DIT Keys</h3>
    <h4 class="adId">{{adId}}</h4>
    <div *ngFor="let question of freeWallConfig.item.questions">
      <mat-card>
        <h4 class="question">{{question.que}}</h4>
        <mat-card-content>
          <form>
            <div *ngFor="let answer of question.answers" fxLayout="row" fxLayoutAlign="start">
              <mat-form-field class="dit-keys-form-field" fxFill>
                <mat-label class="dit-answer">{{answer.ans}}</mat-label>
                <div class="dit-keys-encoded-answer">
                  <input #clipboardTarget fxFlex="90%" matInput readonly="true"
                    value="{{freeWallConfig.item.questions | userPreferenceEncoder: question : answer : adId}}">
                  <button [ngxClipboard]="clipboardTarget" class="copy-link-button" mat-button color="primary" fxFlex>
                    <mat-icon>file_copy</mat-icon>
                  </button>
                </div>
              </mat-form-field>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
</div>

