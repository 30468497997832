<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <input matInput
         [matDatepicker]="datePicker"
         [(ngModel)]="formattedDate"
         [disabled]="disabled"
         (ngModelChange)="onChange($event)"
         name="endDate">
  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker></mat-datepicker>
</mat-form-field>
