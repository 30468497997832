import {Pipe, PipeTransform} from "@angular/core";
import {decamelize} from "@angular-devkit/core/src/utils/strings";

@Pipe({
  name: "decamelise"
})
export class DecamelisePipe implements PipeTransform {

  transform(value: string): string {
    return decamelize(value).replace("_", " ");
  }

}
