/**
 * This class tracks the files that need to be uploaded to S3 when compiling a publisher config or FreeWall
 */
import {IFileService, ScannedFile} from "@djabry/fs-s3";

export class CompilationScope {

    foldersToSkip: string[];
    filesToCopy: Record<string, ScannedFile | string>;

    constructor() {
        this.filesToCopy = {};
        this.foldersToSkip = [
            ".git",
            ".hg",
            ".DS_Store",
            ".idea"
        ];
    }

    skipFile(inputFile: ScannedFile | string, destinationKey: string): boolean {

        const pathElements = destinationKey.split("/");

        return !!pathElements.find(pathElement => {
            return this.foldersToSkip.indexOf(pathElement) > -1;
        });
    }

    /**
     * Specify a file that should be uploaded to S3, an existing file with the same destination will get
     * removed from the scope
     * @param {ScannedFile | string} inputFile - The input file location or string content to write
     * @param {string} destinationKey - The S3 key to write the file to
     */
    addFile(inputFile: ScannedFile | string, destinationKey: string) {

        destinationKey = destinationKey.replace(/\\/g, "/");

        if (!this.skipFile(inputFile, destinationKey)) {

            this.filesToCopy[destinationKey] = inputFile;

        } else {

            console.log("Skipping invalid file:", destinationKey);
        }

    }

    removeFile(destinationKey: string) {
        delete this.filesToCopy[destinationKey];
    }

}
