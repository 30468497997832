import {DefaultUtils} from "@rezonence/core/utils/default.utils";
import { Injectable } from "@angular/core";
import { tag } from "@rezonence/git";
import { ActivatedRoute } from "@angular/router";

@Injectable()
export class CampaignUtils extends DefaultUtils {

  constructor(private route: ActivatedRoute) {
    super();
  }

  getBranch(): string {
    const queryParamsMap = this.route.snapshot.queryParamMap;
    return queryParamsMap.get("branch") || tag;
  }

}
