import {ReportNode} from "./report.node";
import {Report} from "@rezonence/core";
import {ReportGroup} from "./report.group";

export abstract class NodeFactory {

  constructor(public childNodeFactory?: NodeFactory) {
  }

  abstract split(reports: Report[]): ReportGroup[];

  createNode(group: ReportGroup): ReportNode {

    const node: ReportNode = {
      title: group.title,
    };

    if (group.reports.length === 1) {

      node.report = group.reports[0];

    } else {
      node.children = this.childNodeFactory.getChildren(group.reports);
    }

    return node;
  }

  getChildren(reports: Report[]): ReportNode[] {

    const groups = this.split(reports);

    // Keep splitting until we reach individual nodes
    return groups.map(group => this.createNode(group)).sort((n1, n2) => {

      const title1 = n1.report ? n1.report.title : n1.title;
      const title2 = n2.report ? n2.report.title : n2.title;

      if (n1.report && !n2.report) {
        return 1;
      }

      if (!n1.report && n2.report) {
        return -1;
      }

      if (title1 === title2) {
        return 0;
      } else if (title1 > title2) {
        return 1;
      } else if (title2 > title1) {
        return -1;
      }

    });
  }
}
