import {Optional} from "@rezonence/sdk";
import {KeyValue, AbstractKeyValueStore} from "@rezonence/dao";

export class InMemoryKeyValueStore<T> extends AbstractKeyValueStore<string, T> {

    constructor(protected store: Record<string, T> = {}) {
        super();
    }

    async read(key: string): Promise<Optional<T>> {
        return Optional.of(this.getStoreValue(key));
    }

    async* list(): AsyncIterable<string> {
        const keys = Object.keys(this.store);
        for (const key of keys) {
            yield key;
        }
    }

    async writeValue(request: KeyValue<string, T>): Promise<void> {
        this.store[request.key] = request.value;
    }

    protected getStoreValue(key: string): T | undefined {
        return this.store[key];
    }

}
