import {Injectable} from "@angular/core";
import {UserPreferenceEncoder} from "@rezonence/dit";
import {DitEncoderService} from "./dit.encoder.service";

@Injectable({
  providedIn: "root"
})
export class UserPreferenceEncoderService extends UserPreferenceEncoder {

  constructor(private ditEncoder: DitEncoderService) {

    super(ditEncoder);

  }

}
