import { Report } from "@rezonence/core";
import {NodeFactory} from "./node.factory";
import {ReportGroup} from "./report.group";
import {Duration} from "@rezonence/duration";

export class DateNodeFactory extends NodeFactory {

  split(reports: Report[]): ReportGroup[] {

    const lastWeek = (new Date()).getTime() - (Duration.MsInWeek);
    // Split into new and old reports

    const currentReports = reports.filter(report => report.endDate >= lastWeek);
    const oldReports = reports.filter(report => report.endDate < lastWeek);
    return [
      {
        title: "Current",
        reports: currentReports
      },
      {
        title: "Previous",
        reports: oldReports

      }
    ];

  }

}
