<h4>Create a new report</h4>
<div *ngIf="!creating">
  <mat-form-field>
    <input type="text" matInput [(ngModel)]="title" placeholder="Report title">
  </mat-form-field>
  <button mat-button color="primary" *ngIf="title && reportType" (click)="create()">Go</button>
</div>
<div *ngIf="creating">
  <mat-progress-bar color="primary"></mat-progress-bar>
</div>
<div *ngIf="error">
  <p>Failed to create report</p>
  <pre>{{error | json}}</pre>
</div>
