<ng-container *ngIf="fileType === '.json'; else code">
  <div class="toggle-container">
    <mat-button-toggle-group class="toggle" #group="matButtonToggleGroup">
      <mat-button-toggle value="form" checked="true">Form</mat-button-toggle>
      <mat-button-toggle value="json">Code</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <ng-container *ngIf="group.value === 'form'; else json">
    <app-json-form-editor [(data)]="data"
                          [schema]="schema"
                          [layout]="layout"
                          (isValid)="isValid.emit($event)"
                          (validationErrors)="validationErrors.emit($event)">
    </app-json-form-editor>
  </ng-container>

  <ng-template #json>
    <app-json-code-editor [(data)]="data"
                          [schema]="schema"
                          (isValid)="isValid.emit($event)"
                          (validationErrors)="validationErrors.emit($event)">
    </app-json-code-editor>
  </ng-template>
</ng-container>

<ng-template #code>
  <div class="code-editor-container">
    <rez-code-editor
      [language]="mode"
      [(text)]="data"
      (isValid)="isValid.emit($event)"
      [customStyling]="codeEditorStyling">
    </rez-code-editor>
  </div>
</ng-template>

