import { Pipe, PipeTransform } from "@angular/core";
import {KeyValue} from "@rezonence/dao";
import { CreativeCap } from "@rezonence/core/config-extractor/publisher/creative.cap";

@Pipe({
  name: "configId",
  pure: false
})
export class ConfigIdPipe implements PipeTransform {

  constructor() {
  }

  transform(creativeCapEntries: KeyValue<string, CreativeCap>[]): string[] {
    return creativeCapEntries.map(entry => entry.key);
  }

}
