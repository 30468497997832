<h4>Delete report?</h4>
<div *ngIf="!deleting && !!report">
  <p>Are you sure you want to delete <strong>{{report.title}}</strong>?</p>
  <button color="warn" mat-button (click)="deleteReport()">Delete</button>
  <button color="primary" mat-button (click)="cancel()">Cancel</button>
</div>
<div *ngIf="deleting">
  <mat-progress-bar color="primary"></mat-progress-bar>
</div>
<div *ngIf="!!error">
  <p>Failed to delete report</p>
  <pre>{{error}}</pre>
</div>
