export enum RealtimeLogColumnName {
   Timestamp = "timestamp",
   Ip = "c-ip",
   TimeToFirstByte = "time-to-first-byte",
   Status = "sc-status",
   Bytes = "sc-bytes",
   Method = "cs-method",
   Host = "cs-host",
   UriStem = "cs-uri-stem",
   RequestBytes = "cs-bytes",
   EdgeLocation = "x-edge-location",
   EdgeRequestId = "x-edge-request-id",
   HostHeader = "x-host-header",
   TimeTaken = "time-taken",
   ProtocolVersion = "cs-protocol-version",
   IpVersion = "c-ip-version",
   UserAgent = "cs-user-agent",
   Referer = "cs-referer",
   Cookie = "cs-cookie",
   UriQuery = "cs-uri-query",
   EdgeResponseResultType = "x-edge-response-result-type",
   ForwardedFor = "x-forwarded-for",
   SslProtocol = "ssl-protocol",
   SslCipher = "ssl-cipher",
   EdgeResultType = "x-edge-result-type",
   FleEncryptedFields = "fle-encrypted-fields",
   FleStatus = "fle-status",
   ContentType = "sc-content-type",
   ContentLen = "sc-content-len",
   RangeStart = "sc-range-start",
   RangeEnd = "sc-range-end",
   Port = "c-port",
   EdgeDetailedResultType = "x-edge-detailed-result-type",
   Country = "c-country",
   AcceptEncoding = "cs-accept-encoding",
   Accept = "cs-accept",
   CacheBehaviorPathPattern = "cache-behavior-path-pattern",
   Headers = "cs-headers",
   HeaderNames = "cs-header-names",
   HeadersCount = "cs-headers-count"
}