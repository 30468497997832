import {Component, OnInit } from "@angular/core";
import {ArrayUtils} from "@rezonence/array-utils";
import {KeyValue} from "@rezonence/dao";
import { CreativeCap } from "@rezonence/core/config-extractor/publisher/creative.cap";
import {ActivatedRoute} from "@angular/router";
import { ConfigType } from "@rezonence/core";
import { FreewallService } from "../services/FreewallService";
import { MatSnackBar } from "@angular/material/snack-bar";
import {defaultCap} from "./default.cap";
import {PublisherCreativeCappingDaoService} from "../services/publisher-creative-capping-dao.service";
import {ProgressDialogService} from "../services/progress-dialog.service";
import { ReplaySubject} from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import {DeploymentConfirmationDialogComponent}
  from "../deployment-confirmation-dialog/deployment-confirmation-dialog.component";
import difference from "lodash/difference";
import cloneDeep from "lodash/cloneDeep";

@Component({
  selector: "app-creative-cap",
  templateUrl: "./creative-cap.component.html",
  styleUrls: ["./creative-cap.component.css"],
  providers: [PublisherCreativeCappingDaoService]
})
export class CreativeCapComponent implements OnInit {

  ConfigType = ConfigType;
  adId: string;
  removedConfigIds: string[];

  public readonly entries$: ReplaySubject<KeyValue<string, CreativeCap>[]> = new ReplaySubject(1);

  constructor(private publisherCappingDao: PublisherCreativeCappingDaoService,
              private route: ActivatedRoute,
              private progressDialogService: ProgressDialogService,
              private snackbar: MatSnackBar,
              private freeWallService: FreewallService,
              private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    this.adId = this.route.snapshot.queryParamMap.get(ConfigType.AdId);
    this.entries$.next(await ArrayUtils.collect(this.publisherCappingDao.listEntries()));
  }

  addOrRemoveEntries(selectedConfigIds: string[], entries: KeyValue<string, CreativeCap>[]) {
    if (entries.length < selectedConfigIds.length) {
      const entryToAdd = this.createNewEntry(selectedConfigIds, entries);
      this.add(entryToAdd, entries);
    }
    if (entries.length > selectedConfigIds.length) {
      const entryToRemove = entries.find(entry => !selectedConfigIds.includes(entry.key));
      this.remove(entryToRemove, entries);
    }
  }

  add(entryToAdd: KeyValue<string, CreativeCap>, entries: KeyValue<string, CreativeCap>[]) {
    entries.push(entryToAdd);
    this.entries$.next(entries);
  }

  remove(entryToRemove: KeyValue<string, CreativeCap>, entries: KeyValue<string, CreativeCap>[]) {
    entries = entries.filter(entry => entry.key !== entryToRemove.key);
    this.entries$.next(entries);
  }

  async submit(entries: KeyValue<string, CreativeCap>[]) {
    const entryIds = entries.map(entry => entry.key);

    this.progressDialogService.open();
    this.progressDialogService.message = "Identifying changes...";
    const existingEntryIds = await ArrayUtils.collect(this.publisherCappingDao.list());
    const removedEntryIds = difference(existingEntryIds, entryIds);
    const idsToDeploy = [...entryIds, ...removedEntryIds];
    await this.progressDialogService.close();

    const deployConfigs = await this.confirmDeployment(idsToDeploy);
    if (deployConfigs) {
      await this.deleteRemovedEntries(removedEntryIds);
      await this.save(entries);
      await this.deploy(idsToDeploy);
    }
  }

  private async confirmDeployment(configsToDeploy: string[]): Promise<boolean> {
    const dialogRef = this.dialog.open(DeploymentConfirmationDialogComponent, {
      data: {
        configIds: configsToDeploy
      },
      disableClose: true
    });
    return await dialogRef.afterClosed().toPromise();
  }

  private async deploy(configIds: string[]) {
    this.progressDialogService.open();
    this.progressDialogService.message = "Deploying configs...";
    try {
      for (const id of configIds) {
        this.progressDialogService.message = `Deploying ${id}...`;
        await this.freeWallService.writePublisherFilesToCDN(id);
      }
    } catch (err) {
      console.error(err);
      await this.progressDialogService.close();
      this.snackbar.open(`Failed to deploy: ${err}`, "OK");
    } finally {
      await this.progressDialogService.close();
      this.snackbar.open("Completed deploying configs", "OK");
    }
  }

  private async save(entries: KeyValue<string, CreativeCap>[]) {
    this.progressDialogService.open();
    this.progressDialogService.message = "Saving configs...";
    try {
      for (const entry of entries) {
        this.progressDialogService.message = `Saving ${entry.key}...`;
        await this.publisherCappingDao.write(entry);
      }
    } catch (err) {
      console.error(err);
      await this.progressDialogService.close();
      this.snackbar.open(`Failed to save: ${err}`, "OK");
    } finally {
      await this.progressDialogService.close();
      this.snackbar.open("Completed saving configs", "OK");
    }
  }

  private async deleteRemovedEntries(configIds: string[]) {
    this.progressDialogService.open();
    this.progressDialogService.message = "Checking for removed site overrides...";
    try {
      this.progressDialogService.message = "Deleting removed site overrides...";
      for (const configId of configIds) {
        this.progressDialogService.message = `Deleting ${configId} site override...`;
        await this.publisherCappingDao.delete(configId);
      }
    } catch (err) {
      console.error(err);
      await this.progressDialogService.close();
      this.snackbar.open(`Failed to remove: ${err}`, "OK");
    } finally {
      await this.progressDialogService.close();
      this.snackbar.open("Completed removing site overrides", "OK");
    }
  }

  private createNewEntry(selectedConfigs: string[],
                         entries: KeyValue<string, CreativeCap>[]): KeyValue<string, CreativeCap> {
    const configId = selectedConfigs.find(config => !(entries.map(entry => entry.key)).includes(config));
    return {
      key: configId,
      value: {
        adId: this.adId,
        cap: cloneDeep(defaultCap)
      }
    };
  }

}
