import {PublisherConfigType} from "./publisher.config.type";
import {publisherConfigFile} from "./publisher.config.file";

export const configFileNameByType: Record<PublisherConfigType, string> = {
  [PublisherConfigType.PublisherConfig]: publisherConfigFile.publisherConfigJson,
  [PublisherConfigType.Doubleserve]: publisherConfigFile.doubleserveConfig,
  [PublisherConfigType.Css]: publisherConfigFile.publisherFormat,
  [PublisherConfigType.CustomCode]: publisherConfigFile.customCode,
  [PublisherConfigType.SubSites]: publisherConfigFile.sites
};
