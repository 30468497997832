import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ReportService } from "./services/report.service";
import { Report } from "@rezonence/core";
import { DatePipe } from "@angular/common";
import { TitleService } from "../core/title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { reportIdQueryParam } from "@rezonence/live-report-config";
import { ReportType } from "@rezonence/core/report/ReportType";
import { DeleteReportComponent } from "./delete-report/delete-report.component";
import { MatDialog } from "@angular/material/dialog";
import { AccordionPanelComponent } from "ngx-bootstrap/accordion";
import { domain as reportDomain } from '@rezonence/live-report';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "report-item",
  templateUrl: "./report.item.html",
  styleUrls: [
    "./report.item.css"
  ]
})
export class ReportItemComponent implements OnInit {

  currentDate: number;

  @ViewChild(AccordionPanelComponent)
  detailPanel: AccordionPanelComponent;

  @Output()
  reportDeleted: EventEmitter<Report> = new EventEmitter<Report>();

  public reportLink: string;
  private _report: Report;

  constructor(public reportService: ReportService,
    private datePipe: DatePipe,
    private titleService: TitleService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute) {

    this.currentDate = new Date().getTime();

  }

  get report(): Report {
    return this._report;
  }

  @Input()
  set report(report: Report) {
    this._report = report;
    if (report.reportType === ReportType.article) {

      this.reportLink = "http://articles.rezonence.com/" + report.id;

    } else {
      this.reportLink = `https://${reportDomain}?${reportIdQueryParam}=${report.id}`;
    }

  }

  ngOnInit(): any {
  }

  showDetail() {
    return this.detailPanel && this.detailPanel.isOpen;
  }

  updateSelectedCampaign() {

    if (this.detailPanel.isOpen) {
      this.reportService.selectedReport = this.report;
    } else {
      this.reportService.selectedReport = null;
    }
  }

  duplicate(event) {
    event.preventDefault();

    const newReport = this.reportService.duplicate(this.report);

    this.reportService.saveReport(newReport).then(savedReport => {
      this.router.navigate(["report", savedReport.id]);
    });
  }

  edit(event) {
    event.preventDefault();
    this.router.navigate(["report", this.report.id]);

  }

  openReportLink(event) {

    event.preventDefault();
    event.stopPropagation();
    window.open(this.reportLink, "_blank");

  }

  onReportDeleted() {
    this.reportDeleted.emit(this.report);
  }

  deleteReport(event) {
    event.preventDefault();

    const ref = this.dialog.open(DeleteReportComponent);
    ref.componentInstance.report = this.report;

    ref.afterClosed().subscribe(result => {
      if (result) {
        this.reportDeleted.emit(this.report);
      }
    });
  }
}
