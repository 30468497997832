import { ConfigDownloader } from './ConfigDownloader'
import { DestinationPrefix } from './DestinationPrefix'
import { LinkMacro } from './LinkMacro'
import { LinkMacroRenderer } from './LinkMacroRenderer'
import { R3ZConfig, ResolveConfigRequest, FreeWallConfig } from '../freewall'
import { Optional } from './Optional'

export class ConfigResolver {
  linkMacroByPrefix: Record<DestinationPrefix, LinkMacro> = {
    [DestinationPrefix.Ads]: LinkMacro.AdId,
    [DestinationPrefix.Pub]: LinkMacro.ConfigId
  }

  constructor(private downloader: ConfigDownloader, private macroRenderer: LinkMacroRenderer) {
  }

  async resolveFromId<T extends R3ZConfig>(resolveConfigRequest: ResolveConfigRequest): Promise<Optional<T>> {
    const downloadedConfig = await this.downloader.downloadConfig<T>(resolveConfigRequest)
    return downloadedConfig.map(c => this.renderLinks(c, resolveConfigRequest))
  }

  async resolveFreeWallConfig(request: { adId: string, cdn: URL }): Promise<Optional<FreeWallConfig>> {
    return this.resolveFromId<FreeWallConfig>({
      id: request.adId,
      cdn: request.cdn,
      prefix: DestinationPrefix.Ads
    })
  }

  toCdnString(url: URL): string {
    return `${url.origin}/`
  }

  renderLinks<T extends R3ZConfig>(config: T, resolveConfigRequest: ResolveConfigRequest): T {
    const configString = JSON.stringify(config)
    const renderedConfig = this.macroRenderer.renderAll({
      link: configString,
      valueByMacro: {
        [LinkMacro.Cdn]: this.toCdnString(resolveConfigRequest.cdn),
        [this.linkMacroByPrefix[resolveConfigRequest.prefix]]: resolveConfigRequest.id,
        [LinkMacro.Version]: config.version
      }
    })
    return JSON.parse(renderedConfig) as T
  }
}
