import {Injectable} from "@angular/core";
import {FormControl} from "@angular/forms";
import {PublisherConfigMappingService} from "../reports/services/publisher.config.mapping.service";
import {ConfigValidationResult} from "./config.validation.result";
import {rootPublisherConfigId} from "@rezonence/publisher-config-dao";

@Injectable({
  providedIn: "root"
})
export class ConfigIdValidatorService {

  constructor(private dao: PublisherConfigMappingService) {
  }

  async validateConfigId(control: FormControl): Promise<ConfigValidationResult | null> {
    const configId = control.value;
    const configExistsValidationObject = {
      configExists: true
    };
    if (configId === rootPublisherConfigId) {
      return configExistsValidationObject;
    }
    const mapping = await this.dao.find({configId});
    if (mapping.exists) {
      return configExistsValidationObject;
    }
    return null;
  }
}
