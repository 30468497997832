import { Component, OnInit } from "@angular/core";
import {ReportService} from "../services";
import { Report } from "@rezonence/core";
import {Router} from "@angular/router";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-delete-report",
  templateUrl: "./delete-report.component.html",
  styleUrls: ["./delete-report.component.css"]
})
export class DeleteReportComponent implements OnInit {

  public deleting: boolean;
  public report: Report;
  public nextRoute: string;
  public error;

  constructor(public ref: MatDialogRef<DeleteReportComponent>,
              private reportService: ReportService,
              private router: Router) {
  }

  ngOnInit() {
  }

  async deleteReport() {
    this.ref.disableClose = true;
    this.deleting = true;

    try {

      await this.reportService.deleteReport(this.report);

      if (this.nextRoute) {
        await this.router.navigate([this.nextRoute]);
      }

      this.ref.close(this.report);

    } catch (err ) {
      this.error = err;
    }

    this.deleting  = false;
    this.ref.disableClose = false;
  }

  cancel() {
    this.ref.close();
  }

}
