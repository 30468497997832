import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {RouterModule} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import { CoreModule } from "../core";
import {SafePipe} from "./services/safe.pipe";
import {FreewallSearchPipe} from "./services/freewall-search.pipe";
import {FreewallService} from "./services/FreewallService";
import { ConfigListComponent } from "./config-list";
import {CreateTagComponent} from "./tag-create/tag-create.component";
import {CreateFreeWallComponent} from "./freewall-create/CreateFreeWallComponent";
import { FreeWallProgressComponent } from "./freewall-progress/freewall-progress.component";
import {RequestDeployComponent} from "./request-deploy/request-deploy.component";
import {DeleteConfigDialogComponent} from "./delete-config-dialog/delete-config.dialog";
import {FreewallSearchComponent} from "./freewall-search/freewall-search.component";
import {FreewallSearchService} from "./services/freewall-search.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { TagRegistrationService } from "./services/tag.registration.service";
import { FreewallListComponent } from "./freewall-list/FreewallListComponent";
import {PublisherListComponent} from "./publisher-list/publisher-list.component";
import {S3StatusCache} from "./services/s3.status.cache";
import {PubConfEditorComponent} from "./pub-conf-editor/pub-conf-editor.component";
import {MaterialDesignFrameworkModule} from "@ajsf/material";
import {DoubleserveEditorComponent} from "./doubleserve-editor/doubleserve-editor.component";
import {NoEditComponent} from "./no-edit/no-edit.component";
import {CustomNumberComponent} from "./custom-number/custom.number.component";
import {CustomSliderComponent} from "./custom-slider/custom-slider.component";
import {ConfigWizardComponent} from "./config-wizard/config-wizard.component";
import {FlexLayoutModule} from "@angular/flex-layout";
import {EditorsModule} from "../editors/editors.module";
import {VersionSelectorComponent} from "./version-selector/version-selector.component";
import {FileNamePipe} from "./file-name.pipe";
import {DeleteConfigButtonComponent} from "./delete-config-button/delete-config-button.component";
import { Route } from "./Route";
import {JoinPipe} from "./join.pipe";
import {FormErrorFormattingPipe} from "./form-error-formatting.pipe";
import {DuplicateWizardComponent} from "./duplicate-wizard/duplicate-wizard.component";
import {MatMenuModule} from "@angular/material/menu";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSliderModule} from "@angular/material/slider";
import {MatOptionModule} from "@angular/material/core";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {MatStepperModule} from "@angular/material/stepper";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {DitKeysComponent} from "./dit-keys/dit-keys.component";
import {MatCardModule} from "@angular/material/card";
import {UserPreferenceEncoderPipe} from "./user-preference-encoder/user-preference-encoder.pipe";
import {ClipboardModule} from "ngx-clipboard";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {CreativeCapComponent} from "./creative-cap/creative-cap.component";
import {ReportModule} from "../reports";
import {CreativeCapEntryComponent} from "./creative-cap-entry/creative-cap-entry.component";
import {ConfigIdPipe} from "./config-id.pipe";
import {PublisherCreativeCappingDaoService} from "./services/publisher-creative-capping-dao.service";
import {DeploymentConfirmationDialogComponent}
  from "./deployment-confirmation-dialog/deployment-confirmation-dialog.component";
import { CodeEditorModule } from "@rezonence/code-editor";
import { ImportFreeWallPage } from "./freewall-import/ImportFreeWallPage";
import { FreeWallDeployer } from "./services/FreeWallDeployer";


@NgModule({
  imports: [
    CommonModule,
    CodeEditorModule,
    MatOptionModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSnackBarModule,
    CoreModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatToolbarModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatAutocompleteModule,
    FormsModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatInputModule,
    MatFormFieldModule,
    MaterialDesignFrameworkModule,
    FlexLayoutModule,
    EditorsModule,
    MatMenuModule,
    MatCardModule,
    ClipboardModule,
    RouterModule.forChild([
      {
        path: Route.FreeWalls,
        component: FreewallListComponent
      },
      {
        path: Route.Configs,
        component: PublisherListComponent
      },
      {
        path: Route.Config,
        component: PubConfEditorComponent
      },
      {
        path: Route.CreativeCap,
        component: CreativeCapComponent
      },
      {
        path: Route.ImportFreeWall,
        component: ImportFreeWallPage
      }
    ]),
    MatCheckboxModule,
    ReportModule,
    MatFormFieldModule
  ],
  providers: [
    S3StatusCache,
    FreeWallDeployer,
    FreewallService,
    FreewallSearchService,
    TagRegistrationService,
    PublisherCreativeCappingDaoService
  ],
  declarations: [
    CustomNumberComponent,
    PublisherListComponent,
    FreewallListComponent,
    DeleteConfigDialogComponent,
    RequestDeployComponent,
    FreeWallProgressComponent,
    CreateFreeWallComponent,
    CreateTagComponent,
    ConfigListComponent,
    SafePipe,
    FreewallSearchComponent,
    FreewallSearchPipe,
    PubConfEditorComponent,
    DoubleserveEditorComponent,
    NoEditComponent,
    CustomSliderComponent,
    ConfigWizardComponent,
    VersionSelectorComponent,
    FileNamePipe,
    DeleteConfigButtonComponent,
    JoinPipe,
    FormErrorFormattingPipe,
    DuplicateWizardComponent,
    DitKeysComponent,
    UserPreferenceEncoderPipe,
    CreativeCapComponent,
    CreativeCapEntryComponent,
    ConfigIdPipe,
    DeploymentConfirmationDialogComponent,
    ImportFreeWallPage
  ],
  exports: [
    ConfigListComponent,
  ],
})
export class FreewallsModule {

}
