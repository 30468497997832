import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable()
export class TagRegistrationService {

  /**
   *
   * Registers a new tag for the placementId/adId combination (if one doesn't already exist) and returns the
   * id of the association
   *
   * @param {string} adId - The ad id of the FreeWall
   * @param {number} placementId - The Nexus placement id
   * @return {Promise<string>} - The id of the new tag
   */
  async registerTag(adId: string, placementId: number): Promise<string> {
    throw new Error("Not implemented yet");
  }

  /**
   * Returns the download link for the registered tag
   *
   * @param {string} tagId - The tag id
   * @return {string} - The download link
   */
  getTagDownloadLink(tagId: string): string {
    return `${environment.tagDownloadBase}/${tagId}`;
  }

}
