<p mat-dialog-title>
  Request authorisation to deploy without unlock trackers?
</p>
<mat-dialog-content>
  <p>
    You are not permitted to deploy this FreeWall since it has no unlock trackers.
  </p>
  <p>
    Would you like to request permission to deploy it without unlock trackers?
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="requestAuthorisation()" mat-button color="primary">
    Yes
  </button>
  <button (click)="cancel()" mat-button>
    No
  </button>
</mat-dialog-actions>

