import {Report} from "./Report";
import {ReportType} from "./ReportType";
import {Currency, Money} from "../types/money";

/**
 * The sole concrete Report implementation.
 */
export class DefaultReport implements Report {
    reportType: ReportType;
    displayECPM: boolean;
    displayRevenue: boolean;
    adjustment: number;
    answerBreakdown: boolean;
    externalReference: string;
    internalReference: string;
    configIds: string[];
    networkIds: string[];
    campaignIds: string[];
    domainId: string;
    id: string;
    reportId: string;
    startDate: number;
    endDate: number;
    adIds: string[];
    pubIds: string[];
    targetEngagements: number;
    title: string;
    cpe: Money;
    cpeCurrency: Currency;
    statusReport: boolean;
    displayClicks: boolean;

    constructor(domainId: string, title: string, id: string, reportType?: ReportType) {

        // Generate a date that's at the start of the day
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);

        // Generate an end date that is at the end of the day
        const endDate = new Date(startDate.getTime() + (1000 * 3600 * 24));

        endDate.setHours(23, 59, 59, 999);

        // Generate unique id
        this.domainId = domainId;
        this.id = id;
        this.reportId = id;
        this.title = title;
        this.adIds = [];
        this.pubIds = [];
        this.configIds = [];
        this.networkIds = [];
        this.adjustment = 0;
        this.cpe = 0.15;
        this.cpeCurrency = Currency.GBP;
        this.statusReport = false;
        this.targetEngagements = 10000;
        this.startDate = startDate.getTime();
        this.endDate = endDate.getTime();
        this.displayECPM = reportType === ReportType.publisher;
        this.answerBreakdown = false;
        this.displayRevenue = true;
        this.reportType = reportType;
        this.displayClicks = true;
    }
}
