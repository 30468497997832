import {KeyValueCache} from "./key.value.cache";
import {NgZone} from "@angular/core";
import {KeyValueCacheConfig} from "./key.value.cache.config";

export class BackgroundRefreshCache extends KeyValueCache {

  protected refreshOperations: Record<string, () => Promise<any>>;
  protected refreshing: boolean;

  constructor(options: KeyValueCacheConfig, protected zone: NgZone) {
    super(options);
    this.refreshOperations = {};
  }

  get keysToRefresh(): string[] {
    return Object.keys(this.refreshOperations);
  }

  async runRefresh() {
    if (!this.refreshing) {
      this.refreshing = true;
      try {
        while (this.keysToRefresh.length) {
          const key = this.keysToRefresh.pop();
          const refreshOperation = this.refreshOperations[key];
          try {
            await this.refreshCacheItem(key, refreshOperation);
          } finally {
            delete this.refreshOperations[key];
          }
        }
      } finally {
        this.refreshing = false;
      }
    }
  }

  async get<T>(key: string, executor: () => Promise<T>, maxAge?: number): Promise<T> {
    const cacheItem = (await this.findCacheItem<T>(key)) || (await this.refreshCacheItem(key, executor));
    if (!this.isCacheItemInDate(cacheItem, maxAge)) {
      this.zone.runOutsideAngular( () => {
        this.refreshOperations[key] = executor;
        this.runRefresh();
      });
    }

    return cacheItem.value;
  }

}
