
import { PipeTransform, Pipe } from "@angular/core";
import { DatasetItem, Meta, Dataset } from "@rezonence/core";
import { DashboardRequestService } from "./DashboardRequestService";
import { toDemoUrl } from '@rezonence/freewall-demo-client'

@Pipe({
  name: "demoLink"
})
export class DemoLinkPipe implements PipeTransform {

  constructor(private requestService: DashboardRequestService) {
  }

  toFallbackUrl(record: DatasetItem<Meta>): string {
    const url = toDemoUrl({
      aid: this.requestService.toAdId({
        ...record,
        recordFolder: Dataset.Freewall,
      })
    })
    return url.href;
  }

  transform(record: DatasetItem<Meta>): string {
    return record.value.demoLink || this.toFallbackUrl(record);
  }
}
