import { InPlaceEditorParams, freeWallCreatorURL, Route as FreeWallCreatorRoute } from "@rezonence/freewall-creator-config";
import { Route } from "./Route";

export function toFreeWallEditorUrl(adId: string): string {
  const url = new URL(FreeWallCreatorRoute.Edit, freeWallCreatorURL);
  const importerUrl = new URL(Route.ImportFreeWall, location.origin);
  const params: Record<InPlaceEditorParams, string> = {
    sourceId: adId,
    redirectTo: importerUrl.toString()
  }
  url.search = `?${new URLSearchParams(params).toString()}`;
  return url.toString();
}
