import { ReportService } from "./services";
import {
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { reportIdQueryParam, showImpressionsQueryParam } from "@rezonence/live-report-config";
import { Report, ReportType, ConfigType } from "@rezonence/core";
import { Currency } from "@rezonence/core/types/money";
import { DeleteReportComponent } from "./delete-report/delete-report.component";
import { MatDialog } from "@angular/material/dialog";
import { DateField } from "./date-field";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { domain as qsDomain } from "@rezonence/bi-report-stack";
import { domain as liveDomain } from "@rezonence/live-report";

@Component({
  selector: "report-detail",
  templateUrl: "./ReportDetailComponent.html",
  styleUrls: ["./ReportDetailComponent.css"]
})
export class ReportDetailComponent implements OnInit, OnDestroy {
  Object = Object;
  ConfigType = ConfigType;
  DateField = DateField;
  ReportType = ReportType;
  Currency = Currency;

  timestamp: number;
  reportLink: string;
  impressionsLink: string;
  eocLink: string;
  trackersLink: string;
  subscriptions: Subscription[] = [];
  checkboxFields: (string & keyof Report)[] = ["answerBreakdown", "displayRevenue", "statusReport", "displayClicks"];
  // We have been recording FreeWall data in a suitable format for extraction since 2017-08-01
  minTimestamp: number = new Date("2017-08-01").getTime();
  canWrite$: Observable<boolean>;
  report$ = new ReplaySubject<Report>(1);

  @Input()
  isReportTypeSelectable: boolean;

  @Output()
  reportDeleted: EventEmitter<Report> = new EventEmitter<Report>(false);

  @Input()
  set report(report: Report) {
    this.report$.next(report);
  }

  constructor(public reportService: ReportService,
    public dialog: MatDialog) {
    this.canWrite$ = reportService.canWrite$;
  }

  async ngOnInit() {
    this.timestamp = new Date().getTime();
    this.subscriptions.push(this.report$.pipe(tap(report => {
      this.reportLink = report.reportType === ReportType.article
        ? `http://articles.rezonence.com/${report.id}`
        : `https://${liveDomain}?${reportIdQueryParam}=${report.id}`;
      this.trackersLink = `http://trackers.rezonence.com?${reportIdQueryParam}=${report.id}`;
      this.impressionsLink = `https://${liveDomain}?${reportIdQueryParam}=${report.id}&${showImpressionsQueryParam}=true`;
      this.eocLink = `https://${qsDomain}?d=353df4a2-e711-415c-af91-a5d98606b5e8&c=${report.id}`
    })).subscribe());
  }

  async saveReport(report: Report) {
    await this.reportService.saveReport(report);
  }

  deleteReport(report: Report) {
    const ref = this.dialog.open(DeleteReportComponent);
    ref.componentInstance.report = report;
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.reportDeleted.emit(report);
      }
    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
