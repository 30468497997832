 <mat-vertical-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="hostnameFormGroup">
      <form [formGroup]="hostnameFormGroup">
        <ng-template matStepLabel>Site</ng-template>
        <mat-form-field>
          <input matInput placeholder="Site" formControlName="hostnameInput" required>
          <ng-container *ngIf="hostnameFormGroup.controls['hostnameInput'].errors as errors">
            <mat-error *ngIf="errors['minlength']">Must be at least 5 characters long</mat-error>
            <mat-error *ngIf="errors['pattern']">Must not contain invalid characters</mat-error>
          </ng-container>
        </mat-form-field>
      </form>
    </mat-step>
    <mat-step [stepControl]="htmlSelectorFormGroup">
      <form [formGroup]="htmlSelectorFormGroup">
        <ng-template matStepLabel>CSS paragraph selector</ng-template>
        <mat-form-field>
          <input matInput placeholder="CSS paragraph selector" formControlName="htmlSelectorInput" required>
          <ng-container *ngIf="htmlSelectorFormGroup.controls['htmlSelectorInput'].errors as errors">
            <mat-error *ngIf="errors['minlength']">Must be at least 5 characters long</mat-error>
          </ng-container>
        </mat-form-field>
      </form>
    </mat-step>
    <mat-step [stepControl]="networkIdFormGroup">
      <form [formGroup]="networkIdFormGroup">
        <ng-template matStepLabel>Network id</ng-template>
        <mat-form-field>
          <mat-select formControlName="networkIdInput" placeholder="Network Id" required>
            <mat-option matInput *ngFor="let networkId of networkIds | async" [value]="networkId">
              {{networkId}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div>
        <button mat-button (click)="save()">Create</button>
      </div>
    </mat-step>

  </mat-vertical-stepper>
