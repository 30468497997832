import {NodeFactory} from "./node.factory";
import {Report} from "@rezonence/core";
import {ReportGroup} from "./report.group";

export class CampaignNodeFactory extends NodeFactory {

  split(reports: Report[]): ReportGroup[] {

    const campaignsMap: { [campaign: string]: Report[] } = {};
    const otherReports: Report[] = [];

    for (const report of reports) {
      const campaigns = this.getCampaigns(report);
      if (campaigns.length === 1) {

        let categoryReports = campaignsMap[campaigns[0]];

        if (!categoryReports) {

          categoryReports = [];
          campaignsMap[campaigns[0]] = categoryReports;
        }

        categoryReports.push(report);

      } else {

        otherReports.push(report);
      }
    }

    return Object.keys(campaignsMap).map(campaign => ({
        title: this.getTitle(campaign),
        reports: campaignsMap[campaign]
      })).concat(otherReports.map(report => ({
        title: report.title,
        reports: [report]
      })));

  }

  getTitle(campaign: string): string {
    return campaign.split("/").join(" ");
  }

  getCampaigns(report: Report): string[] {

    return (report.adIds || [])
      .map(adId => this.getCampaign(adId))
      .filter((campaign, index, arr) => campaign && arr.indexOf(campaign) === index);

  }

  getCampaign(adId: string): string {

    const idParts = adId.split("/");

    if (idParts.length >= 2) {

      return idParts[0] + "/" + idParts[1];

    } else {

      return "";
    }

  }

}
