import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ReportService} from "./services/report.service";
import {TitleService} from "../core/title.service";
import startCase from "lodash/startCase";
import { concatMap, map, Observable } from "rxjs";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "report-group",
  templateUrl: "./reports.html",
  styleUrls: ["./reports.css"]
})
export class ReportGroupComponent implements OnInit {

  loaded$: Observable<boolean>;

  @Input()
  reportType: string;

  constructor(private reportService: ReportService,
              private titleService: TitleService) {
    this.loaded$ = this.reportService.canWrite$.pipe(
      concatMap(() => this.reportService.listReports()),
      map(() => true)
    )
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => startCase(txt));
  }

  ngOnInit(): any {

    const title = this.toTitleCase(this.reportType) + " reports";
    this.titleService.title = title;
  }

}
