import {KeyValueStore} from "./key.value.store";
import {KeyValue} from "./key.value";
import {Optional} from "@rezonence/sdk";
import {KvStorageEvent} from "./kv.storage.event";
import {InMemoryPubSub} from "@repeaterjs/pubsub";
import {defaultTopic} from "./default.topic";

export abstract class AbstractKeyValueStore<K, V> implements KeyValueStore<K, V> {

    protected constructor(protected topic = defaultTopic) {
    }

    abstract read(key: K): Promise<Optional<V>>;
    protected eventBus: InMemoryPubSub<KvStorageEvent<K, V>> = new InMemoryPubSub();

    toStorageEvent(oldValue: Optional<V>, request: KeyValue<K, V>): KvStorageEvent<K, V> {
        return {
            key: request.key,
            oldValue,
            newValue: Optional.of(request.value)
        };
    }

    async write(request: KeyValue<K, V>): Promise<void> {
        const oldValue = await this.read(request.key);
        await this.writeValue(request);
        const storageEvent = this.toStorageEvent(oldValue, request);
        this.eventBus.publish(this.topic, storageEvent);
    }

    abstract writeValue(request: KeyValue<K, V>): Promise<void>;

    abstract list(): AsyncIterable<K>;

    async* listEntries(): AsyncIterable<KeyValue<K, V>> {
        for await (const key of this.list()) {
            const optionalValue = await this.read(key);
            if (optionalValue.exists) {
                yield {
                    key,
                    value: optionalValue.item
                };
            }
        }
    }

    listenToChanges(): AsyncIterable<KvStorageEvent<K, V>> {
        return this.eventBus.subscribe(this.topic);
    }

}
