import {Injectable} from "@angular/core";
import {LayoutDefinition} from "./layout.definition";
import {PublisherConfigType, EditableType} from "@rezonence/publisher-config-dao";
import {LayoutElement} from "./layout.element";
import {FieldSelection} from "./field.selection";
import {FormElement} from "./form.element";
import {Schema} from "jsonschema";

@Injectable({
  providedIn: "root"
})
export class FormService {

  advancedFields: Partial<Record<PublisherConfigType, FieldSelection<EditableType>>>;
  fieldsToExclude: Partial<Record<PublisherConfigType, FieldSelection<EditableType>>>;
  customFormDefs: Partial<Record<PublisherConfigType,
    Partial<Record<Extract<keyof EditableType, string>, FormElement<EditableType>>>>>;

  constructor() {
    this.advancedFields = {
      [PublisherConfigType.Doubleserve]: [
        "version",
        "singleAd",
        "safetyTimeout",
        "adSlotId",
        "test"
      ] as FieldSelection<EditableType>,
      [PublisherConfigType.PublisherConfig]: [
        "version",
        "rzl",
        "cke",
        "dom",
        "stk",
        "pix",
        "htm",
        "pid",
        "nid",
        "placeholders",
        "adKillerSelectors"
      ] as FieldSelection<EditableType>,
    };

    this.fieldsToExclude = {
      [PublisherConfigType.Doubleserve]: [
        "version",
        // TODO Re-enable this once we have a working custom widget for this field
        "targetingParameter"
      ] as FieldSelection<EditableType>,
      [PublisherConfigType.PublisherConfig]: [
        "version"
      ] as FieldSelection<EditableType>,
    };

    this.customFormDefs = {
      [PublisherConfigType.Doubleserve]: {},
      [PublisherConfigType.PublisherConfig]: {},
    };
  }

  isHidden<T>(field: LayoutElement<T>, hiddenFields: FieldSelection<T>): boolean {
    const fieldKey = typeof field === "string" ? field : field.key || "";
    return hiddenFields.includes(fieldKey as Extract<keyof T, string>);
  }

  getLayout<T>(configType: PublisherConfigType, schema: Schema, advanced: boolean):
    LayoutDefinition<T> {
    const excludeFields = this.fieldsToExclude[configType];
    const hiddenFields = advanced ? excludeFields : this.advancedFields[configType].concat(excludeFields);
    const customLayout = this.customFormDefs[configType];

    // Can't use this approach until this is fixed: https://github.com/hamzahamidi/ajsf/issues/20
    /*    return Object.keys(configAndSchema.schema.properties).filter(element => {
          return advanced || !this.isHidden(element, hiddenFields);
        }).map(key => {
          return customLayout[key] || {key};
        });*/

    // TODO Remove this and use the commented method above once
    //  https://github.com/hamzahamidi/ajsf/issues/20 is fixed
    const schemaProperties = schema.properties;
    for (const propertyName of Object.keys(schemaProperties)) {
      const propertyDef = schemaProperties[propertyName];

      if (propertyName === "targetingParameter") {
        // Remove the incompatible fields
        delete schemaProperties[propertyName];
      } else if (propertyName === "version") {
        // Move the version field to the bottom
        const versionSchema = schemaProperties[propertyName];
        delete schemaProperties[propertyName];
        schemaProperties[propertyName] = versionSchema;
      } else if ((propertyDef as Schema).type === "array") {
        // Prevent ajsf from inserting values into empty arrays
        (propertyDef as any).listItems = 0;
      }

      if (this.isHidden(propertyName as LayoutElement<EditableType>, hiddenFields)) {

      }
    }

    return ["*"];

  }

}
