<mat-card>
  <div class="header">
    <h4 class="configId">{{entry.key}}</h4>
    <button class="removeEntry" mat-icon-button (click)="remove.emit(entry)"><mat-icon color="primary">close</mat-icon></button>
  </div>
  <mat-checkbox [(ngModel)]="entry.value.cap.ovr" [name]="'Override'">Overwrite publisher capping settings</mat-checkbox>
  <mat-card-content *ngFor="let cap of requiredCaps">
    <form>
      <h5>{{CapTitle[cap]}}</h5>
      <mat-form-field>
        <input matInput [disabled]="!entry.value.cap.ovr" placeholder="Number per period" required value="DefaultCap[cap].num" type="number"
               [(ngModel)]="entry.value.cap[cap].num" class="creative-cap-number-input" [name]="CapTitle[cap] + 'Number'">
      </mat-form-field>
      <mat-form-field>
        <input matInput [disabled]="!entry.value.cap.ovr" placeholder="Period in seconds" required value="DefaultCap[cap].prd" type="number"
               [(ngModel)]="entry.value.cap[cap].prd" class="creative-cap-period-input" [name]="CapTitle[cap] + 'Period'">
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>

