import { DateRangeOptions, Grouping, Order } from "@rezonence/date-range";
import { resolveCurrentTimeZone } from "./resolveCurrentTimeZone";

export function resolveDefaultDateRangeOptions(): DateRangeOptions {
  return {
    timeZone: resolveCurrentTimeZone(),
    order: Order.Ascending,
    groupBy: Grouping.None
  };
}
