import { Injectable } from "@angular/core";
import { TemplateService } from "@rezonence/core/freewall-compiler/template.service";
import { CampaignFileService } from "./campaign.file.service";
import { CampaignTemplateResolver } from "./CampaignTemplateResolver";
import { S3File } from "@jabrythehutt/fs-s3";

@Injectable({
  providedIn: "root"
})
export class CampaignTemplateService extends TemplateService<S3File> {

  constructor(fileService: CampaignFileService, templateResolver: CampaignTemplateResolver) {
    super(fileService, templateResolver as any);
  }
}
