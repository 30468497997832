import {Report} from "./Report";
import {ReportDB} from "./ReportDB";
import {ReportType} from "./ReportType";
import type DynamoDB from "aws-sdk/clients/dynamodb";
import {DefaultReport} from "./DefaultReport";
import {ReportTable} from "./ReportTable";
import { defaultReportDomain } from "./defaultReportDomain";

export class DynamoReportDB implements ReportDB {

    tableName: string;
  domainId: string;

  constructor(private db: DynamoDB.DocumentClient, domainId?: string, tableName?: string,) {
        this.tableName = tableName || ReportTable.Reports;
    this.domainId = domainId || defaultReportDomain;
    }

    readDB<T>(db: DynamoDB.DocumentClient, query, items?: T[], recursionCount?: number): Promise<T[]> {

        return db.query(query).promise().then(data => {

            items = items || [];

            recursionCount = recursionCount || 1;

            data.Items.forEach(item => {

                items.push(item as T);

            });

            if (data.LastEvaluatedKey) {

                query.ExclusiveStartKey = data.LastEvaluatedKey;

                return this.readDB(db, query, items, recursionCount + 1);

            } else {

                if (recursionCount > 1) {
                    console.log(recursionCount, " pages of results read");
                }
                return items;

            }

        });
    }

    findReports(reportType?: string): Promise<Report[]> {

        console.log("Retrieving reports for domain id =", this.domainId);

        return this.readDB(this.db, {
            TableName: this.tableName,
            IndexName: "domainId-index",
            KeyConditionExpression: "domainId = :domainId",
            ExpressionAttributeValues: {
                ":domainId": this.domainId
            }
        }).then(items => {

            return items.map(item => {

                return this.convertItem(item);

            }).filter(report => {

                return reportType ? report.reportType === reportType : true;

            });
        });

    }

    convertItem(item): Report {

      const report = new DefaultReport(item.domainId, item.title, item.reportId || item.campaignId);

        Object.assign(report, item);

      report.adIds = this.convertSet(item.adIds);

      report.pubIds = this.convertSet(item.pubIds);

      report.networkIds = this.convertSet(item.networkIds);

      report.configIds = this.convertSet(item.configIds);

      report.campaignIds = this.convertSet(item.campaignIds);

        report.adjustment = report.adjustment || 0;

        if (!report.reportType) {

            report.reportType = ReportType.agency;

            if (report.displayECPM) {
                report.reportType = ReportType.publisher;
            }

        }

        return report;

    }

    convertSet(inputSet): string[] {
        if (inputSet) {
            return inputSet.values;
        }

        return [];
    }

    findReport(reportId: string): Promise<Report> {

        return this.db.query({
            TableName: this.tableName,
            KeyConditionExpression: "reportId = :reportId",
            ExpressionAttributeValues: {
                ":reportId": reportId
            }
        }).promise().then(data => {

          const reports = data.Items.map(item => {
                return this.convertItem(item);
            }) as Report[];

            return reports[0];

        });
    }

    convertToSet(item, fieldName: string, array: string[]) {

        if (array && array.length) {
            item[fieldName] = this.db.createSet(array, {});
        } else {

            delete item[fieldName];
        }

        return item;

    }

    convertToItem(report: Report) {
        let item = Object.assign({}, report) as any;

        item.reportId = report.id;
      delete item.id;

        item = this.convertToSet(item, "adIds", report.adIds);
        item = this.convertToSet(item, "pubIds", report.pubIds);
        item = this.convertToSet(item, "networkIds", report.networkIds);
        item = this.convertToSet(item, "configIds", report.configIds);
        item = this.convertToSet(item, "campaignIds", report.campaignIds);

        if (!report.reportType) {

            item.reportType = ReportType.agency;

            if (report.displayECPM) {
                item.reportType = ReportType.publisher;
            }

        }

        return item;
    }

    saveReport(report: Report): Promise<Report> {

        const item = this.convertToItem(report);

        return this.db.put({TableName: this.tableName, Item: item}).promise().then(data => {

            return report;
        });

    }

    deleteReport(reportId: string): Promise<any> {

        return this.db.delete({TableName: this.tableName, Key: {reportId}})
            .promise().then(data => {
                return reportId;
            });

    }

}
