import { DemoItem } from "./table";
import { Component, Input } from "@angular/core";

@Component({
  selector: "demo-preview",
  template: `
      <div *ngIf="item.record | demoLink as demoUrl" class="preview">
        <a href="{{demoUrl}}" target="_blank">{{item.title}}</a>
        <ng-container *ngIf="item.record | adId | freewallConfig | async as optionalConfig">
          <ng-container *ngIf="optionalConfig.item as fwConfig">
            <a href="{{demoUrl}}" target="_blank">
              <div class="banner" style.backgroundImage="url('{{fwConfig | banner}}')"></div>
            </a>
          </ng-container>
        </ng-container>
      </div>
  `,
  styles: [
    `
      .preview {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .banner {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: black;
        width: 400px;
        height: 225px;
      }
    `
  ]
})
export class DemoPreviewComponent {

  @Input()
  item: DemoItem;

}
