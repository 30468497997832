import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ReportService} from "../../reports/services";
import {SaveService} from "../services/save.service";
import { PublisherConfigType, configFileNameByType, rootPublisherConfigId } from "@rezonence/publisher-config-dao";
import {VersionService} from "../services/version.service";
import {SchemaService} from "../services/schema.service";
import {Router} from "@angular/router";
import {FreewallService} from "../services/FreewallService";
import {ConfigIdValidatorService} from "../config-id-validator.service";
import {CampaignFileService} from "../../core/campaign.file.service";
import {environment as awsEnv} from "@rezonence/core/aws/environment";
import { DestinationPrefix, R3ZConfig, Optional } from "@rezonence/sdk";
import defaultJson from "json-schema-defaults";
import { PublisherConfig } from "@rezonence/core/config-extractor/publisher/publisher.config";
import {AnyFile, WriteOptions} from "@djabry/fs-s3";
import { ConfigType } from "@rezonence/core/report/ConfigType";
import { PublisherConfigMappingService } from "../../reports/services/publisher.config.mapping.service";
import {MatDialogRef} from "@angular/material/dialog";
import {Schema} from "jsonschema";

@Component({
  selector: "app-config-wizard",
  templateUrl: "./config-wizard.component.html",
  styleUrls: ["./config-wizard.component.css"]
})
export class ConfigWizardComponent implements OnInit {
  isLinear = true;
  hostnameFormGroup: FormGroup;
  htmlSelectorFormGroup: FormGroup;
  networkIdFormGroup: FormGroup;
  networkIds: Promise<string[]>;
  hostnameInput: string;
  htmlSelectorInput: string;
  networkIdInput: string;
  savePromise: Promise<void>;

  constructor(public ref: MatDialogRef<ConfigWizardComponent>,
              private formBuilder: FormBuilder,
              private reportService: ReportService,
              private versionService: VersionService,
              private saveService: SaveService,
              private schemaService: SchemaService,
              private router: Router,
              private freewallService: FreewallService,
              private fileService: CampaignFileService,
              private configIdValidator: ConfigIdValidatorService,
              private mappingService: PublisherConfigMappingService) { }

  ngOnInit() {
    this.hostnameFormGroup = this.formBuilder.group({
      hostnameInput: ["", [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern("[^ |<>#%\"]+")
      ],
        this.configIdValidator.validateConfigId.bind(this.configIdValidator)]
    });
    this.htmlSelectorFormGroup = this.formBuilder.group({
      htmlSelectorInput: ["", [Validators.required, Validators.minLength(5)]]
    });
    this.networkIdFormGroup = this.formBuilder.group({
      networkIdInput: ["", Validators.required]
    });
    this.networkIds = this.getNetworkIdList();
  }

  async getNetworkIdList(): Promise<string[]> {
    return await this.reportService.listNetworkIds();
  }

  async getConfigVersion(): Promise<string> {
    const versionList = await this.versionService.listVersions();
    return versionList[0];
  }

  async saveConfigs(configObject: Partial<PublisherConfig>): Promise<void> {
    const configId = this.hostnameFormGroup.value.hostnameInput;
    await this.mappingService.save([{
        configId,
        parentConfigId: rootPublisherConfigId,
        lastModified: new Date().toISOString()
      }]);
    for (const configType of Object.values(PublisherConfigType)) {
      const config = configType === PublisherConfigType.Doubleserve
        ? await this.createDoubleserveConfig(configType, configObject)
        : configObject as R3ZConfig;
      const destinationKey =
        `${DestinationPrefix.Pub}/${configId}/${configFileNameByType[configType]}`;
      const writeOptions = {overwrite: true, skipSame: true, makePublic: true};
      const destination = {key: destinationKey, bucket: awsEnv.COMPILE_BUCKET};
      if (configType === PublisherConfigType.Css) {
        await this.saveCssConfig(config, destination, writeOptions);
      } else if (configType === PublisherConfigType.PublisherConfig || configType === PublisherConfigType.Doubleserve) {
        await this.saveDoubleserveAndPublisherConfigs(config, destination, writeOptions);
      }
    }
  }

  async createDoubleserveConfig(configType: PublisherConfigType.Doubleserve,
                                configObject: Partial<PublisherConfig>): Promise<R3ZConfig> {
    return this.createNewConfig<R3ZConfig>((await this.schemaService.getSchema(configType,
      configObject.version)).item, configObject.version);
  }

  async saveCssConfig(config: R3ZConfig, destination: AnyFile, options: WriteOptions) {
    const sourceFile = this.saveService.toTemplateFile(Optional.of(config.version),
      configFileNameByType.css);
    await this.fileService.copy(sourceFile.item, destination, options);
  }

  async saveDoubleserveAndPublisherConfigs(config: R3ZConfig, destination: AnyFile, options: WriteOptions) {
    await this.fileService.write(JSON.stringify(config, null, 4), destination, options);
  }

  async buildConfigObject(): Promise<Partial<PublisherConfig>> {
    const version = await this.getConfigVersion();
    const schemaOptional = await this.schemaService.getSchema(PublisherConfigType.PublisherConfig, version);
    const object = schemaOptional.exists ? this.createNewConfig(schemaOptional.item, version) : {version};
    return {
      ...object,
      selectors: [this.htmlSelectorFormGroup.value.htmlSelectorInput],
      nid: this.networkIdFormGroup.value.networkIdInput
    };
  }

  createNewConfig<T extends R3ZConfig>(schema: Schema, version: string): T {
    const object = defaultJson(schema) as T;
    object.version = version;
    return object;
  }

  async save() {
    this.ref.disableClose = true;
    try {
      const configObject = await this.buildConfigObject();
      this.savePromise = this.saveConfigs(configObject);
      await this.savePromise;
      const configId = this.hostnameFormGroup.value.hostnameInput;
      await this.reportService.clearConfigsCache(ConfigType.ConfigId);
      await this.router.navigate(["config"], {queryParams: {configId}});
      this.ref.close();
    } finally {
      this.ref.disableClose = false;
    }

  }

}
