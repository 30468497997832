export enum RealtimeLogColumn {
   Timestamp = "timestamp",
   Ip = "ip",
   TimeToFirstByte = "time_to_first_byte",
   Status = "status",
   Bytes = "bytes",
   Method = "method",
   Host = "host",
   UriStem = "uri_stem",
   RequestBytes = "request_bytes",
   EdgeLocation = "edge_location",
   EdgeRequestId = "edge_request_id",
   HostHeader = "host_header",
   TimeTaken = "time_taken",
   ProtocolVersion = "protocol_version",
   IpVersion = "ip_version",
   UserAgent = "user_agent",
   Referer = "referer",
   Cookie = "cookie",
   UriQuery = "uri_query",
   EdgeResponseResultType = "edge_response_result_type",
   ForwardedFor = "forwarded_for",
   SslProtocol = "ssl_protocol",
   SslCipher = "ssl_cipher",
   EdgeResultType = "edge_result_type",
   FleEncryptedFields = "fle_encrypted_fields",
   FleStatus = "fle_status",
   ContentType = "content_type",
   ContentLen = "content_len",
   RangeStart = "range_start",
   RangeEnd = "range_end",
   Port = "port",
   EdgeDetailedResultType = "edge_detailed_result_type",
   Country = "country",
   AcceptEncoding = "accept_encoding",
   Accept = "accept",
   CacheBehaviorPathPattern = "cache_behavior_path_pattern",
   Headers = "headers",
   HeaderNames = "header_names",
   HeadersCount = "headers_count"
}