import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ReportGroupComponent } from "./report.group";
import { ReportItemComponent } from "./report.item";
import { ReportDetailComponent } from "./ReportDetailComponent";
import { ReportListComponent } from "./report.list";
import { ReportService } from "./services";
import { BrowserModule } from "@angular/platform-browser";
import { CoreModule } from "../core";
import { ReportEditorComponent } from "./report.editor";
import { PublisherReportsComponent } from "./publisher.reports";
import { AgencyReportsComponent } from "./agency.reports";
import { ProgressBarsComponent } from "./progress.bars";
import { RouterModule, Routes } from "@angular/router";
import { ReportsComponent } from "./reports.component";
import { ReportTreeService } from "./tree/tree.service";
import { ReportNodeComponent } from "./report.node.component";
import { SearchService } from "./services/search.service";
import { CreateReportComponent } from "./create-report/create-report.component";
import { DeleteReportComponent } from "./delete-report/delete-report.component";
import { ReportDataCache } from "./services/report.data.cache";
import { ConfigListCache } from "./services/config.list.cache";
import { PublisherConfigMappingService } from "./services/publisher.config.mapping.service";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSelectModule } from "@angular/material/select";
import { MsToDayJsPipe } from "./MsToDayJsPipe";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgSelectModule } from "@ng-select/ng-select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipsModule } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ConfigMultiselectorComponent } from "./config-multiselector/config-multiselector.component";
import { DecamelisePipe } from "./decamelise.pipe";
import { ReportDatepickerComponent } from "./report-datepicker/report-datepicker.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RemoveDisplayPrefixPipe } from "./remove-display-prefix.pipe";
import { ReportsRoutePath } from "./ReportsRoutePath";
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes: Routes = [
  {
    path: "report/:id",
    component: ReportEditorComponent
  },
  {
    path: ReportsRoutePath.Reports,
    component: ReportsComponent,
    children: [
      {
        path: "",
        redirectTo: "agency",
        pathMatch: "full"
      },
      {
        path: "agency",
        component: AgencyReportsComponent
      },
      {
        path: "publisher",
        component: PublisherReportsComponent
      }
    ]
  },
];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    CoreModule,
    ProgressbarModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTabsModule,
    AccordionModule.forRoot(),
    MatInputModule,
    MatSnackBarModule,
    RouterModule.forChild(routes),
    MatTooltipModule,
    MatStepperModule,
    MatSelectModule,
    NgxDaterangepickerMd.forRoot(),
    NgSelectModule,
    MatDatepickerModule,
    MatChipsModule,
    MatAutocompleteModule,
    FlexLayoutModule
  ],
  declarations: [
    ReportNodeComponent,
    ReportsComponent,
    ProgressBarsComponent,
    ReportEditorComponent,
    ReportGroupComponent,
    ReportItemComponent,
    ReportDetailComponent,
    ReportListComponent,
    PublisherReportsComponent,
    AgencyReportsComponent,
    CreateReportComponent,
    DeleteReportComponent,
    MsToDayJsPipe,
    ConfigMultiselectorComponent,
    DecamelisePipe,
    ReportDatepickerComponent,
    RemoveDisplayPrefixPipe
  ],
  providers: [
    ConfigListCache,
    ReportDataCache,
    SearchService,
    ReportTreeService,
    ReportService,
    PublisherConfigMappingService,
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      }
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: "en-GB"
    }
  ],
  exports: [
    PublisherReportsComponent,
    AgencyReportsComponent,
    ConfigMultiselectorComponent
  ]
})
export class ReportModule {

}
