
import { FreeWallDBService } from "../../core";
import { collect } from "@rezonence/array-utils";
import { SubjectMap, fromSubjectMap, createBehaviorSubjectMap } from '@rezonence/rxjs'
import { Component, Input, OnDestroy, AfterViewInit, ViewChild, OnInit } from "@angular/core";
import { DatasetItem, Dataset, Meta } from "@rezonence/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { DemoUserDetailsDao } from "../DemoUserDetailsDao";
import { Observable, ReplaySubject, shareReplay, Subject, switchMap, Subscription, combineLatest, map, debounceTime, distinctUntilChanged, BehaviorSubject } from "rxjs";
import { TableColumn } from "./TableColumn";
import { companyDomains } from "@rezonence/freewall-creator-config";
import { DemoItem } from "./DemoItem";
import { FilterRequest } from "./FilterRequest";
import isEqual from "lodash/isEqual";

@Component({
  selector: "demos-table",
  template: `
  <div class="demos-table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="{{TableColumn.Date}}" matSortDirection="desc" matSortDisableClear>
    <ng-container [matColumnDef]="TableColumn.Date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">Date</th>
        <td mat-cell *matCellDef="let item">
          {{item.date | date : 'medium'}}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="TableColumn.Email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email">Email</th>
        <td mat-cell *matCellDef="let item">
          {{item.email}}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="TableColumn.Title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title">Demo</th>
        <td mat-cell *matCellDef="let item">
          <demo-preview [item]="item"></demo-preview>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
    </table>
</div>
  `,
  styles: [
    `
      .demos-table-container table {
        width: 100%;
      }
    `
  ]
})
export class DemosTableComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(MatSort)
  sort: MatSort;

  @Input()
  set externalOnly(external: boolean) {
    this.filterInputs.external.next(external);
  }

  @Input()
  set filter(input: string) {
    this.filterInputs.filter.next(input);
  }

  readonly subscriptions: Subscription[] = [];
  readonly dataSource = new MatTableDataSource();
  readonly TableColumn = TableColumn;
  readonly displayColumns = Object.values(TableColumn);
  readonly dateRange$: Subject<[Date, Date]> = new ReplaySubject(1);
  readonly filterInputs: SubjectMap<FilterRequest> = createBehaviorSubjectMap({
    filter: '',
    external: false
  })


  readonly filterPredicates: Array<(item: DemoItem, request: FilterRequest) => boolean> = [
    (item, _) => this.includesEmail(item),
    (item, request) => !request.external || this.isExternal(item.email),
    (item, request) => !request.filter || this.includesFilterExpression(item, request.filter)
  ]

  readonly data$: Observable<DemoItem[]> = this.dateRange$.pipe(
    switchMap(dateRange => collect(this.freeWallDb.listAllItems<Meta>(Dataset.Meta, dateRange))),
    switchMap(items => Promise.all(items.map(item => this.toDemoItem(item)))),
    shareReplay(1)
  );

  readonly filterRequest$ = fromSubjectMap(this.filterInputs).pipe(
    debounceTime(300),
    distinctUntilChanged((v1, v2) => isEqual(v1, v2))
  );

  readonly filteredData$: Observable<DemoItem[]> = combineLatest([this.data$, this.filterRequest$]).pipe(
    map(([data, request]) => data.filter(item => this.display(item, request))),
  )

  constructor(
    private freeWallDb: FreeWallDBService,
    private userDetailsDao: DemoUserDetailsDao
  ) { }

  @Input()
  set dateRange(range: [Date, Date]) {
    this.dateRange$.next(range);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  includesEmail(item: DemoItem): boolean {
    return !!item.email;
  }

  isExternal(email: string): boolean {
    return !companyDomains.find(domain => email.endsWith(`@${domain}`))
  }

  includesFilterExpression(item: DemoItem, filter: string): boolean {
    const values = Object.values(TableColumn).map(column => item[column].toLowerCase());
    return !!values.find(v => v.includes(filter.toLowerCase()));
  }

  display(item: DemoItem, request: FilterRequest): boolean {
    return this.filterPredicates.every(p => p(item, request));
  }

  ngOnInit() {
    this.subscriptions.push(this.filteredData$.subscribe(data => this.dataSource.data = data));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  async toDemoItem(record: DatasetItem<Meta>): Promise<DemoItem> {
    return {
      email: (await this.userDetailsDao.resolveEmail(record.identityId)) || "",
      date: (new Date(record.modified)).toISOString(),
      title: record.value.title,
      record
    }
  }

}
