import { Injectable } from '@angular/core'
import { ConfigResolver } from '@rezonence/sdk'
import { ConfigDownloaderService } from '../freewalls/services/config-downloader.service'
import { LinkMacroRendererService } from '../freewalls/services/link-macro-renderer.service'

@Injectable({
  providedIn: 'root'
})
export class ConfigResolverService extends ConfigResolver {
  constructor (configDownloader: ConfigDownloaderService, macroRenderer: LinkMacroRendererService) {
    super(configDownloader, macroRenderer)
  }
}
