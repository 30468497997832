import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from "@angular/core";

@Component({
  selector: "no-edit",
  templateUrl: "./no-edit.component.html",
  styleUrls: ["./no-edit.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class NoEditComponent implements OnInit {

  @Output()
  isValid: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.isValid.emit(false);
  }

}
