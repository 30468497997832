import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, filter, Observable, of, shareReplay, tap } from "rxjs";
import { Route } from "../Route";
import { ImportFreeWallService } from "./ImportFreeWallService";

@Component({
  templateUrl: './ImportFreeWallPage.html',
  providers: [ImportFreeWallService]
})
export class ImportFreeWallPage {

  readonly adId$: Observable<string> = this.importer.importedAdId$.pipe(
    tap(() => this.router.navigateByUrl(Route.FreeWalls))
  )

  readonly error$: Observable<Error> = this.adId$.pipe(
    catchError((thrown) => of(thrown)),
    filter((thrown) => thrown instanceof Error)
  );

  constructor(private importer: ImportFreeWallService, private router: Router) { }
}
