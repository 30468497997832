import {FreewallFilenamePrefix} from "@rezonence/sdk";
import {TemplateFileSuffix} from "@rezonence/core";
import {schemaFileName} from "./schema.file.name";

export const doubleserveBase = "doubleserve";
export const publisherConfigFile = {
    doubleserveJs: `${doubleserveBase}.js`,
    doubleserveSchema: schemaFileName.doubleserveConfig,
    doubleserveConfig: `${doubleserveBase}.json`,
    publisherConfigSchema: schemaFileName.publisherConfig,
    publisherConfigJs: FreewallFilenamePrefix.Pb + TemplateFileSuffix.Code,
    publisherConfigJson: FreewallFilenamePrefix.Pb + TemplateFileSuffix.Conf,
    publisherFormat: FreewallFilenamePrefix.Pb + TemplateFileSuffix.FormatCss,
    customCode: FreewallFilenamePrefix.Pb + TemplateFileSuffix.Extension,
    sites: "sites.json"
};
