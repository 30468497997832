<p mat-dialog-title>
  Create a tag
</p>
<mat-dialog-content>
  <mat-form-field>
    <mat-select placeholder="Tag type" [(ngModel)]="tagType" required>
      <mat-option *ngFor="let t of tagTypes" [value]="t">{{t | titlecase}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-content *ngIf="tagType === TagType.Live">
  <mat-form-field>
    <mat-label>Campaign ID</mat-label>
    <input matInput placeholder="REZXXXXXX" [(ngModel)]="campaignId" required>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-content>
  <mat-form-field>
    <mat-select placeholder="Ad server" [(ngModel)]="adServer">
      <mat-option *ngFor="let serverType of adServers" [value]="serverType">{{serverType}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-content class="in-app-checkbox">
  <mat-checkbox [(ngModel)]="inApp">
    In app
  </mat-checkbox>
</mat-dialog-content>
<mat-dialog-content>
  <mat-checkbox [(ngModel)]="profiles">
    Integrate with YouGov Profiles
  </mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn-primary" (click)="download()" [disabled]="!tagType">
    Download
  </button>
</mat-dialog-actions>
