import { Pipe, PipeTransform } from "@angular/core";
import { DatasetItem, Meta, Dataset } from "@rezonence/core";
import { DashboardRequestService } from "./DashboardRequestService";

@Pipe({
    name: "adId"
})
export class AdIdPipe implements PipeTransform {

    constructor(private requestService: DashboardRequestService) {
    }

    transform(record: DatasetItem<Meta>): string {
        return this.requestService.toAdId({
            ...record,
            recordFolder: Dataset.Freewall,
        });
    }
}
