import { Component, OnInit } from "@angular/core";

import { FreewallSearchService } from "../services/freewall-search.service";

@Component({
    selector: "freewalls-freewall-search",
    templateUrl: "./freewall-search.component.html",
    styleUrls: [ "./freewall-search.component.css" ]
  })
  export class FreewallSearchComponent {

  constructor(
    private freewallSearchService: FreewallSearchService) {}

  set searchTerm(text: string) {
    this.freewallSearchService.setSearch(text);
  }

  get searchTerm(): string {
    return this.freewallSearchService.getSearch();
  }

}
