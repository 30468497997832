import {Component, Input, OnDestroy, ViewEncapsulation} from "@angular/core";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "spinner",
  styleUrls: ["./spinner.component.css"],
  templateUrl: "./spinner.component.html"
})
export class SpinnerComponent implements OnDestroy {

  @Input()
  public delay = 300;

  public delayRunning = false;
  private currentTimeout: number;

  @Input()
  public set isRunning(value: boolean) {
    if (!value) {
      this.cancelTimeout();
      this.delayRunning = false;
      return;
    }

    if (this.currentTimeout) {
      return;
    }

    setTimeout(() => {
      this.delayRunning = value;
      this.cancelTimeout();
    }, this.delay);
  }

  ngOnDestroy(): any {
    this.cancelTimeout();
  }

  private cancelTimeout(): void {
    clearTimeout(this.currentTimeout);
    this.currentTimeout = undefined;
  }

}
