import { ConfigType } from "@rezonence/core";
import { FreewallEventColumn } from "@rezonence/freewall-events";

export const reportFilterMapping: Record<ConfigType, FreewallEventColumn> = {
    networkId: FreewallEventColumn.Nid,
    configId: FreewallEventColumn.Cid,
    pubId: FreewallEventColumn.Pid,
    adId: FreewallEventColumn.Aid,
    campaignId: FreewallEventColumn.CampaignId
};
