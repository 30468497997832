import {DitKey} from "./dit.key";
import {DitEncoder} from "./dit.encoder";
import {keyPrefix} from "./key.prefix";
import {AbstractKeyParser} from "./abstract.key.parser";
import {apiVersion} from "./api.version";
import {Versioned} from "./versioned";

export class DitKeyParser extends AbstractKeyParser<DitKey> {

    constructor(private encoder: DitEncoder<Versioned<DitKey>>) {
        super();
    }

    isValidSuffix(suffix: string): boolean {
        return this.encoder.isValid(suffix) && this.encoder.parse(suffix).item.version === apiVersion;
    }

    encodeSuffix(input: DitKey): string {
        return this.encoder.encode({
            ...input,
            version: apiVersion
        });
    }

    parseSuffix(input: string): DitKey {
        const params = this.encoder.parse(input).item;
        return {
            date: new Date(params.date),
            type: params.type
        };
    }

    encode(key: DitKey): string {
        return keyPrefix + this.encoder.encode({...key, version: apiVersion});
    }
}
