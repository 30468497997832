<json-schema-form ngDefaultControl
                  framework="material-design"
                  [data]="data"
                  [schema]="schema"
                  [layout]="layout"
                  [options]="{ addSubmit: false }"
                  (isValid)="isValid.emit($event)"
                  (onChanges)="dataChange.emit($event)"
                  (validationErrors)="validationErrors.emit($event)">
</json-schema-form>
