import {PublisherCap} from "@rezonence/core/config-extractor/publisher/publisher.cap";
import { Duration } from "@rezonence/duration";
import {FrequencyCap} from "@rezonence/core";

export const defaultCap: PublisherCap = {
  [FrequencyCap.Unlock]: {
    num: 1,
    prd: (Duration.MsInDay * 30) / Duration.MsInSecond
  },
  [FrequencyCap.InView]: {
    num: 1,
    prd: Duration.MsInDay / Duration.MsInSecond
  },
  ovr: true
};
