import { Injectable } from "@angular/core";

import { Dataset } from "@rezonence/core/freewall/dataset";
import { Profile } from "@rezonence/core/freewall/profile";
import { ImportFreeWallParams } from "@rezonence/campaigns-config";
import { ConfigListItem } from "../config.list.item";
import { FreeWallDBService } from "../../core/FreeWallDBService";
import { FwIndexClient } from "../../core";
import { CampaignFileService } from "../../core/campaign.file.service";
import { ConfigType, compileBucket } from "@rezonence/core";
import { DestinationPrefix } from "@rezonence/sdk";
import { DeployServiceRequest } from "./deploy.service.request";
import { LambdaService } from "../../core/lambda.service";
import { DeployOperation } from "./deploy.operation";
import { functionName as deployerFunctionName } from "@rezonence/deployer-stack";
import { PublisherConfigMappingService } from "../../reports/services/publisher.config.mapping.service";
import chunk from "lodash/chunk";

@Injectable()
export class FreewallService {

  constructor(private freewallDB: FreeWallDBService,
    private fwIndex: FwIndexClient,
    private fileService: CampaignFileService,
    private publisherConfigMappingService: PublisherConfigMappingService,
    private lambdaService: LambdaService) {

  }

  async createNew(request: ImportFreeWallParams): Promise<void> {
    const folder = DestinationPrefix.Ads;
    await this.fileService.copy({ bucket: compileBucket, key: `${folder}/${request.sourceId}/` },
      { bucket: compileBucket, key: `${folder}/${request.destinationId}/` },
      { parallel: true, skipSame: true, overwrite: true, makePublic: true });
  }

  async refresh(configType: ConfigType): Promise<ConfigListItem[]> {
    if (configType === ConfigType.AdId) {
      this.fwIndex.refresh();
    }
    return this.list(configType);
  }

  async list(configType: ConfigType): Promise<ConfigListItem[]> {
    if (configType === ConfigType.ConfigId) {
      const items = await this.publisherConfigMappingService.listTopMappings();
      return items.map(item => ({
        configId: item.configId,
        lastModified: new Date(item.lastModified).getTime()
      }));
    } else {
      return this.fwIndex.listAll();
    }
  }


  async findEmail(identityId: string): Promise<string> {

    const profile = await this.freewallDB.getItem<Profile>(identityId, Dataset.Profile, "default");
    return profile.value ? profile.value.email : "";
  }

  async performDeployOperation(payloadBody: DeployServiceRequest): Promise<void> {
    await this.lambdaService.invoke(deployerFunctionName, payloadBody);

  }

  testFreeWall(adId: string): Promise<void> {
    console.log("Testing FreeWall");
    const payloadBody = {
      body: {
        adId,
        operation: DeployOperation.Test
      }
    };
    return this.performDeployOperation(payloadBody);
  }

  writeFreeWallFilesToCDN(adId: string): Promise<void> {
    console.log("Writing FreeWall files to CDN");
    const payloadBody = {
      body: {
        adId,
        operation: DeployOperation.Cdn
      }
    };
    return this.performDeployOperation(payloadBody);
  }

  async writePublisherFilesToCDN(configId: string): Promise<void> {
    console.log("Writing publisher files to CDN");
    const childMappings = await this.publisherConfigMappingService.listMappingsForConfig(configId);
    const configIdBatches = chunk([configId, ...childMappings.map(m => m.configId)], 10);
    for (const batch of configIdBatches) {
      await Promise.all(batch.map(id => {
        const payloadBody = {
          body: {
            configId: id,
            operation: DeployOperation.Cdn
          }
        };
        this.performDeployOperation(payloadBody);
      }));
    }
  }

}
