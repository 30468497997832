import {Component, OnInit} from "@angular/core";
import { CampaignFileService } from "../../core/campaign.file.service";
import {DestinationPrefix} from "@rezonence/sdk";
import {DeleteConfigRequest} from "./delete.config.request";
import { ConfigType, compileBucket } from "@rezonence/core";
import {PublisherConfigMappingService} from "../../reports/services/publisher.config.mapping.service";
import {DialogMode} from "./dialog.mode";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "delete-config-dialog",
  templateUrl: "delete-config.dialog.html",
  styleUrls: [
    "delete-config.dialog.css"
  ]
})
export class DeleteConfigDialogComponent implements OnInit {

  deleteRequest: DeleteConfigRequest;
  DialogMode = DialogMode;
  deletionMessage = "Deleting...";
  mode: DialogMode;
  errorMessage: string;

  constructor(public ref: MatDialogRef<DeleteConfigDialogComponent>,
              private fileService: CampaignFileService,
              private mappingService: PublisherConfigMappingService) {
  }

  ngOnInit(): void {
    this.mode = DialogMode.Initial;
  }

  async deleteConfig(): Promise<void> {
    this.initialiseDelete();
    if (this.deleteRequest.configType === ConfigType.AdId) {
      await this.deleteFreeWall();
    } else if (this.deleteRequest.configType === ConfigType.ConfigId) {
      await this.deletePublisherConfig();
    } else {
      this.displayError(`Deletion of config type ${this.deleteRequest.configType} not yet supported.`);
    }
    this.ref.disableClose = false;
  }

  async deleteFreeWall(): Promise<void> {
    const freeWallFolder = {
      bucket: compileBucket,
      key: `${DestinationPrefix.Ads}/${this.deleteRequest.configId}/`
    };
    try {
      await this.fileService.deleteAll(freeWallFolder);
      this.ref.close(true);
    } catch (error) {
      this.displayError(error.message);
    }
  }

  async deletePublisherConfig(): Promise<void> {
    try {
      await this.mappingService.delete([{configId: this.deleteRequest.configId}]);
      this.ref.close(true);
    } catch (error) {
      this.displayError(error.message);
    }
  }

  cancel(): void {
    this.ref.close();
  }

  private initialiseDelete(): void {
    if (!this.deleteRequest.configId && this.deleteRequest.configId.split("/").length < 3) {
      const errorMessage = "Invalid config";
      this.displayError(errorMessage);
      throw new Error(errorMessage);
    }
    this.ref.disableClose = true;
    this.mode = DialogMode.Deleting;
  }

  private displayError(error: string): void {
    this.mode = DialogMode.Error;
    this.errorMessage = error;
  }

}
