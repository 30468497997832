import {Component, Input, OnInit} from "@angular/core";
import {ReportService} from "./services/report.service";
import {ReportSummary} from "./report.summary";

@Component({
  selector: "progress-bars",
  templateUrl: "./progress.bars.html",
  styleUrls: [
    "./progress.bars.css"
  ]
})
export class ProgressBarsComponent implements OnInit {
  
  progress: Promise<number>;
  timeProgress: Promise<number>;
  
  @Input()
  reportId: string;
  reportSummary: Promise<ReportSummary>;

  constructor(private reportService: ReportService) {

  }

  ngOnInit(): void {
    this.reportSummary = this.reportService.getReportData(this.reportId).then(reportData => reportData.summary);

    this.progress = this.reportSummary.then(summary => 100 * summary.engagements / summary.report.targetEngagements);
    this.timeProgress = this.reportSummary.then(summary => this.toTimeProgress(summary));

  }

  toTimeProgress(summary: ReportSummary): number {
    const endTime = new Date(summary.report.endDate).getTime();
    const startTime = new Date(summary.report.startDate).getTime();
    const duration = endTime - startTime;
    const currentTime = new Date().getTime();
    return Math.min((currentTime - startTime) / duration, 1);

  }

}
