import {Repeater} from "@repeaterjs/repeater";
import {Optional} from "@rezonence/sdk";
import {KeyValue, KvStorageEvent, AbstractKeyValueStore, KeyValueStore} from "@rezonence/dao";

/**
 * Joins key-value stores.
 * * Lists the keys from both delegates (without any de-duplication)
 * * Reads the first available value for a given key
 * * Writes to the first delegate store
 */
export class MultiStore<K, V> extends AbstractKeyValueStore<K, V> implements KeyValueStore<K, V> {
    constructor(private delegates: Array<KeyValueStore<K, V>>) {
        super();
    }

    async* list(): AsyncIterable<K> {
        for (const delegate of this.delegates) {
            for await (const key of delegate.list()) {
                yield key;
            }
        }
    }

    async read(key: K): Promise<Optional<V>> {
        for (const delegate of this.delegates) {
            const optional = await delegate.read(key);
            if (optional.exists) {
                return optional;
            }
        }
        return Optional.empty();
    }

    writeValue(request: KeyValue<K, V>): Promise<void> {
        for (const delegate of this.delegates) {
            return delegate.write(request);
        }
    }

    listenToChanges(): AsyncIterable<KvStorageEvent<K, V>> {
        return Repeater.merge(this.delegates.map(d => d.listenToChanges()));
    }
}
